import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  Switch,
  InputNumber,
  Tooltip,
  Space,
  Alert
} from 'antd';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { inputValidator } from './helpers';

const { Option } = Select;

export const GPIOFormModal = ({
  visible,
  getChannels,
  onCancel,
  onValidate,
  onSubmit,
  diginForm,
  digoutForm,
  channelTypes,
  values
}) => {
  const [form] = Form.useForm();
  const [formConfig, setFormConfig] = useState(
    values?.input?.startsWith('digout.') ? digoutForm : diginForm
  );
  const [formInput, setFormInput] = useState(values?.input);
  const [formValues, setFormValues] = useState(values);
  const [isValid, setIsValid] = useState(false);
  const editMode = !!values?.input;
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    form.resetFields();
    form.setFieldValue('input', formInput);
  }, [formConfig, formInput]);

  useEffect(() => {
    setTimeout(() => {
      form.validateFields().then(
        () => {
          setIsValid(true);
        },
        e => {
          setIsValid(false);
        }
      );
    }, 0);
  }, [Form.useWatch([], form)]);

  const handleFormChange = (changedValues, allValues) => {
    //if it is a input field, we will skip it else rerender will cause focus lost
    if (!document.activeElement.getAttribute('class').includes('ant-input')) {
      // reset the fields to clear the state of all the components
      form.resetFields();
      if (changedValues.input) {
        setFormConfig(changedValues.input.startsWith('digin.') ? diginForm : digoutForm);
        setFormValues({});
        setFormInput(changedValues.input);
      } else {
        if (changedValues.custom || (changedValues.io && allValues.custom)) {
          allValues.custom_name = t('Alerts.GPIO.' + allValues.io, allValues.io);
          const field = formConfig
            .find(f => f.type === 'io')
            ?.field?.find(f => f.name === allValues.io);
          if (field.verb) {
            const states = field.verb.split(',');
            allValues.custom_state1 = states[0];
            allValues.custom_state2 = states[1];
          }
        }
        setFormValues(allValues);
        form.setFieldsValue(allValues);
      }
      setErrorMessage(undefined);
    }
  };

  const formItemStyle = {
    marginBottom: '3px' // Adjust the bottom margin as needed
  };

  // Function to render form fields based on JSON data
  const renderFormFields = () => {
    const formItems = formConfig.map((item, index) => renderItem(item, index)).flat();
    return formItems;
  };

  const renderItem = (item, index) => {
    const {
      name,
      field,
      type,
      default: defaultValue,
      options,
      min,
      max,
      tooltip,
      colSpan,
      visibleWhen
    } = item;

    const tooltipValue = tooltip
      ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.' + name + '_tooltip', tooltip)
      : '';
    // Check the visibility condition
    const isVisible = visibleWhen ? visibleWhen(formValues) : true;

    if (field === 'input' || !isVisible) {
      return null; // Skip rendering if not visible
    }

    const span = colSpan ? colSpan : 12;

    const label = t('CompanyConfig.DeviceConfigurations.GPIOTemplates.' + name, {
      defaultValue: name
    });

    const key = `${field}-${index}`;
    let initialValue;
    if (Array.isArray(field)) {
      // Special for io field
      if (type === 'io') {
        initialValue = formValues['io'];
      } else if (type !== 'service' && formValues[field[0]]) {
        initialValue = formValues[field[0]][field[1]];
      }
    } else {
      initialValue = formValues[field];
    }
    if (initialValue === undefined || initialValue === null) {
      initialValue = defaultValue;
    }

    switch (type) {
      case 'input':
        return (
          <Col span={span} key={key}>
            <Form.Item
              label={label}
              name={field}
              initialValue={initialValue}
              style={formItemStyle}
              tooltip={tooltipValue}
            >
              <Input placeholder={defaultValue} />
            </Form.Item>
          </Col>
        );

      case 'integer':
        return (
          <Col span={span} key={key}>
            <Form.Item
              label={label}
              name={field}
              initialValue={initialValue}
              style={formItemStyle}
              tooltip={tooltipValue}
              rules={inputValidator(item).number}
            >
              <InputNumber placeholder={defaultValue} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        );

      case 'double':
        return (
          <Col span={span} key={key}>
            <Form.Item
              label={label}
              name={field}
              initialValue={initialValue}
              style={formItemStyle}
              tooltip={tooltipValue}
              rules={inputValidator(item).double}
            >
              <InputNumber precision={1} placeholder={defaultValue} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        );

      case 'boolean':
        return (
          <Col span={span} key={key}>
            <Form.Item
              label={label}
              name={field}
              initialValue={initialValue}
              style={formItemStyle}
              valuePropName="checked"
              tooltip={tooltipValue}
            >
              <Switch></Switch>
            </Form.Item>
          </Col>
        );

      case 'select':
        if (initialValue && options.length === 0) {
          initialValue = undefined;
        }
        return (
          <Col span={span} key={key}>
            <Form.Item
              label={label}
              name={field}
              initialValue={!initialValue && options.length > 0 ? options[0].key : initialValue}
              style={formItemStyle}
              tooltip={tooltipValue}
            >
              <Select
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {options
                  .sort((a, b) => {
                    if (a.value < b.value) return -1;
                    if (a.value > b.value) return 1;
                    return 0;
                  })
                  .map((opt, optIndex) =>
                    opt.key ? (
                      <Option key={optIndex} value={opt.key}>
                        {t('Alerts.GPIO.' + opt.value, opt.value)}
                      </Option>
                    ) : (
                      <Option key={optIndex} value={opt}>
                        {t('Alerts.GPIO.' + opt, opt)}
                      </Option>
                    )
                  )}
              </Select>
            </Form.Item>
          </Col>
        );

      case 'io':
        const currentSelect = (
          <Col span={span} key={key}>
            <Form.Item
              label={label}
              name="io"
              initialValue={!initialValue && field.length > 0 ? field[0].name : initialValue}
              style={formItemStyle}
              tooltip={tooltipValue}
            >
              <Select
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {field
                  .sort((a, b) => {
                    if (t('Alerts.GPIO.' + a.name, a.name) < t('Alerts.GPIO.' + b.name, b.name))
                      return -1;
                    if (t('Alerts.GPIO.' + a.name, a.name) > t('Alerts.GPIO.' + b.name, b.name))
                      return 1;
                    return 0;
                  })
                  .map((opt, optIndex) => (
                    <Option key={optIndex} value={opt.name}>
                      {t('Alerts.GPIO.' + opt.name, opt.name)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        );
        if (item.field && item.field.length > 0) {
          const fieldsToExclude = ['name', 'field', 'verb'];
          const innerFields = item.field
            .map(innerItem => {
              const allProperties = Object.keys(innerItem);
              const remaining = allProperties.filter(p => !fieldsToExclude.includes(p));
              if (remaining.length <= 0) {
                return [];
              }
              return remaining.map(p => {
                const subItem = innerItem[p];
                return renderItem({
                  name: innerItem[p].name,
                  field: [innerItem.name, innerItem[p].field],
                  type: subItem.type,
                  options: subItem.options,
                  default: subItem.default,
                  min: subItem.min,
                  max: subItem.max,
                  visibleWhen: fieldsValue => fieldsValue['io'] === innerItem.name
                });
              });
            })
            .flat();
          return [currentSelect, ...innerFields];
        } else {
          return currentSelect;
        }

      case 'mselect':
        return (
          <Col span={span < 12 ? 12 : span} key={key}>
            <Form.Item
              label={label}
              name={field}
              initialValue={initialValue}
              style={formItemStyle}
              tooltip={tooltipValue}
            >
              <Select mode="multiple">
                {options
                  .sort((a, b) => {
                    if (t('Alerts.GPIO.' + a, a) < t('Alerts.GPIO.' + b, b)) return -1;
                    if (t('Alerts.GPIO.' + a, a) > t('Alerts.GPIO.' + b, b)) return 1;
                    return 0;
                  })
                  .map((opt, optIndex) => (
                    <Option key={optIndex} value={opt}>
                      {t('Alerts.GPIO.' + opt, opt)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        );

      case 'service':
        if (item.field && item.field.length > 0 && formValues.io === defaultValue) {
          return item.field.map((inner, index) =>
            renderItem({
              ...inner,
              colSpan: index === 0 ? 24 : 12
            })
          );
        }
        return null;

      case 'rquery':
        return (
          <Col span={colSpan} key={key}>
            <Space>
              <Form.Item
                name={field}
                noStyle
                valuePropName="checked"
                initialValue={!!initialValue}
                tooltip={tooltipValue}
              >
                <Switch></Switch>
              </Form.Item>
              {label}
              <Tooltip title={tooltipValue}>
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>
          </Col>
        );

      // Add more cases for other field types if needed

      default:
        return null;
    }
  };

  const channelInitialType = channelTypes[0].form + '.' + channelTypes[0].key;
  if (!formInput) {
    setFormInput(channelInitialType);
  }
  const channels = getChannels(formInput) || [];
  let channelInitialValue = channels.length > 0 ? channels[0].value : null;
  if (formValues.channel) {
    channelInitialValue = formValues.channel;
  }

  return (
    <Modal
      title={
        editMode
          ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Edit Config')
          : t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Add Config')
      }
      open={visible}
      onCancel={onCancel}
      okButtonProps={{ disabled: !isValid }}
      width="800px"
      onOk={() => {
        try {
          onValidate(form.getFieldsValue());
          setErrorMessage(undefined);
        } catch (error) {
          setErrorMessage(error.message);
          return;
        }

        form.validateFields().then(values => {
          onSubmit(values);
          form.resetFields();
        });
      }}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" onValuesChange={handleFormChange}>
        <Row gutter={[16, 16]}>
          {errorMessage && (
            <Col span={24}>
              <ExclamationCircleOutlined style={{ color: 'red' }} /> {errorMessage}
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Channel Type')}
              required={true}
              colon={false}
              name="input"
              style={formItemStyle}
            >
              <Select disabled={editMode}>
                {channelTypes.map(item => (
                  <Option key={item.form + '.' + item.key} value={item.form + '.' + item.key}>
                    {t(
                      'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + item.value,
                      item.value
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Channel')}
              colon={false}
              initialValue={channelInitialValue}
              name="channel"
              rules={[
                {
                  required: true
                }
              ]}
              style={formItemStyle}
            >
              <Select>
                {channels.map((opt, optIndex) => (
                  <Option key={optIndex} value={opt.key}>
                    {opt.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {renderFormFields()}
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col span={12}>
            <Space>
              <Form.Item
                name="custom"
                noStyle
                valuePropName="checked"
                initialValue={formValues['custom']}
              >
                <Switch></Switch>
              </Form.Item>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Custom I/O Name')}
            </Space>
          </Col>
        </Row>
        {formValues['custom'] && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Alert
                  type="info"
                  style={{ padding: '10px', margin: '16px 0px' }}
                  description={t(
                    'CompanyConfig.DeviceConfigurations.GPIOTemplates.CustomNameDescription'
                  )}
                  showIcon
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.I/O Name')}
                  style={formItemStyle}
                  name="custom_name"
                  initialValue={formValues.custom_name}
                >
                  <Input maxLength={50} allowClear={true} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.State 1')}
                  style={formItemStyle}
                  name="custom_state1"
                  initialValue={formValues.custom_state1}
                >
                  <Input maxLength={20} allowClear={true} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.State 2')}
                  style={formItemStyle}
                  name="custom_state2"
                  initialValue={formValues.custom_state2}
                >
                  <Input maxLength={20} allowClear={true} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};
