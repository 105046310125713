import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DefaultToolbarAction,
  DefaultToolbarFilterOptions,
  Toolbar,
  useDefaultToolbarReducer
} from './Toolbar';
import { Trans, useTranslation } from 'react-i18next';
import {
  AttachmentStatus,
  CameraEventTypes,
  CameraTabs,
  EventStatusToRequestStatus,
  extractCameraError,
  extractEventAttachmentStatus,
  extractEventExtras,
  RequestStatusFilterOptions,
  StatusFilterOptions,
  ViewMode
} from '../constant';
import { prepareDataForMultiselect } from 'utils/filters';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { isEqual, sortBy, orderBy, uniqBy, trim } from 'lodash';
import { RequestQueue } from 'features/requestQueue/RequestQueue';
import { DatePicker, DateRangePicker } from 'components/ant';
import { useLocalization } from 'features/localization/localizationSlice';
import moment from 'moment';
import styles from './Tab.module.scss';
import { GridView } from './GridView';
import { ListView } from './ListView';
import { useDrivers } from 'features/users/usersSlice';
import { EventNamesMap, EventUtil } from 'features/events/eventUtil';
import { useUser } from 'features/user/userSlice';
import { ViewTypeSwitch } from '../Components/ViewTypeSwitch';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import i18n, { t_error } from 'i18nextConfig';
import { PATH_SPLIT, selectAll } from 'components/form/treeselect/TreeSelect';
import { SelectSort } from 'containers/home/SelectSort';
import { fetchCameraEvents } from 'features/camera/cameraApi';
import { useDispatch } from 'react-redux';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useHistory } from 'react-router';
import { requestRetryVideoFootage, requestVideoUpload } from 'features/camera/cameraSlice';
import { LoadingSpinner } from 'components/loading/LoadingSpinner';
import { useCurrentCompany } from 'features/company/companySlice';
import { useTimer } from 'utils/hooks/useTimer';
import { Button } from 'antd';
import { MapModal } from 'components/modals/map-modal/MapModal';
import { getGPSByAddress } from 'utils/geo';
import { CameraModelConfig } from 'features/camera/CameraModelConfig';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';

const EventActions = {
  filterEventTypes: 'filterEventTypes',
  toggleDataPicker: 'toggleDataPicker',
  updateDateRange: 'updateDateRange',
  filterTree: 'filterTree',
  updateFilterTreeNode: 'updateFilterTreeNode',
  sortEvent: 'sortEvent',
  toggleSelectSort: 'toggleSelectSort',
  markFilterTreeReady: 'markFilterTreeReady',
  syncEvent: 'syncEvent'
};

const FilterOptions = {
  get Vehicles() {
    return i18n.t('Common.Vehicles');
  },
  get Devices() {
    return i18n.t('Common.Devices');
  },
  get Drivers() {
    return i18n.t('Common.Drivers');
  },
  get Status() {
    return i18n.t('Common.Status');
  },
  get RequestStatus() {
    return i18n.t('Footage.Request Status');
  }
};

const SortOptions = {
  get Fleet() {
    return i18n.t('Common.Fleet');
  },
  get Vehicle() {
    return i18n.t('Common.Vehicle');
  },
  get Driver() {
    return i18n.t('Common.Drivers');
  },
  get Device() {
    return i18n.t('Common.Device');
  },
  get EventTime() {
    return i18n.t('Common.Event Time');
  },
  get Type() {
    return i18n.t('Home.Event Type');
  },
  get Location() {
    return i18n.t('Common.Location');
  },
  get Status() {
    return i18n.t('Common.Status');
  }
};

function processSearchTextWithCamTypes(search, forVideoRequest, supportedCameraEventTypes) {
  if (!search || !search.trim() || !search.trim().includes(' ')) {
    return search;
  }
  const camTypes = Object.entries(supportedCameraEventTypes).filter(
    e =>
      e[1] !== CameraEventTypes.SNAPSHOT &&
      (forVideoRequest || e[1] !== CameraEventTypes.VIDEO_REQUEST)
  );
  const s = trim(search.toLowerCase());
  const matched_type = camTypes.find(e =>
    i18n
      .t(`Common.CameraEventTypes.${e[1]}`)
      ?.toLowerCase()
      .includes(s)
  );
  if (matched_type) {
    return matched_type[0];
  } else {
    return search;
  }
}

export function Tab({
  id,
  showViewSwitch = true,
  defaultView = ViewMode.GridView,
  tableColumns,
  forVideoRequest,
  sharedData,
  setSharedData
}) {
  const dispatch = useDispatch();
  const localization = useLocalization();
  const { t } = useTranslation();
  const drivers = useDrivers();
  const [vrState, setVRState] = useState({
    deviceId: null,
    eventId: null,
    showSpinner: false
  });
  const history = useHistory();
  const viewStateRef = useRef(null);
  const currentCompany = useCurrentCompany();
  viewStateRef.current = history.location.state?.hasContext ? window.eventDataCache : null;

  const [showMapModal, setShowMapModal] = useState(false);
  const [gps, setGps] = useState({});

  const [viewMode, setViewMode] = useState(defaultView);
  const currentUser = useUser();

  const canPotentialCrash = useCanFeatureFlag({
    featureFlag: FeatureFlag.potentialCrashEvent.flag
  });
  const { supportedCameraEventTypes, processSearchText } = useMemo(() => {
    const supportedCameraEventTypes = Object.keys(CameraEventTypes).reduce(
      (a, type) =>
        type === 'POTENTIAL_CRASH' && !canPotentialCrash
          ? a
          : { ...a, [type]: CameraEventTypes[type] },
      {}
    );
    return {
      supportedCameraEventTypes,
      processSearchText: (search, forVideoRequest) =>
        processSearchTextWithCamTypes(search, forVideoRequest, supportedCameraEventTypes)
    };
  }, [canPotentialCrash]);

  const eventsQueryContext = useRef({
    request: null,
    from: null,
    to: null,
    filter: {
      devices: null,
      drivers: null,
      eventTypes: null,
      orderField: null,
      orderDirection: null,
      search: null,
      rowOffset: 0
    },
    totalRowCount: null
  });
  const [events, setEvents] = useState([]);
  const [decoratedEvents, setDecoratedEvents] = useState([]);
  const [FilterInitTree] = useState(() => {
    if (viewStateRef.current?.dataStore?.filterTree) {
      return viewStateRef.current?.dataStore?.filterTree;
    }

    return Object.keys(FilterOptions).reduce((pre, cur, idx) => {
      if (forVideoRequest && FilterOptions[cur] === FilterOptions.Status) return pre;
      if (!forVideoRequest && FilterOptions[cur] === FilterOptions.RequestStatus) return pre;

      let node = {
        label: FilterOptions[cur],
        function: undefined,
        nodeKey: idx.toString(),
        id: idx,
        children: {
          0: {
            ...selectAll,
            label: t('Common.' + selectAll.name),
            nodeKey: idx + PATH_SPLIT + 0
          }
        }
      };
      pre[node.id] = node;
      if (FilterOptions[cur] === FilterOptions.Status) {
        sortBy(Object.entries(StatusFilterOptions), ([key]) =>
          t(`Common.AttachmentStatus.${key}`)
        ).forEach(([key], o_i) => {
          node.children[o_i + 1] = {
            id: o_i + 1,
            name: key,
            label: t(`Common.AttachmentStatus.${key}`),
            nodeKey: node.id + PATH_SPLIT + (o_i + 1)
          };
        });
      } else if (FilterOptions[cur] === FilterOptions.RequestStatus) {
        sortBy(Object.entries(RequestStatusFilterOptions), ([, value]) =>
          t(`Footage.RequestStatus.${value}`)
        ).forEach(([key, value], o_i) => {
          node.children[o_i + 1] = {
            id: o_i + 1,
            name: key,
            label: t(`Footage.RequestStatus.${value}`),
            nodeKey: node.id + PATH_SPLIT + (o_i + 1)
          };
        });
      }
      return pre;
    }, {});
  });
  const [isLoading, setIsLoading] = useState(viewStateRef.current ? false : true);

  const [filterOptions] = useState(() => {
    const filterOptions = DefaultToolbarFilterOptions.filter(
      o => o.key !== 'vehicleFilter'
    ).map(o => Object.assign({}, o));
    if (viewStateRef.current) {
      filterOptions.forEach(o => {
        if (o.key === 'searchFilter') {
          o.defaultValue = viewStateRef.current.dataStore.search;
        } else if (o.key === 'companyFilter') {
          o.defaultValue = viewStateRef.current.dataStore.companyOptions;
        } else if (o.key === 'fleetFilter') {
          o.defaultValue = viewStateRef.current.dataStore.fleetOptions;
        } else if (o.key === 'vehicleFilter') {
          o.defaultValue = viewStateRef.current.dataStore.vehicleOptions;
        }
      });
    }
    return [
      ...filterOptions,
      {
        component: AntMultiselect,
        config: (dataStore, actions) => ({
          title: !dataStore?.eventTypeOptions?.some(e => !e.checked)
            ? t('Home.All Event Types')
            : t('Home.Event Types'),
          data: dataStore?.eventTypeOptions,
          onFilter: actions?.onFilterEventTypes
        }),
        visible: dataStore => !forVideoRequest,
        ...(viewStateRef.current?.dataStore?.eventTypeOptions && {
          defaultValue: viewStateRef.current?.dataStore?.eventTypeOptions
        })
      },
      {
        component: forVideoRequest ? DatePicker : DateRangePicker,
        config: (dataStore, actions) => ({
          disabledDate: dataStore.disabledDate,
          allowClear: false,
          ...(forVideoRequest
            ? {
                picker: 'month',
                className: styles.datePicker,
                format: 'MMMM YYYY',
                defaultValue: dataStore.defaultDate,
                onChange: date =>
                  actions.onDateChanged([
                    moment(date).startOf('month'),
                    moment(date).endOf('month')
                  ])
              }
            : {
                picker: 'date',
                className: styles.dateRangePicker,
                format: localization.formats.time.formats.dby.toUpperCase(),
                defaultDates: [
                  moment(dataStore.defaultDate)
                    .startOf('month')
                    .startOf('day'),
                  moment(dataStore.defaultDate)
                    .endOf('month')
                    .endOf('day')
                ],
                onDateRangeChanged: dates =>
                  actions.onDateChanged([
                    moment(dates?.[0]).startOf('day'),
                    moment(dates?.[1]).endOf('day')
                  ])
              })
        }),
        ...(viewStateRef.current?.dataStore?.selectedDateRange &&
          (forVideoRequest
            ? { defaultValue: viewStateRef.current.dataStore.selectedDateRange[0] }
            : { defaultDates: viewStateRef.current.dataStore.selectedDateRange }))
      },
      {
        component: DropdownTreeSelect,
        config: (dataStore, actions) => ({
          title: dataStore.filterTitle,
          tree: dataStore.filterTree,
          onChange: actions?.onFilterTree,
          showSelectAll: true
        })
      },
      {
        component: SelectSort,
        config: (dataStore, actions) => ({
          onChange: actions?.onSortChange,
          defaultValue: dataStore.sortValue,
          options: dataStore.sortOptions
        }),
        visible: dataStore => dataStore.showSelectSort,
        ...(viewStateRef.current?.dataStore?.sortValue && {
          defaultValue: viewStateRef.current?.dataStore?.sortValue
        })
      },
      ...(showViewSwitch
        ? [
            {
              component: ViewTypeSwitch,
              config: (dataStore, actions) => ({
                onGridViewClicked: actions.onGridViewClicked,
                onListViewClicked: actions.onListViewClicked
              }),
              ...(viewStateRef.current?.dataStore && {
                defaultValue: viewStateRef.current?.dataStore?.showSelectSort
              }),
              position: 'end'
            }
          ]
        : [])
    ];
  });

  const [dataStore, updateStore, actions] = useDefaultToolbarReducer(
    (state, action) => {
      switch (action.type) {
        case EventActions.filterEventTypes: {
          return { ...state, eventTypeOptions: action.payload };
        }
        case EventActions.toggleDataPicker: {
          return { ...state, isDatePickerOpen: action.payload };
        }
        case EventActions.updateDateRange: {
          return {
            ...state,
            selectedDateRange: action.payload
          };
        }
        case EventActions.filterTree: {
          return { ...state, filterTree: action.payload };
        }
        case EventActions.updateFilterTreeNode: {
          const filterTreeReady = action.payload?.filterTreeReady;
          delete action.payload.filterTreeReady;
          const filterTree = { ...state.filterTree, ...action.payload };
          return {
            ...state,
            filterTree,
            ...(filterTreeReady !== undefined && { filterTreeReady })
          };
        }
        case EventActions.sortEvent: {
          return { ...state, sortValue: action.payload };
        }
        case EventActions.toggleSelectSort: {
          return { ...state, showSelectSort: action.payload ?? !state.showSelectSort };
        }
        case EventActions.markFilterTreeReady: {
          return { ...state, filterTreeReady: true };
        }
        case EventActions.syncEvent: {
          return { ...state, syncedEvents: action.payload };
        }
        default: {
          return state;
        }
      }
    },
    () => ({
      eventTypeOptions: prepareDataForMultiselect(
        sortBy(
          Object.entries(supportedCameraEventTypes)
            .filter(
              t =>
                t[1] !== CameraEventTypes.SNAPSHOT &&
                ((!forVideoRequest && t[1] !== CameraEventTypes.VIDEO_REQUEST) || forVideoRequest)
            )
            .map(e => ({
              id: e[0],
              name: t(`Common.CameraEventTypes.${e[1]}`)
            })),
          'name'
        ),
        t('Home.All Event Types'),
        null
      ),
      defaultDate: moment(),
      disabledDate: current => {
        if (
          current <
            moment()
              .subtract(12, 'month')
              .startOf('month') ||
          current >=
            moment()
              .startOf('month')
              .add(1, 'month')
        ) {
          return true;
        }
        return false;
      },
      selectedDateRange: [
        moment()
          .startOf('month')
          .startOf('day'),
        moment()
          .endOf('month')
          .endOf('day')
      ],
      filterTitle: t('Common.Filter'),
      filterTree: FilterInitTree,
      filterTreeReady: false,
      sortValue: { item: 'EventTime', order: 'desc' },
      sortOptions: Object.keys(SortOptions).map(s => ({
        key: s,
        label: SortOptions[s]
      })),
      showSelectSort: forVideoRequest ? false : true,
      syncedEvents: {
        events: [],
        syncedBy: null,
        isSameCtx: ctxParams => false,
        totalCount: 0
      }
    }),
    updateDataStore => ({
      onFilterEventTypes: data =>
        updateDataStore({ type: EventActions.filterEventTypes, payload: data }),
      toggleDatePicker: data =>
        updateDataStore({ type: EventActions.toggleDataPicker, payload: data }),
      onDateChanged: data => updateDataStore({ type: EventActions.updateDateRange, payload: data }),
      onFilterTree: data => updateDataStore({ type: EventActions.filterTree, payload: data }),
      onSortChange: data => updateDataStore({ type: EventActions.sortEvent, payload: data }),
      onGridViewClicked: () => {
        setViewMode(ViewMode.GridView);
        updateDataStore({ type: EventActions.toggleSelectSort, payload: true });
      },
      onListViewClicked: () => {
        setViewMode(ViewMode.ListView);
        updateDataStore({ type: EventActions.toggleSelectSort, payload: false });
      }
    })
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const syncedEvents = useMemo(
    () =>
      sharedData?.syncedEvents ||
      dataStore.syncedEvents || {
        events: [],
        isSameCtx: ctxParams => false,
        syncedBy: null,
        totalCount: 0
      },
    [dataStore?.syncedEvents, sharedData?.syncedEvents]
  );

  const { dismissEventPristine, setSyncedEvents, clearSyncedEvents } = useMemo(
    () => ({
      dismissEventPristine: id => {
        if (setSharedData) {
          const syncedEvents = {
            ...sharedData?.syncedEvents,
            events: (sharedData?.syncedEvents?.events || []).map(e =>
              String(e.id) === String(id) ? { ...e, dismissed: true } : e
            )
          };
          setSharedData({ ...sharedData, syncedEvents });
          updateStore({ type: EventActions.syncEvent, payload: syncedEvents });
        }
      },
      setSyncedEvents: syncedEvents => {
        if (setSharedData) {
          setSharedData({ ...sharedData, syncedEvents });
          updateStore({ type: EventActions.syncEvent, payload: syncedEvents });
        }
      },
      clearSyncedEvents: ctxParams => {
        if (setSharedData) {
          const syncedEvents =
            sharedData?.syncedEvents?.isSameCtx && sharedData.syncedEvents.isSameCtx(ctxParams)
              ? sharedData.syncedEvents
              : null;
          setSharedData(prev => (syncedEvents ? prev : { ...prev, syncedEvents: {} }));
          updateStore({ type: EventActions.syncEvent, payload: syncedEvents || {} });
        }
      }
    }),
    [updateStore, sharedData, setSharedData]
  );

  useEffect(() => {
    if (viewStateRef.current && viewStateRef.current.companyId === currentCompany.id) {
      if (viewStateRef.current.dataStore.search) {
        actions?.onSearch(viewStateRef.current.dataStore.search);
        updateStore({
          type: DefaultToolbarAction.Search.Filter,
          payload: viewStateRef.current.dataStore.search
        });
      }

      actions?.onFilterCompanies(viewStateRef.current.dataStore.companyOptions);
      actions?.onFilterFleets(viewStateRef.current.dataStore.fleetOptions);
      actions?.onFilterVehicles(viewStateRef.current.dataStore.vehicleOptions);
      actions?.onFilterEventTypes(viewStateRef.current.dataStore.eventTypeOptions);
      if (viewStateRef.current.dataStore.selectedDateRange) {
        actions?.onDateChanged(viewStateRef.current.dataStore.selectedDateRange);
      }

      actions?.onFilterTree(viewStateRef.current.dataStore.filterTree);
      actions?.onSortChange(viewStateRef.current.dataStore.sortValue);
      setSyncedEvents(viewStateRef.current.dataStore.syncedEvents);
      if (viewStateRef.current?.dataStore?.showSelectSort) {
        actions?.onGridViewClicked();
      } else {
        actions?.onListViewClicked();
      }
      updateStore({
        type: EventActions.markFilterTreeReady
      });
    }
  }, [actions, currentCompany, setSyncedEvents]);

  useEffect(() => {
    if (viewStateRef.current && viewStateRef.current.companyId === currentCompany.id) {
      setViewMode(window.eventDataCache.viewMode);
      setEvents(window.eventDataCache.events);
      setDecoratedEvents(window.eventDataCache.decoratedEvents);
      eventsQueryContext.current = window.eventDataCache.eventsQueryContext;
      window.eventDataCache = null;
    } else {
      viewStateRef.current = null;
      window.eventDataCache = null;
    }
  }, [currentCompany]);

  useEffect(() => {
    if (viewStateRef.current) return;

    const driverNodeIdx = Object.keys(FilterOptions).indexOf('Drivers');
    let node = {
      label: FilterOptions.Drivers,
      function: undefined,
      nodeKey: driverNodeIdx.toString(),
      id: driverNodeIdx,
      children: {
        0: {
          ...selectAll,
          label: t('Common.' + selectAll.name),
          nodeKey: driverNodeIdx + PATH_SPLIT + 0
        }
      }
    };
    const companyIdMap = dataStore.companyOptions?.reduce((prev, cur) => {
      prev[cur.id] = cur.checked;
      return prev;
    }, {});

    sortBy(drivers || [], d => d.firstName + ' ' + d.lastName).forEach((d, d_i) => {
      if (companyIdMap[d.company?.id]) {
        const label = d.firstName + ' ' + d.lastName;
        const idx = d_i + 1;
        node.children[idx] = {
          id: d.id,
          name: label,
          label: label,
          nodeKey: driverNodeIdx + PATH_SPLIT + idx
        };
      }
    });

    updateStore({ type: EventActions.updateFilterTreeNode, payload: { [node.id]: node } });
  }, [dataStore.companyOptions, updateStore, drivers]);

  useEffect(() => {
    if (viewStateRef.current || dataStore.initLoading) return;

    const deviceNodeIdx = Object.keys(FilterOptions).indexOf('Devices');
    const devicesNode = {
      label: FilterOptions.Devices,
      function: undefined,
      nodeKey: deviceNodeIdx.toString(),
      id: deviceNodeIdx,
      children: {
        0: {
          ...selectAll,
          label: t('Common.' + selectAll.name),
          nodeKey: deviceNodeIdx + PATH_SPLIT + 0
        }
      }
    };

    const vehicleNodeIdx = Object.keys(FilterOptions).indexOf('Vehicles');
    const vehiclesNode = {
      label: FilterOptions.Vehicles,
      function: undefined,
      nodeKey: vehicleNodeIdx.toString(),
      id: vehicleNodeIdx,
      children: {
        0: {
          ...selectAll,
          label: t('Common.' + selectAll.name),
          nodeKey: vehicleNodeIdx + PATH_SPLIT + 0
        }
      }
    };

    sortBy(dataStore.vehicleOptions || [], ['label']).forEach((v, v_i) => {
      const idx = v_i + 1;
      if (v.id?.toString().startsWith('v_')) {
        vehiclesNode.children[idx] = {
          id: v.id.replace('v_', ''),
          name: v.label,
          label: v.label,
          nodeKey: vehicleNodeIdx + PATH_SPLIT + idx
        };
      } else if (v.id?.toString().startsWith('d_')) {
        devicesNode.children[idx] = {
          id: v.id.replace('d_', ''),
          name: v.label,
          label: v.label,
          nodeKey: deviceNodeIdx + PATH_SPLIT + idx
        };
      }
    });

    updateStore({
      type: EventActions.updateFilterTreeNode,
      payload: {
        [devicesNode.id]: devicesNode,
        [vehiclesNode.id]: vehiclesNode,
        filterTreeReady: true
      }
    });
  }, [dataStore.vehicleOptions, dataStore.initLoading, updateStore]);

  const refreshEvents = useCallback(() => {
    if (forVideoRequest && !isLoading && events?.length) {
      const ctx = eventsQueryContext.current;
      const getFirstPendingEvtIdx = evts =>
        (evts || []).findIndex(
          evt =>
            evt?.videoRequestStatus?.toUpperCase() &&
            ![EventStatusToRequestStatus.AVAILABLE, EventStatusToRequestStatus.ENABLED]
              .map(status => status.toUpperCase())
              .includes(evt.videoRequestStatus)
        );
      const search = trim(processSearchText(ctx.filter.search, forVideoRequest));
      const createReq = (rowOffset = 0, reqIdx = 0) =>
        dispatch(
          fetchCameraEvents(
            ctx.from.format(),
            ctx.to.format(),
            ctx.filter.devices || undefined,
            ctx.filter.drivers || undefined,
            ctx.filter.eventTypes || undefined,
            ctx.filter.orderField,
            ctx.filter.orderDirection,
            rowOffset,
            search,
            respJson => {
              ctx.refreshRequests[reqIdx] = null;
              if (respJson?.events?.length) {
                const result = {},
                  idMap = {};
                respJson.events.forEach(e => {
                  if (idMap[e.id] >= 0) {
                    if (result[idMap[e.id]].childCount) {
                      result[idMap[e.id]].childCount++;
                    } else {
                      result[idMap[e.id]].childCount = 1;
                    }
                    e.id = e.id + '_' + result[idMap[e.id]].childCount;
                  } else {
                    idMap[e.id] = result.length;
                  }
                  result[e.id] = e;
                });
                setEvents(evts => (evts?.length ? evts.map(evt => result[evt.id] || evt) : evts));
              }
            },
            err => {
              ctx.refreshRequests[reqIdx] = null;
            },
            false,
            forVideoRequest,
            ctx.filter.status || undefined,
            false
          )
        );
      const processReq = async req => {
        try {
          if (req) {
            await req;
          }
        } catch (e) {}
      };
      const INITIAL_LIMIT = 100,
        requests = [],
        firstPendingEvtIdx = getFirstPendingEvtIdx(events);
      if (firstPendingEvtIdx >= INITIAL_LIMIT) {
        requests.push(createReq(INITIAL_LIMIT));
      } else if (firstPendingEvtIdx >= 0) {
        requests.push(createReq(0));
        if (events?.[INITIAL_LIMIT] && getFirstPendingEvtIdx(events.slice(INITIAL_LIMIT)) >= 0) {
          requests.push(createReq(INITIAL_LIMIT, 1));
        }
      }
      if (requests.length) {
        if (ctx.refreshRequests?.length) {
          for (const req of ctx.refreshRequests) {
            if (req?.abort) {
              req.abort();
            }
          }
        }
        ctx.refreshRequests = requests;
        for (const req of ctx.refreshRequests) {
          processReq(req);
        }
      }
    }
  }, [dispatch, forVideoRequest, isLoading, events, processSearchText]);

  useTimer(60000, refreshEvents);

  useEffect(() => {
    if (viewStateRef.current || dataStore.initLoading || !dataStore.filterTreeReady) return;

    const ctx = eventsQueryContext.current;

    const currentFilter = {
      get eventTypes() {
        return dataStore.eventTypeOptions.reduce((prev, cur) => {
          if (cur.id && cur.checked) {
            prev[cur.id] = cur.checked;
          }
          return prev;
        }, {});
      },
      get drivers() {
        const driverOptionIdx = Object.values(FilterOptions).indexOf(FilterOptions.Drivers);
        const driverFilters = dataStore.filterTree[driverOptionIdx];
        if (driverFilters.children[0].checked) {
          return null;
        } else {
          let length = 0;
          const selectedDrivers = Object.keys(driverFilters.children).reduce((prev, curr) => {
            if (driverFilters.children[curr].checked) {
              prev[driverFilters.children[curr].id] = true;
              length++;
            }
            return prev;
          }, {});
          return length === 0 ? null : selectedDrivers;
        }
      },
      get devices() {
        const selectedDevices = {};
        const deviceOptionIdx = Object.values(FilterOptions).indexOf(FilterOptions.Devices);
        const deviceFilters = dataStore.filterTree[deviceOptionIdx];
        let allChecked = deviceFilters.children[0].checked;
        let anyChecked = false;
        Object.keys(deviceFilters.children).forEach(d => {
          if (d > 0 && (deviceFilters.children[d].checked || allChecked)) {
            selectedDevices[deviceFilters.children[d].id] = true;
            anyChecked = true;
          }
        });

        const vehicleOptionIdx = Object.values(FilterOptions).indexOf(FilterOptions.Vehicles);
        const vehicleFilters = dataStore.filterTree[vehicleOptionIdx];
        allChecked = vehicleFilters.children[0].checked;
        const vehicleMap = dataStore.allVehicles.reduce((prev, curr) => {
          const camDevs = curr.devices?.filter(d => d.type?.code === 'CAMERA');
          if (curr.id && camDevs?.length) {
            prev[curr.id] = camDevs;
          }
          return prev;
        }, {});

        Object.keys(vehicleFilters.children).forEach(v => {
          if (v > 0 && (vehicleFilters.children[v].checked || allChecked)) {
            vehicleMap[vehicleFilters.children[v].id]?.forEach(d => (selectedDevices[d.id] = true));
            anyChecked = true;
          }
        });

        if (!anyChecked) {
          Object.keys(deviceFilters.children).forEach(d => {
            if (d > 0) {
              selectedDevices[deviceFilters.children[d].id] = true;
            }
          });

          Object.keys(vehicleFilters.children).forEach(v => {
            if (v > 0) {
              vehicleMap[vehicleFilters.children[v].id]?.forEach(
                d => (selectedDevices[d.id] = true)
              );
            }
          });
        }

        if (Object.keys(selectedDevices).length > 0) {
          return selectedDevices;
        } else if (
          Object.keys(deviceFilters.children).length === 1 &&
          Object.keys(vehicleFilters.children).length === 1
        ) {
          //when there are no vehicles or devices, return empty
          return {};
        } else {
          return null;
        }
      },
      get search() {
        return dataStore.search;
      },
      get status() {
        let statusFilters = null;
        if (forVideoRequest) {
          statusFilters = Object.values(dataStore.filterTree).find(
            f => f.label === FilterOptions.RequestStatus
          );
        } else {
          statusFilters = Object.values(dataStore.filterTree).find(
            f => f.label === FilterOptions.Status
          );
        }
        if (statusFilters.children[0].checked) {
          return null;
        } else {
          let length = 0;
          const selectedStatus = Object.keys(statusFilters.children).reduce((prev, curr) => {
            if (statusFilters.children[curr].checked) {
              prev[statusFilters.children[curr].name] = true;
              length++;
            }
            return prev;
          }, {});
          return length === 0 ? null : selectedStatus;
        }
      }
    };

    //filter compares
    const cp = {
      get dateRange() {
        return (
          ctx.from?.valueOf() === dataStore.selectedDateRange[0].valueOf() &&
          ctx.to?.valueOf() === dataStore.selectedDateRange[1].valueOf()
        );
      },
      get order() {
        return (
          ctx.filter.orderField === dataStore.sortValue.item &&
          ctx.filter.orderDirection === dataStore.sortValue.order
        );
      },
      get eventTypes() {
        return (
          ctx.filter.eventTypes?.every(t => currentFilter.eventTypes[t]) &&
          ctx.filter.eventTypes?.length === Object.keys(currentFilter.eventTypes || {}).length
        );
      },
      get devices() {
        return (
          (ctx.filter.devices === null && currentFilter.devices === null) ||
          (ctx.filter.devices?.every(d => currentFilter.devices?.[d]) &&
            ctx.filter.devices?.length === Object.keys(currentFilter.devices || {}).length)
        );
      },
      get drivers() {
        return (
          (ctx.filter.drivers === null && currentFilter.drivers === null) ||
          (ctx.filter.drivers?.every(d => currentFilter.drivers?.[d]) &&
            ctx.filter.drivers?.length === Object.keys(currentFilter.drivers || {}).length)
        );
      },
      get search() {
        return (ctx.filter.search?.trim() || '') === (currentFilter.search?.trim() || '');
      },
      get status() {
        return (
          (ctx.filter.status === null && currentFilter.status === null) ||
          (ctx.filter.status?.every(d => currentFilter.status?.[d]) &&
            ctx.filter.status?.length === Object.keys(currentFilter.status || {}).length)
        );
      }
    };

    if (
      !(
        cp.dateRange &&
        cp.order &&
        cp.eventTypes &&
        cp.devices &&
        cp.drivers &&
        cp.search &&
        cp.status
      )
    ) {
      if (ctx.request) {
        ctx.request.abort();
        ctx.request = null;
      }
      ctx.filter.rowOffset = 0;
      ctx.from = dataStore.selectedDateRange[0];
      ctx.to = dataStore.selectedDateRange[1];
      ctx.filter.orderField = dataStore.sortValue.item;
      ctx.filter.orderDirection = dataStore.sortValue.order;
      ctx.filter.eventTypes = Object.keys(currentFilter.eventTypes);
      ctx.filter.devices = currentFilter.devices ? Object.keys(currentFilter.devices) : null;
      ctx.filter.drivers = currentFilter.drivers ? Object.keys(currentFilter.drivers) : null;
      ctx.filter.search = currentFilter.search;
      ctx.filter.status = currentFilter.status ? Object.keys(currentFilter.status) : null;

      setEvents([]); //clear events first to reset the view
      setIsLoading(true);
      const params = {
        from: ctx.from.format(),
        to: ctx.to.format(),
        devices: ctx.filter.devices || undefined,
        drivers: ctx.filter.drivers || undefined,
        eventTypes: ctx.filter.eventTypes || undefined,
        orderField: ctx.filter.orderField,
        orderDirection: ctx.filter.orderDirection,
        search: trim(processSearchText(ctx.filter.search, forVideoRequest)),
        allAttachment: false,
        forVideoRequest,
        status: ctx.filter.status || undefined
      };
      clearSyncedEvents(params);
      ctx.request = dispatch(
        fetchCameraEvents(
          params.from,
          params.to,
          params.devices,
          params.drivers,
          params.eventTypes,
          params.orderField,
          params.orderDirection,
          params.rowOffset,
          params.search,
          respJson => {
            ctx.request = null;
            if (respJson) {
              ctx.totalRowCount = respJson.totalCount;
              const idMap = {};
              const result = [];
              respJson.events.forEach(e => {
                if (idMap[e.id] >= 0) {
                  if (result[idMap[e.id]].childCount) {
                    result[idMap[e.id]].childCount++;
                  } else {
                    result[idMap[e.id]].childCount = 1;
                  }
                  e.id = e.id + '_' + result[idMap[e.id]].childCount;
                } else {
                  idMap[e.id] = result.length;
                }
                result.push(e);
              });
              setEvents(result);
            } else {
              ctx.totalRowCount = 0;
              setEvents([]);
            }
            setIsLoading(false);
          },
          err => {
            dispatch(
              openToast({
                type: ToastType.Error,
                message: t_error(err)
              })
            );
            setIsLoading(false);
            setEvents([]);
          },
          params.allAttachment,
          params.forVideoRequest,
          params.status,
          false
        )
      );
      const fetchData = async () => {
        try {
          await ctx.request;
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    }
  }, [
    dispatch,
    dataStore.selectedDateRange,
    dataStore.sortValue,
    dataStore.eventTypeOptions,
    dataStore.filterTree,
    dataStore.allVehicles,
    dataStore.vehicleOptions,
    dataStore.search,
    dataStore.initLoading,
    dataStore.filterTreeReady,
    FilterInitTree,
    forVideoRequest,
    clearSyncedEvents,
    processSearchText
  ]);

  useEffect(() => {
    if (viewStateRef.current) return;

    const eventsNeedReorder =
      syncedEvents?.events?.length && syncedEvents.syncedBy.orderDirection
        ? syncedEvents.events
        : [];

    const processEvents = async () => {
      setIsLoading(true);
      const processedEvents = await Promise.all(
        uniqBy([...eventsNeedReorder, ...(events || [])], 'id').map(async e => {
          const driver = drivers.find(d => d.id === e.userId);
          const device = dataStore.allDevices?.find(d => d.id === e.deviceId);
          const vehicle = dataStore.allVehicles?.find(v => v.id && v.id === e.vehicleId);

          const showLocation =
            e.location && [StatusFilterOptions.AVAILABLE, 'ENABLED'].includes(e.status);

          const eventGps = { lat: null, lng: null };

          if (showLocation) {
            const res = await getGPSByAddress(e.location);
            eventGps.lat = res.lat;
            eventGps.lng = res.lng;
          }

          return {
            id: e.id,
            driverId: e.userId,
            driverName: driver ? driver.firstName + ' ' + driver.lastName : '',
            deviceId: e.deviceId,
            deviceName: device?.name,
            cameraPosition: e.cameraPosition,
            provider:
              device?.model?.name === CameraModelConfig.MultiIQ.name
                ? CameraModelConfig.MultiIQ.provider
                : device?.model?.name,
            vehicleId: e.vehicleId,
            vehicleName: vehicle?.name,
            fleetName: sortBy(vehicle?.fleets || [], 'name')
              .map(f => f.name)
              .join('\r\n'),
            attachmentId: e.attachmentId,
            rawTimeAt: e.timeAt,
            timeAt: moment(e.timeAt).format(localization.formats.time.formats.dby_imsp),
            eventType: t(
              `Common.CameraEventTypes.${CameraEventTypes[e.type] || CameraEventTypes.OTHER}`
            ),
            get eventLink() {
              return EventUtil.generateEventUrl(
                {
                  id: typeof e.id === 'number' ? e.id : e.id.split('_')[0],
                  eventType: 'CAMERA',
                  timeAt: moment(e.timeAt).valueOf()
                },
                currentUser.id,
                currentUser.auth.key
              );
            },
            location:
              e.location && eventGps?.lat && eventGps?.lng ? (
                <Button
                  type="link"
                  onClick={() => {
                    setGps({
                      lat: eventGps.lat,
                      lng: eventGps.lng,
                      title: e.location
                    });
                    setShowMapModal(true);
                  }}
                >
                  {e.location}
                </Button>
              ) : (
                e.location || '-'
              ),
            status: e.status,
            pristine: eventsNeedReorder.some(
              evt => String(evt.id) === String(e.id) && !evt.dismissed
            ),
            dismissPristine: dismissEventPristine,
            attachmentStatus: extractEventAttachmentStatus(e),
            mimeType: e.mimeType,
            attachmentCount: e.attachmentCount,
            videoRequestTime: moment(e.videoRequestTime).format(
              localization.formats.time.formats.dby_imsp
            ),
            videoRequestStatus: e.videoRequestStatus,
            extras: e.extras,
            details:
              e.videoRequestStatus === 'FAILED' && e.extras && extractEventExtras(e.extras).details
                ? extractEventExtras(e.extras).details
                : '',
            dutyType: e.dutyType
          };
        })
      );

      return processedEvents;
    };

    processEvents().then(processedEvents => {
      if (eventsNeedReorder.length) {
        const reorderedProcessedEvents = orderBy(
          processedEvents,
          pe => {
            switch (syncedEvents.syncedBy.orderField) {
              case 'Fleet':
                return pe.fleetName;
              case 'Vehicle':
                return pe.vehicleName;
              case 'Driver':
                return pe.driverName;
              case 'Device':
                return pe.deviceName;
              case 'Type':
                return [pe.eventType, pe.rawTimeAt];
              case 'Location':
                return pe.location;
              case 'Status':
                return pe.status;
              case 'EventTime':
              default:
                return moment(pe.rawTimeAt).valueOf();
            }
          },
          syncedEvents.syncedBy.orderDirection
        ).slice(0, Math.min(events?.length || 0, eventsQueryContext.current?.totalRowCount || 0));
        setDecoratedEvents(reorderedProcessedEvents);
        setIsLoading(false);
      } else {
        let sortedEvents = processedEvents;
        // Manual sorting for sort by event type in frontend
        const ctx = eventsQueryContext.current;
        if (ctx.filter.orderField === 'Type') {
          sortedEvents = orderBy(
            processedEvents,
            ['eventType', 'rawTimeAt'],
            ctx.filter.orderDirection
          );
        }

        setDecoratedEvents(sortedEvents);
        setIsLoading(false);
      }
    });
  }, [
    events,
    drivers,
    dataStore.allDevices,
    localization.formats.time.formats.dby_imsp,
    t,
    currentUser,
    dismissEventPristine,
    syncedEvents,
    eventsQueryContext.current?.totalRowCount
  ]);

  useEffect(() => {
    if (viewStateRef.current?.stateUpdate) {
      window.eventDataCache = null;
      viewStateRef.current = null;
    } else if (viewStateRef.current) {
      viewStateRef.current.stateUpdate = true;
    }
  }, [dataStore]);

  const handleLoadMoreData = useCallback(async () => {
    if (viewStateRef.current) return;

    const ctx = eventsQueryContext.current;
    if (ctx.request) {
      ctx.request.abort();
      ctx.request = null;
      if (ctx.filter.rowOffset > 0) {
        ctx.filter.rowOffset -= 100;
      }
    }
    ctx.filter.rowOffset += 100;
    setIsLoading(true);
    const search = trim(processSearchText(ctx.filter.search, forVideoRequest));
    ctx.request = dispatch(
      fetchCameraEvents(
        ctx.from.format(),
        ctx.to.format(),
        ctx.filter.devices || undefined,
        ctx.filter.drivers || undefined,
        ctx.filter.eventTypes || undefined,
        ctx.filter.orderField,
        ctx.filter.orderDirection,
        ctx.filter.rowOffset,
        search,
        respJson => {
          ctx.request = null;
          if (respJson) {
            ctx.totalRowCount = respJson.totalCount;
            setEvents(prev => {
              const result = Array.from(prev);
              const idMap = (prev || []).reduce((r, cur, idx) => {
                r[cur.id] = idx;
                return r;
              }, {});
              respJson.events.forEach(e => {
                if (idMap[e.id] >= 0) {
                  if (result[idMap[e.id]].childCount) {
                    result[idMap[e.id]].childCount++;
                  } else {
                    result[idMap[e.id]].childCount = 1;
                  }
                  e.id = e.id + '_' + result[idMap[e.id]].childCount;
                } else {
                  idMap[e.id] = result.length;
                }
                result.push(e);
              });
              return result;
            });
          }
          setIsLoading(false);
        },
        null,
        false,
        forVideoRequest,
        ctx.filter.status || undefined,
        false
      ),
      err => {
        ctx.filter.rowOffset -= 100;
        setIsLoading(false);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t_error(err)
          })
        );
      }
    );

    await ctx.request;
  }, [dispatch, forVideoRequest, processSearchText]);

  const handleSort = useCallback(
    (orderField, orderDir) => {
      updateStore({ type: EventActions.sortEvent, payload: { item: orderField, order: orderDir } });
    },
    [updateStore]
  );

  const handleVR = useCallback(
    (evt, forRetry = false) => {
      setVRState({
        deviceId: evt.deviceId,
        eventId: evt.id,
        showSpinner: true
      });
      setDecoratedEvents(prevEvents => {
        evt.requestVideoUpload = true;
        prevEvents.splice(
          prevEvents.findIndex(v => v.id === evt.id),
          1,
          evt
        );
        return prevEvents;
      });

      const openSuccessToast = () => {
        dispatch(
          openToast({
            type: ToastType.Success,
            linkable: true,
            delay: 10000,
            message: (
              <Trans
                i18nKey={'Footage.RequestVideoSubmitInfo.SuccessSubmitted'}
                components={{
                  1: (
                    <Button
                      type="link"
                      onClick={_ => {
                        history.push(
                          Object.values(CameraTabs).find(t => t.title === 'Video Requests')?.path
                        );
                      }}
                    />
                  )
                }}
              />
            )
          })
        );
      };

      if (forRetry) {
        dispatch(requestRetryVideoFootage(evt.deviceId, evt.id, evt.provider, evt.rawTimeAt))
          .then(
            d => {
              openSuccessToast();
              setDecoratedEvents(prevEvents => {
                delete evt.requestVideoUpload;
                evt.videoRequestStatus = Object.entries(EventStatusToRequestStatus).find(
                  ([, value]) => value === EventStatusToRequestStatus.INPROGRESS
                )[0];
                prevEvents.splice(
                  prevEvents.findIndex(v => v.id === evt.id),
                  1,
                  evt
                );
                return Array.from(prevEvents);
              });
            },
            e => {
              let { message } = extractCameraError(e);
              dispatch(
                openToast({
                  message: message,
                  type: ToastType.Error
                })
              );
              setDecoratedEvents(prevEvents => {
                delete evt.requestVideoUpload;
                prevEvents.splice(
                  prevEvents.findIndex(v => v.id === evt.id),
                  1,
                  evt
                );
                return prevEvents;
              });
            }
          )
          .finally(() => {
            setVRState({
              deviceId: null,
              eventId: null,
              showSpinner: false
            });
          });
      } else {
        dispatch(requestVideoUpload(evt.deviceId, evt.id, evt.provider, evt.rawTimeAt))
          .then(
            d => {
              openSuccessToast();
              delete evt.requestVideoUpload;
              setEvents(prevEvents => {
                return prevEvents.map(existingEvent => {
                  if (existingEvent.id === evt.id) {
                    const updatedEvent = {
                      ...existingEvent,
                      attachmentStatus: Object.entries(EventStatusToRequestStatus).find(
                        ([, value]) => value === EventStatusToRequestStatus.REQUESTED
                      )[0]
                    };
                    return updatedEvent;
                  } else {
                    return existingEvent;
                  }
                });
              });
            },
            e => {
              let { message } = extractCameraError(e);
              dispatch(
                openToast({
                  message: message,
                  type: ToastType.Error
                })
              );
              setDecoratedEvents(prevEvents => {
                delete evt.requestVideoUpload;
                prevEvents.splice(
                  prevEvents.findIndex(v => v.id === evt.id),
                  1,
                  evt
                );
                return prevEvents;
              });
            }
          )
          .finally(() => {
            setVRState({
              deviceId: null,
              eventId: null,
              showSpinner: false
            });
          });
      }
    },
    [dispatch, history, t]
  );

  const handleViewEvent = useCallback(
    event => {
      if (
        !event.attachmentCount ||
        ![AttachmentStatus.AVAILABLE, AttachmentStatus.ENABLED].includes(event.attachmentStatus)
      ) {
        return;
      }

      window.eventDataCache = {
        viewMode,
        dataStore,
        events,
        decoratedEvents,
        eventsQueryContext: eventsQueryContext.current,
        companyId: currentCompany.id
      };
      const cameraEvtMap = EventNamesMap['Ng::Event::Camera'];
      const eventLink =
        '/view_events/' +
        cameraEvtMap.eventUrl({ ...event, timeAt: moment(event.rawTimeAt).valueOf() });
      history.replace(history.location.pathname, { hasContext: true });
      history.push(eventLink);
    },
    [history, viewMode, dataStore, events, decoratedEvents, currentCompany]
  );

  const pullNewComingEvents = useCallback(
    async (merge = false, syncedEvents = {}, lastTotalCount = 0) => {
      const ctx = eventsQueryContext.current;
      if (ctx.syncRequest?.abort) {
        ctx.syncRequest.abort();
      }
      const orderField = ctx.filter.orderField,
        orderDirection = ctx.filter.orderDirection,
        params = {
          from: ctx.from.format(),
          to: ctx.to.format(),
          devices: ctx.filter.devices || undefined,
          drivers: ctx.filter.drivers || undefined,
          eventTypes: ctx.filter.eventTypes || undefined,
          orderField: 'EventTime',
          orderDirection: 'desc',
          search: trim(processSearchText(ctx.filter.search, forVideoRequest)),
          allAttachment: false,
          forVideoRequest,
          status: ctx.filter.status || undefined
        },
        getComparableParams = params =>
          Object.entries(params || {}).reduce(
            (a, [key, v]) =>
              ['orderDirection', 'orderField'].some(skipKey => skipKey === key)
                ? a
                : { ...a, [key]: v },
            {}
          );

      setIsRefreshing(true);
      ctx.syncRequest = dispatch(
        fetchCameraEvents(
          params.from,
          params.to,
          params.devices,
          params.drivers,
          params.eventTypes,
          params.orderField,
          params.orderDirection,
          0,
          params.search,
          respJson => {
            const totalCount = isNaN(Number(respJson?.totalCount || 0))
                ? 0
                : Number(respJson?.totalCount || 0),
              firstPartEvents = respJson?.events || [];
            const addedCount = totalCount - lastTotalCount;

            const idMap = {},
              latestEvents = [];

            (firstPartEvents || []).forEach(e => {
              if (idMap[e.id] >= 0) {
                if (latestEvents[idMap[e.id]].childCount) {
                  latestEvents[idMap[e.id]].childCount++;
                } else {
                  latestEvents[idMap[e.id]].childCount = 1;
                }
                e.id = e.id + '_' + latestEvents[idMap[e.id]].childCount;
              } else {
                idMap[e.id] = latestEvents.length;
              }
              latestEvents.push(e);
            });

            const refreshSuccess = (events, addedCount) => {
              const addedEvents = events.slice(0, addedCount);

              const isSameCtx = syncedEvents?.isSameCtx || (() => false),
                syncedResult = syncedEvents?.events || [];
              setSyncedEvents({
                events: isSameCtx(params)
                  ? uniqBy([...addedEvents, ...syncedResult], 'id')
                  : addedEvents,
                syncedBy: { orderField, orderDirection },
                isSameCtx: ctxParams =>
                  isEqual(getComparableParams(params), getComparableParams(ctxParams)),
                totalCount
              });
              if (merge) {
                ctx.totalRowCount = totalCount;
                setEvents(prev => uniqBy([...events, ...prev], 'id'));
              }
              ctx.syncRequest = null;
              setIsRefreshing(false);
            };

            const createRequest = subsqReqs => {
              const reqIds = subsqReqs.map((_, reqIndex) =>
                RequestQueue.queueRequest(
                  async () => {
                    const ret = {
                      success: false,
                      reqIndex,
                      rowOffset: reqIndex * 100 + firstPartEvents.length,
                      events: []
                    };
                    try {
                      await dispatch(
                        fetchCameraEvents(
                          params.from,
                          params.to,
                          params.devices,
                          params.drivers,
                          params.eventTypes,
                          params.orderField,
                          params.orderDirection,
                          ret.rowOffset,
                          params.search,
                          respJson => {
                            ret.success = true;
                            ret.events = respJson?.events || [];
                          },
                          null,
                          params.allAttachment,
                          params.forVideoRequest,
                          params.status,
                          false
                        )
                      );
                    } catch (error) {
                      ret.success = false;
                    }
                    return ret;
                  },
                  data => {
                    subsqReqs[data.reqIndex] = data;
                    if (subsqReqs.every(req => !!req.success)) {
                      const subsqEvents = subsqReqs.reduce(
                        (a, req) => [...a, ...req.events],
                        firstPartEvents
                      );

                      refreshSuccess(subsqEvents, addedCount);
                    }
                  },
                  null,
                  null
                )
              );
              ctx.syncRequest = {
                abort: () => {
                  for (const reqId of reqIds) {
                    RequestQueue.removeRequest(reqId);
                  }
                }
              };
            };

            if (addedCount > 0) {
              let requestCount = 0;

              if (addedCount <= firstPartEvents.length) {
                if (ctx.filter.rowOffset) {
                  const subsqReqs = new Array(Math.ceil(ctx.filter.rowOffset / 100)).fill(1);
                  createRequest(subsqReqs);
                } else {
                  refreshSuccess(latestEvents, addedCount);
                }
              } else {
                if (ctx.filter.rowOffset) {
                  requestCount = ctx.filter.rowOffset / 100;
                }

                const subsqReqs = new Array(
                  Math.ceil((addedCount - firstPartEvents.length) / 100) + requestCount
                ).fill(1);

                createRequest(subsqReqs);
              }
            } else {
              if (ctx.filter.rowOffset) {
                const subsqReqs = new Array(Math.ceil(ctx.filter.rowOffset / 100)).fill(1);
                const reqIds = subsqReqs.map((_, reqIndex) =>
                  RequestQueue.queueRequest(
                    async () => {
                      const ret = {
                        success: false,
                        reqIndex,
                        rowOffset: reqIndex * 100 + firstPartEvents.length,
                        events: []
                      };
                      try {
                        await dispatch(
                          fetchCameraEvents(
                            params.from,
                            params.to,
                            params.devices,
                            params.drivers,
                            params.eventTypes,
                            params.orderField,
                            params.orderDirection,
                            ret.rowOffset,
                            params.search,
                            respJson => {
                              ret.success = true;
                              ret.events = respJson?.events || [];
                            },
                            null,
                            params.allAttachment,
                            params.forVideoRequest,
                            params.status,
                            false
                          )
                        );
                      } catch (error) {
                        ret.success = false;
                      }
                      return ret;
                    },
                    data => {
                      subsqReqs[data.reqIndex] = data;
                      if (subsqReqs.every(req => !!req.success)) {
                        const subsqEvents = subsqReqs.reduce(
                          (a, req) => [...a, ...req.events],
                          firstPartEvents
                        );

                        setEvents(prev => uniqBy([...subsqEvents, ...prev], 'id'));
                      }
                    },
                    null,
                    null
                  )
                );
                ctx.syncRequest = {
                  abort: () => {
                    for (const reqId of reqIds) {
                      RequestQueue.removeRequest(reqId);
                    }
                  }
                };
              } else {
                setEvents(prev => uniqBy([...latestEvents, ...prev], 'id'));
              }
              ctx.syncRequest = null;
              setIsRefreshing(false);
            }
          },
          err => {
            ctx.syncRequest = null;
            setIsRefreshing(false);
          },
          params.allAttachment,
          params.forVideoRequest,
          params.status,
          false
        )
      );
      await ctx.syncRequest;
    },
    [dispatch, forVideoRequest, processSearchText]
  );

  const countConfig = useMemo(() => {
    const ctxTotalCount = eventsQueryContext.current?.totalRowCount || 0;
    const addedCount = (syncedEvents?.events || []).filter(evt => !evt.dismissed).length;
    const isPartialLoaded = (events?.length || 0) < ctxTotalCount;
    const totalCount = isPartialLoaded ? syncedEvents?.totalCount || ctxTotalCount : ctxTotalCount;
    return {
      disabled: forVideoRequest || isLoading,
      totalCount,
      isRefreshing,
      addedCount,
      onRefresh: () => pullNewComingEvents(!isPartialLoaded, syncedEvents, totalCount)
    };
  }, [
    forVideoRequest,
    isLoading,
    isRefreshing,
    eventsQueryContext.current?.totalRowCount,
    pullNewComingEvents,
    syncedEvents,
    events
  ]);

  return (
    <div className={styles.tab} id={id}>
      <Toolbar
        filterOptions={filterOptions}
        dataStore={dataStore}
        actions={actions}
        countConfig={countConfig}
      />
      {viewMode === ViewMode.GridView && (
        <GridView
          events={decoratedEvents}
          onLoadMoreData={handleLoadMoreData}
          onRequestVideo={handleVR}
          isLoading={isLoading}
          totalCount={eventsQueryContext.current?.totalRowCount || 0}
          onViewEvent={handleViewEvent}
        />
      )}
      {viewMode === ViewMode.ListView && (
        <>
          <ListView
            events={decoratedEvents}
            totalCount={eventsQueryContext.current?.totalRowCount || 0}
            isLoading={isLoading}
            onLoadMoreData={handleLoadMoreData}
            onRequestVideo={handleVR}
            onSort={handleSort}
            onViewEvent={handleViewEvent}
            forVideoRequest={forVideoRequest}
            tableColumns={tableColumns}
          />
          <MapModal
            show={showMapModal}
            coordinates={{
              lat: gps.lat,
              lng: gps.lng
            }}
            title={gps.title}
            onHide={() => setShowMapModal(false)}
          />
        </>
      )}
      {vrState.showSpinner && <LoadingSpinner />}
    </div>
  );
}
