import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Divider, Space, Tooltip } from 'antd';
import { SnapshotConfigPopup } from 'features/dashboard/camera_tab/SnapshotConfig';
import { Can, FeatureFlag, GlobalRoles, services, useCanFeatureFlag } from 'features/permissions';
import {
  fetchCompanyFleetData,
  fetchDeviceStats,
  refreshDevicesSnapshots,
  actionTypes,
  updateDevicesSnapshotsQueryParams
} from 'features/dashboard/dashboardSlice';
import { useUserKey } from 'features/user/userSlice';
import { fetchSnapshots } from 'features/camera/cameraApi';
import { ReloadOutlined, SettingOutlined } from '@ant-design/icons';

export function SnapshotsTabExtras() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showConfigPopup, setShowConfigPopup] = useState(false);

  const location = useLocation();
  const { isRefreshing, onRefresh, canRefresh } = useRefresh();
  const handleRefresh = useCallback(async () => {
    if (location.pathname.includes('cameras_new')) {
      await onRefresh();
    } else {
      dispatch(refreshDevicesSnapshots());
    }
  }, [dispatch, onRefresh]);

  const canCameraHealth = useCanFeatureFlag({
    featureFlag: FeatureFlag.iqCameraHealth.flag
  });

  return (
    <Space
      size={[canCameraHealth ? 6 : 16]}
      split={canCameraHealth ? <Divider type="vertical" /> : null}
    >
      {canCameraHealth ? (
        <Tooltip title={t('Home.Refresh')}>
          <Button
            style={{ minWidth: '20px', width: '20px', height: '20px' }}
            type="text"
            icon={<ReloadOutlined style={{ color: '#64748B', fontSize: '20px' }} />}
            onClick={handleRefresh}
          ></Button>
        </Tooltip>
      ) : (
        <Button
          type="primary"
          onClick={handleRefresh}
          disabled={location.pathname.includes('cameras_new') ? !canRefresh || isRefreshing : false}
        >
          {t('Home.Refresh')}
        </Button>
      )}
      <Can oneOfRoles={[GlobalRoles.Admin, GlobalRoles.SiteAdmin]} everyService={[services.CAMERA]}>
        {canCameraHealth ? (
          <Tooltip title={t('Home.Configure')} placement="topLeft">
            <Button
              style={{ minWidth: '20px', width: '20px', height: '20px' }}
              type="text"
              icon={<SettingOutlined style={{ color: '#64748B', fontSize: '20px' }} />}
              onClick={() => setShowConfigPopup(true)}
            ></Button>
          </Tooltip>
        ) : (
          <Button onClick={() => setShowConfigPopup(true)}>{t('Home.Configure')}</Button>
        )}
        <SnapshotConfigPopup show={showConfigPopup} onHide={() => setShowConfigPopup(false)} />
      </Can>
    </Space>
  );
}

const useRefresh = () => {
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const [isLoadingSnapshots, setIsLoadingSnapshots] = useState(false);
  const queryParams = useSelector(state => state.dashboardData.devicesSnapshots.queryParams);

  const isRefreshing = useSelector(state => {
    const isFetchingCompanyFleet =
      state.dashboardData.companyFleets.status?.companyId?.fetching === actionTypes.processing;
    const isFetchingCompanyDeviceStats =
      state.dashboardData.deviceStats.status?.companyId?.fetching === actionTypes.processing;
    return isFetchingCompanyFleet && isFetchingCompanyDeviceStats && isLoadingSnapshots;
  });

  const canRefresh = useMemo(
    () =>
      queryParams?.companyId &&
      queryParams?.period?.from &&
      queryParams?.period?.to &&
      queryParams?.orderField &&
      queryParams?.orderDirection &&
      !!queryParams?.deviceIds?.length,
    [queryParams]
  );

  const onRefresh = useCallback(async () => {
    if (canRefresh) {
      const {
        companyId,
        period: { from: startTime, to: endTime },
        orderDirection,
        orderField,
        searchText: search,
        rowOffset,
        rowCountLimit,
        deviceIds: devices
      } = queryParams;
      return Promise.all([
        dispatch(fetchCompanyFleetData(userKey, companyId, 'fleets,vehicles,devices')),
        dispatch(fetchDeviceStats(userKey, companyId, 'users'))
      ]).then(() => {
        if (rowOffset === 0) {
          setIsLoadingSnapshots(true);
          return dispatch(
            fetchSnapshots({
              startTime: startTime.format(),
              endTime: endTime.format(),
              devices,
              orderField,
              orderDirection,
              rowOffset: 0,
              search,
              rowCountLimit,
              latestOnly: true,
              onSuccess() {
                setIsLoadingSnapshots(false);
              },
              onError(error) {
                setIsLoadingSnapshots(false);
              }
            })
          );
        } else {
          dispatch(updateDevicesSnapshotsQueryParams({ rowOffset: 0 }));
        }
      });
    }
  }, [dispatch, canRefresh, userKey]);

  return {
    isRefreshing,
    canRefresh,
    onRefresh
  };
};
