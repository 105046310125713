export const MENU_IDS = {
  home: 'menu_home',
  tracking: 'menu_tracking',
  fbtmanager: 'menu_fbtManager',
  scorecard: 'menu_scorecard',
  smartjobs: 'menu_smartjobs',
  messaging: 'menu_messaging',
  insights: 'menu_insights',
  reports: 'menu_reports',
  store: 'menu_store',
  settings: 'menu_settings',
  supportTools: 'menu_supportTools',
  supportToolsELD: 'menu_supportToolsELD',
  supportToolsInstallationAuditLog: 'menu_supportToolsInstallationAuditLog',
  vehiclemaintenance: 'menu_vehicleMaintenance',
  drivermanagement: 'menu_driverManagement',
  easydocs: 'menu_easydocs',
  journeyplanner: 'menu_journeyPlanner',
  fatigueTacho: 'menu_fatigueTacho',
  fatigueEld: 'menu_fatigueEld',
  fatigueDriverPortal: 'menu_fatigueDriverPortal',
  fatigue: 'menu_fatigue',
  integrations: 'menu_integrations',
  mixedEnergy: 'menu_mixedEnergy',
  rucManager: 'menu_rucManager',
  vlt: 'menu_vlt',
  onsiteTraffic: 'menu_onsiteTraffic',
  driveriCamera: 'menu_driveriCamera',
  admin: 'menu_admin',
  users: 'menu_users',
  roles: 'menu_roles',
  fleets: 'menu_fleets',
  vehicles: 'menu_vehicles',
  devices: 'menu_devices',
  locations: 'menu_locations',
  geofences: 'menu_geofences',
  wifi: 'menu_wifi',
  subCompany: 'menu_subCompany',
  config: 'menu_config',
  companyAlerts: 'menu_companyAlerts',
  companyConfig: 'menu_companyConfig',
  driverMgtTypes: 'menu_driverMgtTypes',
  forms: 'menu_forms',
  pretripChecklist: 'menu_pretripChecklist',
  inspectionChecklist: 'menu_inspectionChecklist',
  vehicleMntTypes: 'menu_vehicleMntTypes',
  vehicleTypes: 'menu_vehicleTypes',
  smartJobsCodes: 'menu_smartJobsCodes',
  massManagement: 'menu_massManagement',
  tcaEnrolments: 'menu_tcaEnrolments',
  user: 'menu_user',
  alerts: 'menu_alerts',
  automation: 'menu_automation',
  acm: 'menu_acm',
  preferences: 'menu_preferences'
};

export const BUTTON_IDS = {
  addFile: 'btn_addFile',
  addFilterColumnsModal: 'btn_addFilterColumnsModal',
  addDriverIdTemplate: 'btn_addDriverIdTemplate',
  addGPIOTemplate: 'btn_addGPIOTemplate',
  addAssetsTemplate: 'btn_addAssetsTemplate',
  addNewViewsConfig: 'btn_addNewViewsConfig',
  addTypeAdd: 'btn_addTypeAdd',
  addTypeCancel: 'btn_addTypeCancel',
  addTypeSave: 'btn_addTypeSave',
  alertsViewAdd: 'btn_alertsViewAdd',
  allocateModalRemove: 'btn_allocateModalRemove',
  applyFiltersColumnsModal: 'btn_applyFiltersColumnsModal',
  applyViewsConfig: 'btn_applyViewsConfig',
  assignLogCancel: 'btn_assignLogCancel',
  assignLogUpdate: 'btn_assignLogUpdate',
  auditViewHeader: 'btn_auditViewHeader',
  configureViewHeader: 'btn_configureViewHeader',
  automationSetupAlexa: 'btn_automationSetupAlexa',
  automationSetupIFT: 'btn_automationSetupIFT',
  automationSetupZapier: 'btn_automationSetupZapier',
  bulkManagementExport: 'btn_bulkManagementExport',
  bulkManagementExportCancel: 'btn_bulkManagementExportCancel',
  bulkManagementExportOpen: 'btn_bulkManagementExportOpen',
  bulkManagementImport: 'btn_bulkManagementImport',
  bulkManagementImportOpen: 'btn_bulkManagementImportOpen',
  bulkManagementImportReset: 'btn_bulkManagementImportReset',
  calendarPopup: 'btn_calendarPopup',
  calendarSwitchToView: 'btn_calendarSwitchToView',
  calibrationElement: 'btn_calibrationElement',
  cancelAddViewModal: 'btn_cancelAddViewModal',
  cancelFiltersColumnsModal: 'btn_cancelFiltersColumnsModal',
  cancelViewsConfig: 'btn_cancelViewsConfig',
  configFormsAdd: 'btn_configFormsAdd',
  configureVehiclesCancel: 'btn_configureVehiclesCancel',
  configureVehiclesSave: 'btn_configureVehiclesSave',
  contactFormCancel: 'btn_contactFormCancel',
  contactFormReset: 'btn_contactFormReset',
  contactFormSave: 'btn_contactFormSave',
  contentFolderAssociate: 'btn_contentFolderAssociate',
  contentFolderDeleteFolder: 'btn_contentFolderDeleteFolder',
  contentFolderDeleteSelection: 'btn_contentFolderDeleteSelection',
  contentFolderEdit: 'btn_contentFolderEdit',
  controlPanelEllipsis: 'btn_controlPanelEllipsis',
  cameraEventsFilterBy: 'btn_homeCamerasEventsFilterBy',
  dashboardGridEllipsis: 'btn_dashboardGridEllipsis',
  dateRangeBackwardDays: 'btn_dateRangeBackwardDays',
  dateRangeForwardDays: 'btn_dateRangeForwardDays',
  deleteFilterColumnsRow: 'btn_deleteFilterColumnsRow',
  deleteViewHeader: 'btn_deleteViewHeader',
  deleteViewsConfig: 'btn_deleteViewsConfig',
  deviceEventGridContent: 'btn_deviceEventGridContent',
  deviceFormCancel: 'btn_deviceFormCancel',
  deviceFormSave: 'btn_deviceFormSave',
  devicesAddNew: 'btn_devicesAddNew',
  devicesBulkEdit: 'btn_devicesBulkEdit',
  devicesExportExcel: 'btn_devicesExportExcel',
  devicesBulkEditAudits: 'btn_devicesBulkEditAudits',
  dismissAlertDataNotif: 'btn_dismissAlertDataNotif',
  dismissAllNotif: 'btn_dismissAllNotif',
  documentViewDownload: 'btn_documentViewDownload',
  documentViewEdit: 'btn_documentViewEdit',
  documentViewExportExcel: 'btn_documentViewExportExcel',
  driverCardPin: 'btn_driverCardPin',
  driverIdFormSave: 'btn_driverIdFormSave',
  driverIdFormCancel: 'btn_driverIdFormCancel',
  driverLogBackDay: 'btn_driverLogBackDay',
  driverLogForwardDay: 'btn_driverLogForwardDay',
  driverLogGridEdit: 'btn_driverLogGridEdit',
  driverLogHeaderShow: 'btn_driverLogHeaderShow',
  driverLogMessage: 'btn_driverLogMessage',
  driverLogShowMoreMenu: 'btn_driverLogShowMoreMenu',
  driverLogSuggestRecord: 'btn_driverLogSuggestRecord',
  driverLogView: 'btn_driverLogView',
  driverMgtAdd: 'btn_driverMgtAdd',
  driverMgtCompleteCancel: 'btn_driverMgtCompleteCancel',
  driverMgtCompleteSave: 'btn_driverMgtCompleteSave',
  driverMgtFormCancel: 'btn_driverMgtFormCancel',
  driverMgtFormSave: 'btn_driverMgtFormSave',
  driverMgtManageTypes: 'btn_driverMgtManageTypes',
  driverMgtTypeAdd: 'btn_driverMgtTypeAdd',
  driverMgtTypeFormCancel: 'btn_driverMgtTypeFormCancel',
  driverMgtTypeFormSave: 'btn_driverMgtTypeFormSave',
  driverMgtViewAudits: 'btn_driverMgtViewAudits',
  driverMgtViewCancel: 'btn_driverMgtViewCancel',
  driverMgtViewComplete: 'btn_driverMgtViewComplete',
  driverMgtViewDelete: 'btn_driverMgtViewDelete',
  driverMgtViewEdit: 'btn_driverMgtViewEdit',
  driverMgtViewRestore: 'btn_driverMgtViewRestore',
  driverPortalSwitchStatus: 'btn_driverPortalSwitchStatus',
  driverPortalSwitchStatusSave: 'btn_driverPortalSwitchStatusSave',
  driverPortalSwitchStatusCancel: 'btn_driverPortalSwitchStatusCancel',
  driverPortalNewStatus: 'btn_driverPortalNewStatus',
  driverPortalPrint: 'btn_driverPortalPrint',
  driverPortalPrintSave: 'btn_driverPortalPrintSave',
  driverPortalPrintCancel: 'btn_driverPortalPrintCancel',
  driverPortalEmail: 'btn_driverPortalEmail',
  driverPortalEmailSend: 'btn_driverPortalEmailSend',
  driverPortalEmailCancel: 'btn_driverPortalEmailCancel',
  driverPortalSuggestedLogs: 'btn_driverPortalSuggestedLogs',
  driverPortalSuggestedLogsSave: 'btn_driverPortalSuggestedLogsSave',
  driverPortalSuggestedLogsCancel: 'btn_driverPortalSuggestedLogsCancel',
  driverPortalUnsignedLogs: 'btn_driverPortalUnsignedLogs',
  driverPortalUnsignedLogsSave: 'btn_driverPortalUnsignedLogsSave',
  driverPortalUnsignedLogsCancel: 'btn_driverPortalUnsignedLogsCancel',
  driverPortalUnsignedLogSave: 'btn_driverPortalUnsignedLogSave',
  driverPortalUnsignedLogCancel: 'btn_driverPortalUnsignedLogCancel',
  driverPortalUnidentifiedDriverProfile: 'btn_driverPortalUnidentifiedDriverProfile',
  dropdownTree: 'btn_dropdownTree',
  duressActiveNotif: 'btn_duressActiveNotif',
  dvirFormCancel: 'btn_dvirFormCancel',
  dvirFormSave: 'btn_dvirFormSave',
  easydocsExportExcel: 'btn_easydocsExportExcel',
  inspectionSummaryExportExcel: 'btn_inspectionSummaryExportExcel',
  inspectionSummaryRestoreDefault: 'btn_inspectionSummaryRestoreDefault',
  easydocsInformationExportExcel: 'btn_easydocsInformationExportExcel',
  easydocsFormModalCancel: 'btn_easydocsFormModalCancel',
  easydocsFormUploadNext: 'btn_easydocsFormUploadNext',
  easydocsShowUpload: 'btn_easydocsShowUpload',
  editFilesFormSave: 'btn_editFilesFormSave',
  editJobAdd: 'btn_editJobAdd',
  editJobCancel: 'btn_editJobCancel',
  editJobFinish: 'btn_editJobFinish',
  editJobNext: 'btn_editJobNext',
  editViewHeader: 'btn_editViewHeader',
  edlExport: 'btn_edlExport',
  eldBulkAssignment: 'btn_eldBulkAssignment',
  fbtBulkAssignment: 'btn_fbtBulkAssignment',
  fbtBulkUpdate: 'btn_fbtBulkUpdate',
  fbtAssignCancel: 'btn_fbtAssignCancel',
  eldCarrierNew: 'btn_eldCarrierNew',
  eldConfigCancel: 'btn_eldConfigCancel',
  eldConfigSave: 'btn_eldConfigSave',
  eldExportLogs: 'btn_eldExportLogs',
  errorPageBackTo: 'btn_errorPageBackTo',
  eventPreviewView: 'btn_eventPreviewView',
  eventsAnnotationsAdd: 'btn_eventsAnnotationsAdd',
  ewdAddEditSave: 'btn_ewdAddEditSave',
  ewdExport: 'btn_ewdExport',
  ewdLink: 'btn_ewdLink',
  ewdTableDropdown: 'btn_ewdTableDropdown',
  ewdTabLink: 'btn_ewdTabLink',
  ewdTabUnlink: 'btn_ewdTabUnlink',
  ewdUnlink: 'btn_ewdUnlink',
  ewdUpdatePassword: 'btn_ewdUpdatePassword',
  ewdViewDriverActivity: 'btn_ewdViewDriverActivity',
  expand: 'btn_expand',
  expandAll: 'btn_expandAll',
  exportExcel: 'btn_exportExcel',
  fatigueToolbarExpand: 'btn_fatigueToolbarExpand',
  fbtTableExpand: 'btn_fbtTableExpand',
  filterEventsApply: 'btn_filterEventsApply',
  filterEventsCancel: 'btn_filterEventsCancel',
  filterEventsRemove: 'btn_filterEventsRemove',
  fleetFormCancel: 'btn_fleetFormCancel',
  fleetFormSave: 'btn_fleetFormSave',
  fleetsAddNew: 'btn_fleetsAddNew',
  fleetsExpandList: 'btn_fleetsExpandList',
  fleetsExportExcel: 'btn_fleetsExportExcel',
  float: 'btn_floatButton',
  forceLogoff: 'btn_forceLogoff',
  form: 'btn_form',
  formDatePicker: 'btn_formDatePicker',
  formFooterCancel: 'btn_formFooterCancel',
  formFooterSave: 'btn_formFooterSave',
  formsFormCancel: 'btn_formsFormCancel',
  formsFormSave: 'btn_formsFormSave',
  geofenceFormCancel: 'btn_geofenceFormCancel',
  geofenceFormReset: 'btn_geofenceFormReset',
  geofenceFormSave: 'btn_geofenceFormSave',
  geofenceFormSearch: 'btn_geofenceFormSearch',
  geofencesPrimary: 'btn_geofencesPrimary',
  geofenceViewEdit: 'btn_geofenceViewEdit',
  geofenceViewAudit: 'btn_geofenceViewAudit',
  geofenceViewDelete: 'btn_geofenceViewDelete',
  geofenceViewCancel: 'btn_geofenceViewCancel',
  goToNotif: 'btn_goToNotif',
  gpioTablesUpdate: 'btn_gpioTablesUpdate',
  headerPrimary: 'btn_headerPrimary',
  headerSecondary: 'btn_headerSecondary',
  insEntityRemoveAdd: 'btn_insEntityRemoveAdd',
  insightsAdd: 'btn_insightsAdd',
  insightsEdit: 'btn_insightsEdit',
  insightsVizPadCancel: 'btn_insightsVizPadCancel',
  insightsVizPadSave: 'btn_insightsVizPadSave',
  inspectionChecklistAdd: 'btn_inspectionChecklistAdd',
  inspectionChecklistAddDvir: 'btn_inspectionChecklistAddDvir',
  inspectionChecklistFormCancel: 'btn_inspectionChecklistFormCancel',
  inspectionChecklistFormSave: 'btn_inspectionChecklistFormSave',
  iqBulkEditCancel: 'btn_iqBulkEditCancel',
  iqBulkEditSave: 'btn_iqBulkEditSave',
  iqCameraCancel: 'btn_iqCameraCancel',
  iqCameraSave: 'btn_iqCameraSave',
  iqCameraSaveAll: 'btn_iqCameraSaveAll',
  iqCameraPresetsExport: 'btn_iqCameraPresetsExport',
  iqCameraConfigAudit: 'btn_iqCameraConfigAudit',
  jobDetailsDownloadPdf: 'btn_jobDetailsDownloadPdf',
  jobFormAdd: 'btn_jobFormAdd',
  jobFormCancel: 'btn_jobFormCancel',
  jobFormFinish: 'btn_jobFormFinish',
  jobFormNext: 'btn_jobFormNext',
  journeyMapAdd: 'btn_journeyMapAdd',
  journeyPlannerAdd: 'btn_journeyPlannerAdd',
  journeyPlannerFormAdd: 'btn_journeyPlannerFormAdd',
  journeyPlannerFormCancel: 'btn_journeyPlannerFormCancel',
  journeyPlannerFormClear: 'btn_journeyPlannerFormClear',
  journeyPlannerFormSave: 'btn_journeyPlannerFormSave',
  journeyPlannerFormVerify: 'btn_journeyPlannerFormVerify',
  lensSearchDraw: 'btn_lensSearchDraw',
  lensSearchFind: 'btn_lensSearchFind',
  lensSearchReset: 'btn_lensSearchReset',
  locationFormCancel: 'btn_locationFormCancel',
  locationFormSave: 'btn_locationFormSave',
  locationsPrimary: 'btn_locationsPrimary',
  logExportExpand: 'btn_logExportExpand',
  loginAsUser: 'btn_loginAsUser',
  loginAsUserViewHeader: 'btn_loginAsUserViewHeader',
  loginSignIn: 'btn_loginSignIn',
  logRecordAccept: 'btn_logRecordAccept',
  logRecordCancel: 'btn_logRecordCancel',
  logRecordNext: 'btn_logRecordNext',
  logRecordPrev: 'btn_logRecordPrev',
  logRecordReject: 'btn_logRecordReject',
  logRecordSuggest: 'btn_logRecordSuggest',
  logRecordSuggestClose: 'btn_logRecordSuggestClose',
  logTransfer: 'btn_logTransfer',
  logTransferCancel: 'btn_logTransferCancel',
  manageColumnsModalCancel: 'btn_manageColumnsModalCancel',
  manageColumnsModalReset: 'btn_manageColumnsModalReset',
  manageColumnsModalSave: 'btn_manageColumnsModalSave',
  userManageColumnsModalCancel: 'btn_userManageColumnsModalCancel',
  userManageColumnsModalReset: 'btn_userManageColumnsModalReset',
  userManageColumnsModalSave: 'btn_userManageColumnsModalSave',
  mapModalFooterClose: 'btn_mapModalFooterClose',
  massConfigurationModalCancel: 'btn_massConfigurationModalCancel',
  massConfigurationModalSave: 'btn_massConfigurationModalSave',
  massConfigurationsAddNew: 'btn_massConfigurationsAddNew',
  massManagementConfigure: 'btn_massManagementConfigure',
  messageTemplatesCreate: 'btn_messageTemplatesCreate',
  messagingChatBoxClose: 'btn_messagingChatBoxClose',
  messagingChatBoxDelete: 'btn_messagingChatBoxDelete',
  messagingChatBoxSearch: 'btn_messagingChatBoxSearch',
  messagingChatBoxSearchClose: 'btn_messagingChatBoxSearchClose',
  messagingChatBoxSelect: 'btn_messagingChatBoxSelect',
  messagingChatBoxSubmit: 'btn_messagingChatBoxSubmit',
  messagingListSort: 'btn_messagingListSort',
  messagingNew: 'btn_messagingNew',
  metricScoringReset: 'btn_metricScoringReset',
  metricScoringSave: 'btn_metricScoringSave',
  multiCarrierCancel: 'btn_multiCarrierCancel',
  multiCarrierSave: 'btn_multiCarrierSave',
  multiValueCancel: 'btn_multiValueCancel',
  multiValueSave: 'btn_multiValueSave',
  multiValueShowModal: 'btn_multiValueShowModal',
  muteNotif: 'btn_muteNotif',
  nearestResultPaneReset: 'btn_nearestResultPaneReset',
  nearestSearchPaneFind: 'btn_nearestSearchPaneFind',
  nearestSearchPaneReset: 'btn_nearestSearchPaneReset',
  popupMenu: 'btn_popupMenu',
  preferencesCancel: 'btn_preferencesCancel',
  preferencesSave: 'btn_preferencesSave',
  pretripChecklistAdd: 'btn_pretripChecklistAdd',
  pretripChecklistFormAdd: 'btn_pretripChecklistFormAdd',
  pretripChecklistFormCancel: 'btn_pretripChecklistFormCancel',
  printPdf: 'btn_printPdf',
  removeViewsConfigHelpers: 'btn_removeViewsConfigHelpers',
  renameViewsConfig: 'btn_renameViewsConfig',
  requestFootage: 'btn_requestFootage',
  requestVideo: 'btn_requestVideo',
  requestVideoCancel: 'btn_requestVideoCancel',
  resetPassword: 'btn_resetPassword',
  restoreViewHeader: 'btn_restoreViewHeader',
  rolesAddNew: 'btn_rolesAddNew',
  rolesFormCancel: 'btn_rolesFormCancel',
  rolesFormSave: 'btn_rolesFormSave',
  rolesPermissions: 'btn_rolesPermissions',
  udtRoutePreviewUpdate: 'btn_udtRoutePreviewUpdate',
  udtRoutePreviewClose: 'btn_udtRoutePreviewClose',
  udtByVehicleTableExpand: 'btn_udtByVehicleTableExpand',
  routePreviewReverse: 'btn_routePreviewReverse',
  routePreviewShow: 'btn_routePreviewShow',
  routeToSendMessage: 'btn_routeToSendMessage',
  runsheetFormAdd: 'btn_runsheetFormAdd',
  saveAddViewModal: 'btn_saveAddViewModal',
  saveViewsConfig: 'btn_saveViewsConfig',
  scheduleAddEditCancel: 'btn_scheduleAddEditCancel',
  scheduleAddEditSave: 'btn_scheduleAddEditSave',
  scheduleViewAudits: 'btn_scheduleViewAudits',
  scheduleViewCancel: 'btn_scheduleViewCancel',
  scheduleViewDelete: 'btn_scheduleViewDelete',
  scheduleViewEdit: 'btn_scheduleViewEdit',
  scheduleViewEllipsis: 'btn_scheduleViewEllipsis',
  scheduleViewReschedule: 'btn_scheduleViewReschedule',
  scheduleViewRestore: 'btn_scheduleViewRestore',
  scheduledReports: 'btn_scheduledReports',
  scorecardEventsModalCancel: 'btn_scorecardEventsModalCancel',
  scorecardEventsModalSave: 'btn_scorecardEventsModalSave',
  scorecardEventsPaneExclude: 'btn_scorecardEventsPaneExclude',
  scorecardEventsPaneInclude: 'btn_scorecardEventsPaneInclude',
  scorecardIncExcludeEvent: 'btn_scorecardIncExcludeEvent',
  scorecardViewDetails: 'btn_scorecardViewDetails',
  scoreConfigurationAdd: 'btn_scoreConfigurationAdd',
  selectGeofencesCancel: 'btn_selectGeofencesCancel',
  selectGeofencesSave: 'btn_selectGeofencesSave',
  sentinelActiveRuleset: 'btn_sentinelActiveRuleset',
  sentinelAdd: 'btn_sentinelAdd',
  sentinelAddEditSave: 'btn_sentinelAddEditSave',
  sentinelDeleteEvent: 'btn_sentinelDeleteEvent',
  sentinelTableDropdown: 'btn_sentinelTableDropdown',
  sentinelWorkDiary: 'btn_sentinelWorkDiary',
  shareVizPadAdd: 'btn_shareVizPadAdd',
  shareVizPadCancel: 'btn_shareVizPadCancel',
  shareVizPadSave: 'btn_shareVizPadSave',
  showFilterColumnsModal: 'btn_showFilterColumnsModal',
  showNearestMove: 'btn_showNearestMove',
  showNearestReset: 'btn_showNearestReset',
  showNearestSelect: 'btn_showNearestSelect',
  smartjobsCodesAdd: 'btn_smartjobsCodesAdd',
  smartjobsCodesCancel: 'btn_smartjobsCodesCancel',
  smartjobsCodesSave: 'btn_smartjobsCodesSave',
  smartjobsCodesTableAdd: 'btn_smartjobsCodesTableAdd',
  snapshotEllipsis: 'btn_snapshotEllipsis',
  speedAssistAddTemplate: 'btn_speedAssistAddTemplate',
  supportSummaryExcelExport: 'btn_supportSummaryExcelExport',
  supportSummaryColumns: 'btn_supportSummaryColumns',
  supportSummaryColumnsSave: 'btn_supportSummaryColumnsSave',
  supportSummaryColumnsCancel: 'btn_supportSummaryColumnsCancel',
  tableEllipsis: 'btn_tableEllipsis',
  tachoExpand: 'btn_tachoExpand',
  tcaEnrolmentsNew: 'btn_tcaEnrolmentsNew',
  tcaEnrolmentsExportReport: 'btn_tcaEnrolmentsExportReport',
  tcaEnrolmentsViewExportReport: 'btn_tcaEnrolmentsViewExportReport',
  tcaEnrolmentsViewNew: 'btn_tcaEnrolmentsViewNew',
  text: 'btn_text',
  toggle: 'btn_toggle',
  trackEventsShowFilter: 'btn_trackEventsShowFilter',
  trackEventsHDData: 'btn_trackEventsHDData',
  hdDataExportTo: 'btn_hdDataExportTo',
  hdDataOpenInNewTab: 'btn_hdDataOpenInNewTab',
  tripsSummaryDetailsLive: 'btn_tripsSummaryDetailsLive',
  udtAssignBulkUpdate: 'btn_udtAssignBulkUpdate',
  udtAssignCancel: 'btn_udtAssignCancel',
  udtGridComment: 'btn_udtGridComment',
  udtGridPreview: 'btn_udtGridPreview',
  udtGridPreviewRoute: 'btn_udtGridPreviewRoute',
  udtGridShow: 'btn_udtGridShow',
  udtGridSuggest: 'btn_udtGridSuggest',
  udtGridAccept: 'btn_udtGridAccept',
  udtGridUndo: 'btn_udtGridUndo',
  uploadRunsheetModalClose: 'btn_uploadRunsheetModalClose',
  uploadRunsheetModalTmpt: 'btn_uploadRunsheetModalTmpt',
  userCarrierLink: 'btn_userCarrierLink',
  userDefinedCancel: 'btn_userDefinedCancel',
  userDefinedSave: 'btn_userDefinedSave',
  userFormCancel: 'btn_userFormCancel',
  userFormRulesetAdd: 'btn_userFormRulesetAdd',
  userFormSave: 'btn_userFormSave',
  usersPrimary: 'btn_usersPrimary',
  vehicleCalibrateAccelerCalibrate: 'btn_vehicleCalibrateAccelerCalibrate',
  vehicleCalibrateCancel: 'btn_vehicleCalibrateCancel',
  vehicleCalibrateRefresh: 'btn_vehicleCalibrateRefresh',
  vehicleCalibrateUpdate: 'btn_vehicleCalibrateUpdate',
  vehicleCardPin: 'btn_vehicleCardPin',
  vehicleCombinationModalCancel: 'btn_vehicleCombinationModalCancel',
  vehicleCombinationModalSave: 'btn_vehicleCombinationModalSave',
  vehicleCombinationsAddNew: 'btn_vehicleCombinationsAddNew',
  vehicleConfigModalCancel: 'btn_vehicleConfigModalCancel',
  vehicleConfigModalSave: 'btn_vehicleConfigModalSave',
  vehicleFormAddAsset: 'btn_vehicleFormAddAsset',
  vehicleFormCancel: 'btn_vehicleFormCancel',
  vehicleFormOpenModelYear: 'btn_vehicleFormOpenModelYear',
  vehicleInfoView: 'btn_vehicleInfoView',
  vehicleInfoRouteTo: 'btn_vehicleInfoRouteTo',
  vehicleOperationalFormOpenModelYear: 'btn_vehicleOperationalFormOpenModelYear',
  vehicleFormSave: 'btn_vehicleFormSave',
  vehicleFormSelectOpenModelYear: 'btn_vehicleFormSelectOpenModelYear',
  vehicleLoginLimitCancel: 'btn_vehicleLoginLimitCancel',
  vehicleLoginLimitSave: 'btn_vehicleLoginLimitSave',
  vehicleMaintenanceAddDefinition: 'btn_vehicleMaintenanceAddDefinition',
  vehicleMaintenanceCancel: 'btn_vehicleMaintenanceCancel',
  vehicleMaintenanceSave: 'btn_vehicleMaintenanceSave',
  vehicleMakeModelYearCancel: 'btn_vehicleMakeModelYearCancel',
  vehicleMakeModelYearReset: 'btn_vehicleMakeModelYearReset',
  vehicleMakeModelYearSave: 'btn_vehicleMakeModelYearSave',
  vehicleMakeModelYearUseExisting: 'btn_vehicleMakeModelYearUseExisting',
  vehicleMakeModelYearUseExistingCancel: 'btn_vehicleMakeModelYearUseExistingCancel',
  vehicleMntScheduleCancel: 'btn_vehicleMntScheduleCancel',
  vehicleMntScheduleResch: 'btn_vehicleMntScheduleResch',
  vehicleMntSchedulesAdd: 'btn_vehicleMntSchedulesAdd',
  vehicleMntSchedulesComplete: 'btn_vehicleMntSchedulesComplete',
  vehicleMntSchedulesTypes: 'btn_vehicleMntSchedulesTypes',
  vehicleMntTypesAddNew: 'btn_vehicleMntTypesAddNew',
  vehicleMntTypesFormCancel: 'btn_vehicleMntTypesFormCancel',
  vehicleMntTypesFormSave: 'btn_vehicleMntTypesFormSave',
  vehiclesPrimary: 'btn_vehiclesPrimary',
  vehicleTypesAddNew: 'btn_vehicleTypesAddNew',
  vehicleTypesFormCancel: 'btn_vehicleTypesFormCancel',
  vehicleTypesFormSave: 'btn_vehicleTypesFormSave',
  vehicleViewCalibrate: 'btn_vehicleViewCalibrate',
  vehicleViewGPIO: 'btn_vehicleViewGPIO',
  velocityGraphPanLeft: 'btn_velocityGraphPanLeft',
  velocityGraphPanRight: 'btn_velocityGraphPanRight',
  velocityGraphZoomIn: 'btn_velocityGraphZoomIn',
  velocityGraphZoomOut: 'btn_velocityGraphZoomOut',
  velocityGraphZoomReset: 'btn_velocityGraphZoomReset',
  viewPageDelete: 'btn_viewPageDelete',
  viewPageEdit: 'btn_viewPageEdit',
  viewsConfigModal: 'btn_viewsConfigModal',
  viewTypeSwitchGrid: 'btn_viewTypeSwitchGrid',
  viewTypeSwitchList: 'btn_viewTypeSwitchList',
  wifiAddNew: 'btn_wifiAddNew',
  wifiFormCancel: 'btn_wifiFormCancel',
  wifiFormSave: 'btn_wifiFormSave',
  wifiViewShowPassword: 'btn_wifiViewShowPassword',
  gpioFormSave: 'btn_gpioFormSave',
  gpioFormCancel: 'btn_gpioFormCancel',
  assetsFormSave: 'btn_assetsFormSave',
  assetsFormCancel: 'btn_assetsFormCancel',
  speedAssistFormSave: 'btn_speedAssistFormSave',
  speedAssistFormCancel: 'btn_speedAssistFormCancel',
  devicePushConfigUpdate: 'btn_devicePushConfigUpdate',
  createInspection: 'btn_createInspection',
  cancelCreateInspection: 'btn_cancelCreateInspection',
  subcompanyAddNew: 'btn_subcompanyAddNew',
  subcompanyFormCancel: 'btn_subcompanyFormCancel',
  subcompanyFormSave: 'btn_subcompanyFormSave',
  viewPhotosBtn: 'btn_viewPhotos',
  exportToPDFBtn: 'btn_exportToPDF',
  confirmTransferCompanyBtn: 'btn_transferCompany',
  configureFormSave: 'btn_configureFormSave',
  configureFormCancel: 'btn_configureFormCancel',
  configureFormPush: 'btn_configureFormPush'
};
