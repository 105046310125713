import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const _DailySnapshotsLink = ({ entityType, entityId, entityName, linkTitle }) => {
  const location = useLocation();
  return entityType && entityId ? (
    <Link
      to={{
        pathname: `/daily-snapshots/${entityType.toLowerCase()}/${entityId}`,
        state: { canNewSnapshotsApi: location.pathname.includes('cameras_new') }
      }}
    >
      {linkTitle || entityName}
    </Link>
  ) : (
    <span>{entityName}</span>
  );
};
export const DailySnapshotsLink = React.memo(_DailySnapshotsLink);
