import { useMemo } from 'react';

import { useUser } from 'features/user/userSlice';

import { getRoundValue } from 'utils/methods';

import { getAwsLocale } from './locales';
import { LocalizationUtil, UNITS, FUEL_EFFICIENCY_UNITS, WEIGHT_UNITS } from './localization';
import { useLocalizationConfig, DefaultLocale } from './hooks';

const fuelType = {
  litre: 'litre',
  gallon: 'gallon',
  gallonUS: 'gallon(us)'
};

export function useLocalization(shouldUseCompanyLocale = false, selectedCompany = null) {
  const userInfo = useUser();
  const localizationConfig = useLocalizationConfig(shouldUseCompanyLocale, selectedCompany);

  const localization = useMemo(() => {
    const region = localizationConfig.region;
    const locale = localizationConfig.locale === 'test' ? DefaultLocale : localizationConfig.locale;
    const awsLocale = getAwsLocale(locale);

    return {
      region,
      locale,
      awsLocale,
      formats: localizationConfig,
      convertDistance: (distance, precision = 1) => {
        const formats = localizationConfig;
        const unit = formats?.speed?.unit || UNITS.KM;

        if (typeof distance !== 'number') {
          distance = parseFloat(distance);
        }

        if (isNaN(distance)) {
          distance = 0;
        }

        if (unit === UNITS.KM) {
          return distance.toFixed(precision);
        }
        return LocalizationUtil.kmtomile(distance, precision).toFixed(precision);
      },
      convertExcessiveIdle: (excessiveIdle, distance, precision = 1) => {
        const formats = localizationConfig;
        const unit = formats?.speed?.unit || UNITS.KM;

        if (typeof excessiveIdle !== 'number') {
          excessiveIdle = parseFloat(excessiveIdle);
        }

        if (isNaN(excessiveIdle)) {
          excessiveIdle = 0;
        }

        if (unit === UNITS.KM) {
          return excessiveIdle.toFixed(precision);
        }

        const originalPenaltyPoints = ((100 - excessiveIdle) * distance) / 100;

        return parseFloat(
          100 - originalPenaltyPoints / (localization.convertDistance(distance) / 100)
        ).toFixed(precision);
      },
      convertSpeed: (speed, precision = 1) => {
        return localization.convertDistance(speed, precision); // alias
      },
      convertSpeedWithUnit: (speed, targetUnit, sourceUnit, precision = 1) => {
        if (sourceUnit === targetUnit) {
          return speed.toFixed(precision);
        }
        const locale_unit = localizationConfig?.speed?.unit || UNITS.KM;
        sourceUnit = sourceUnit || locale_unit;
        if (sourceUnit === UNITS.KM && targetUnit === UNITS.MILE) {
          return LocalizationUtil.kmtomile(speed, precision).toFixed(precision);
        } else if (sourceUnit === UNITS.MILE && targetUnit === UNITS.KM) {
          return LocalizationUtil.miletokm(speed).toFixed(precision);
        }
        return speed;
      },
      convertAcceleration: (acceleration, setInDatabaseUnit = false) => {
        // setInDatabaseUnit- when input is in ft/s² or g and API expects m²
        const formats = localizationConfig;
        const unit = formats?.acceleration?.unit || UNITS.M_SQUARE;

        if (unit === UNITS.FT_SQUARE) {
          if (setInDatabaseUnit) {
            return LocalizationUtil.fttom(acceleration);
          }
          return LocalizationUtil.mtoft(acceleration);
        }
        if (unit === UNITS.G_FORCE) {
          if (setInDatabaseUnit) {
            return LocalizationUtil.gtom(acceleration);
          }
          return LocalizationUtil.mtog(acceleration);
        }
        return acceleration.toFixed(1);
      },
      convertAccelerationFromUnit: (acceleration, sourceUnit = UNITS.MILE_PH_PS) => {
        //support specifying sourceUnit(unit used in API/DB)
        if (sourceUnit === UNITS.M_SQUARE) {
          return localization.convertAcceleration(acceleration, false);
        } else if (sourceUnit === UNITS.MILE_PH_PS) {
          acceleration = LocalizationUtil.MilePerHourPerSecToMeterPerSecSquare(acceleration);
          return localization.convertAcceleration(acceleration, false);
        }
        return acceleration;
      },
      convertArea: area => {
        const formats = localizationConfig;
        const unit = formats?.area?.unit || UNITS.KM_SQUARE;

        if (unit === UNITS.KM_SQUARE) {
          return LocalizationUtil.sqmtosqkm(area);
        }
        return LocalizationUtil.sqmtosqmile(area);
      },
      convertAltitude: (altitude, precision = 1) => {
        const formats = localizationConfig;
        const unit = formats?.altitude?.unit || UNITS.M;

        if (unit === UNITS.FT) {
          return LocalizationUtil.mtoft(altitude).toFixed(precision);
        }
        return altitude?.toFixed(precision);
      },
      convertTemperature: (temperature, setInDatabaseUnit = false) => {
        // setInDatabaseUnit - when input is in ˚F and API expects ˚C
        const formats = localizationConfig;
        const unit = formats?.temperature?.unit || UNITS.TEMPERATURE_CELSIUS;

        if (unit === UNITS.TEMPERATURE_FAHRENHEIT) {
          if (setInDatabaseUnit) {
            return LocalizationUtil.FtoC(temperature);
          }
          return LocalizationUtil.CtoF(temperature);
        }
        return LocalizationUtil.CtoC(temperature);
      },
      convertPressure: (pressure, setInDatabaseUnit = false) => {
        // setInDatabaseUnit - when input is in psi and API expects kPa
        const formats = localizationConfig;
        const unit = formats?.pressure?.oil || UNITS.PRESSURE_KPA;

        if (unit === UNITS.PRESSURE_PSI) {
          if (setInDatabaseUnit) {
            return LocalizationUtil.psiToKpa(pressure);
          }
          return LocalizationUtil.kpaToPsi(pressure);
        }
        return Number(pressure);
      },
      convertFuelEfficiency: (fuelAvrEfficencyValue, convertToDBUnit = false) => {
        fuelAvrEfficencyValue = Number(fuelAvrEfficencyValue);
        if (isNaN(fuelAvrEfficencyValue)) {
          return 0;
        }
        const getLocalizedUnit = () => {
          switch (region) {
            case 'us':
            case 'gb':
              return FUEL_EFFICIENCY_UNITS.MilePerGal;
            default:
              return FUEL_EFFICIENCY_UNITS.LiterPer100KM;
          }
        };
        let convertToUnit = convertToDBUnit
          ? FUEL_EFFICIENCY_UNITS.LiterPer100KM
          : getLocalizedUnit();
        let valueUnit = convertToDBUnit ? getLocalizedUnit() : FUEL_EFFICIENCY_UNITS.LiterPer100KM;
        if (valueUnit === FUEL_EFFICIENCY_UNITS.LiterPer100KM) {
          if (convertToUnit === FUEL_EFFICIENCY_UNITS.MilePerGal) {
            return LocalizationUtil.LiterPer100KMToMilePerGal(
              fuelAvrEfficencyValue,
              region === 'us'
            );
          } else if (convertToUnit === FUEL_EFFICIENCY_UNITS.KMPerLiter) {
            return LocalizationUtil.LiterPer100KMToKMPerLiter(fuelAvrEfficencyValue);
          }
        } else if (valueUnit === FUEL_EFFICIENCY_UNITS.MilePerGal) {
          return LocalizationUtil.MilePerGalToLiterPer100KM(fuelAvrEfficencyValue, region === 'us');
        } else if (valueUnit === FUEL_EFFICIENCY_UNITS.KMPerLiter) {
          return LocalizationUtil.KMPerLiterToLiterPer100KM(fuelAvrEfficencyValue);
        }
        return fuelAvrEfficencyValue;
      },
      convertCO2Emissions: (co2EmissionValue, convertToDBUnit = false) => {
        co2EmissionValue = Number(co2EmissionValue);
        if (isNaN(co2EmissionValue)) {
          return 0;
        }
        const getLocalizedUnit = () => {
          switch (region) {
            case 'us':
            case 'gb':
              return FUEL_EFFICIENCY_UNITS.KGPerGal;
            default:
              return FUEL_EFFICIENCY_UNITS.KGPerLiter;
          }
        };
        let convertToUnit = convertToDBUnit ? FUEL_EFFICIENCY_UNITS.KGPerLiter : getLocalizedUnit();
        let valueUnit = convertToDBUnit ? getLocalizedUnit() : FUEL_EFFICIENCY_UNITS.KGPerLiter;
        if (valueUnit === FUEL_EFFICIENCY_UNITS.KGPerLiter) {
          if (convertToUnit === FUEL_EFFICIENCY_UNITS.KGPerGal) {
            return LocalizationUtil.KGPerLiterToKGPerGal(co2EmissionValue, region === 'us');
          }
        } else if (valueUnit === FUEL_EFFICIENCY_UNITS.KGPerGal) {
          if (convertToUnit === FUEL_EFFICIENCY_UNITS.KGPerLiter) {
            return LocalizationUtil.KGPerGalToKGPerLiter(co2EmissionValue, region === 'us');
          }
        }
        return co2EmissionValue;
      },
      convertFuel: (fuel, precision = 1) => {
        const formats = localizationConfig;
        const unit = formats?.fuel?.unit || fuelType.litre;

        if (unit === fuelType.gallonUS) {
          return LocalizationUtil.litresToGallonsUS(fuel, precision);
        }
        if (unit === fuelType.gallon) {
          return LocalizationUtil.litresToGallons(fuel, precision);
        }
        return LocalizationUtil.litresToLitres(fuel, precision);
      },
      convertFuelEconomy: (fuel, distance, localizedValues = false) => {
        const formats = localizationConfig;
        const unitFuel = formats?.fuel?.unit || fuelType.litre;

        if (unitFuel === fuelType.gallonUS) {
          if (Number(fuel) === 0) {
            return 0;
          }
          if (!localizedValues) {
            return getRoundValue(
              LocalizationUtil.kmtomile(distance, 3) / LocalizationUtil.litresToGallonsUS(fuel),
              1
            );
          }
          return getRoundValue(distance / fuel, 1);
        }
        if (unitFuel === fuelType.gallon) {
          if (Number(fuel) === 0) {
            return 0;
          }
          if (!localizedValues) {
            return getRoundValue(
              LocalizationUtil.kmtomile(distance, 1) / LocalizationUtil.litresToGallons(fuel),
              1
            );
          }
          return getRoundValue(distance / fuel, 1);
        }
        if (Number(distance) === 0) {
          return 0;
        }
        return getRoundValue((LocalizationUtil.litresToLitres(fuel) / distance) * 100, 1);
      },
      formatDistance: (distance, precision = 1) => {
        return (
          parseFloat(localization.convertDistance(distance, precision)).toLocaleString(locale, {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision
          }) +
          ' ' +
          (distance <= 1
            ? localization.formats.speed.unit
            : localization.formats.speed.unit_pluralize)
        );
      },
      formatSpeed: (speed, precision = 1) => {
        return (
          localization.convertDistance(speed, precision) +
          ' ' +
          localization.formats.speed.unit_per_hour
        );
      },
      formatArea: area => {
        return localization.convertArea(area) + ' ' + localization.formats.area.unit;
      },
      formatAltitude: altitude => {
        return localization.convertAltitude(altitude) + ' ' + localization.formats.altitude.unit;
      },
      formatTemperature: temperature => {
        return (
          localization.convertTemperature(temperature) + ' ' + localization.formats.temperature.unit
        );
      },
      formatFuel: (fuel, precision = 1) => {
        return (
          localization.convertFuel(fuel).toLocaleString(locale, {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision
          }) +
          ' ' +
          localization.formats.fuel.usage
        );
      },
      formatRelativeTime: timeSpan => {
        if (timeSpan < 3600000) {
          const relativeMin = Math.floor(timeSpan / 60000);
          return `${relativeMin} ${relativeMin > 1 ? 'mins' : 'min'} ago`;
        }

        if (timeSpan < 86400000) {
          const relativeHours = Math.floor(timeSpan / 3600000);
          return `${relativeHours} ${relativeHours > 1 ? 'hours' : 'hour'} ago`;
        }

        const days = Math.floor(timeSpan / 86400000);
        return `${days} ${days > 1 ? 'days' : 'day'} ago`;
      },
      convertWeight: (weight, unit) => {
        const formats = localizationConfig;

        const weightConversions = {
          imperialTonToTon: 1.01605,
          imperialTonToUsTon: 1.12,
          tonToImperialTon: 0.984207,
          tonToUsTon: 1.10231,
          usTonToTon: 0.907185,
          usTonToImperialTon: 0.892857,
          kgToLbs: 2.20462
        };

        // Conversion of KG -> POUNDS and vice versa
        if (unit === WEIGHT_UNITS.POUND && formats.weight.kg.unit === WEIGHT_UNITS.KG) {
          return {
            weight: weight * formats.weight.kg.toKg,
            weightUom: 'kgs'
          };
        }

        if (unit === WEIGHT_UNITS.KG && formats.weight.kg.unit === WEIGHT_UNITS.POUND) {
          return {
            weight: weight * weightConversions.kgToLbs,
            weightUom: 'lbs'
          };
        }

        // Conversion of TON -> IMPERIAL TON and vice versa
        if (unit === WEIGHT_UNITS.IMPERIAL_TON && formats.weight.ton.unit === WEIGHT_UNITS.TON) {
          return {
            weight: weight * weightConversions.imperialTonToTon,
            weightUom: WEIGHT_UNITS.TON
          };
        }

        if (unit === WEIGHT_UNITS.TON && formats.weight.ton.unit === WEIGHT_UNITS.US_TON) {
          return {
            weight: weight * weightConversions.tonToImperialTon,
            weightUom: WEIGHT_UNITS.US_TON
          };
        }

        // Conversion of TON -> US TON and vice versa
        if (unit === WEIGHT_UNITS.US_TON && formats.weight.ton.unit === WEIGHT_UNITS.TON) {
          return {
            weight: weight * weightConversions.usTonToTon,
            weightUom: WEIGHT_UNITS.TON
          };
        }

        if (unit === WEIGHT_UNITS.TON && formats.weight.ton.unit === WEIGHT_UNITS.US_TON) {
          return {
            weight: weight * weightConversions.tonToUsTon,
            weightUom: WEIGHT_UNITS.US_TON
          };
        }

        // Conversion of IMPERIAL TON -> US TON and vice versa
        if (unit === WEIGHT_UNITS.IMPERIAL_TON && formats.weight.ton.unit === WEIGHT_UNITS.US_TON) {
          return {
            weight: weight * weightConversions.imperialTonToUsTon,
            weightUom: WEIGHT_UNITS.US_TON
          };
        }

        if (unit === WEIGHT_UNITS.US_TON && formats.weight.ton.unit === WEIGHT_UNITS.IMPERIAL_TON) {
          return {
            weight: weight * weightConversions.usTonToImperialTon,
            weightUom: WEIGHT_UNITS.IMPERIAL_TON
          };
        }

        return { weight, weightUom: unit };
      }
    };
  }, [userInfo, localizationConfig]);

  return localization;
}
