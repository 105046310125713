import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import { setPageTitle } from 'features/page/pageSlice';
import { DailySnapshotsHeader } from './DailySnapshotsHeader';
import { SnapshotsContainer } from '../SnapshotsContainer.js';
import { Loading } from '../../../Components/Loading.js';
import { useOldDailySnapshots, useDailySnapshots } from '../hooks/useDailySnapshots.js';

export const DailySnapshots = ({ showNonCameraVehicle = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const { entity, entityId, invalidEntity } = useMemo(() => {
    const res = location.pathname.match(/daily-snapshots\/(vehicle|device)\/(\d+)$/);
    const entity = res?.[1],
      entityId = res?.[2];
    return {
      entity,
      entityId,
      invalidEntity: !entity || !entityId
    };
  }, [location]);

  const { isDataFetching, filters, snapshots, snapshotsEntity } = useOldDailySnapshots({
    showNonCameraVehicle,
    entity,
    entityId
  });

  useEffect(() => {
    if (invalidEntity || !snapshotsEntity?.deviceName) {
      return;
    }
    dispatch(
      setPageTitle(
        `${t('Home.SnapshotHistory')} ${snapshotsEntity.deviceName} ${
          location.state?.canNewSnapshotsApi ? ' (New)' : ''
        }`
      )
    );
  }, [dispatch, invalidEntity, t, snapshotsEntity]);

  if (invalidEntity) {
    return <Redirect to="/home/cameras" />;
  }

  if (location.state?.canNewSnapshotsApi) {
    return (
      <DailySnapshotsList
        entity={entity}
        entityId={entityId}
        showNonCameraVehicle={showNonCameraVehicle}
      />
    );
  }

  return (
    <div style={{ height: '100%' }}>
      {isDataFetching ? (
        <Loading />
      ) : (
        <>
          <DailySnapshotsHeader
            t={t}
            devices={snapshotsEntity?.devices}
            deviceId={snapshotsEntity?.deviceId}
            vehicleId={snapshotsEntity?.vehicleId}
            nodeType={snapshotsEntity?.nodeType}
          />
          <SnapshotsContainer isDailyMode={true} filters={filters} snapshots={snapshots} />
        </>
      )}
    </div>
  );
};

export const DailySnapshotsList = ({ entity, entityId, showNonCameraVehicle }) => {
  const { t } = useTranslation();
  const { isDataFetching, filters, snapshots, snapshotsEntity } = useDailySnapshots({
    showNonCameraVehicle,
    entity,
    entityId
  });

  return (
    <div style={{ height: '100%' }}>
      {isDataFetching ? (
        <Loading />
      ) : (
        <>
          <DailySnapshotsHeader
            t={t}
            devices={snapshotsEntity?.devices}
            deviceId={snapshotsEntity?.deviceId}
            vehicleId={snapshotsEntity?.vehicleId}
            nodeType={snapshotsEntity?.nodeType}
          />
          <SnapshotsContainer isDailyMode={true} filters={filters} snapshots={snapshots} />
        </>
      )}
    </div>
  );
};
