import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { get, merge, has, pickBy, hasIn, set } from 'lodash';
import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { useCurrentCompanyId } from 'features/company/companySlice';
import {
  updateIQCameraConfig,
  useIQCameraConfig,
  useIQCameraUpdateStatus
} from 'features/company_config';
import {
  Form_Field_Type,
  DUTY_TYPE,
  TABS,
  getDifferentValues,
  toIQCameraConfigPayload,
  getDirtyCheckConfirmProps,
  toIQCameraFormData,
  EDIT_LEVEL,
  getSubmissionConfirmProps
} from './helper';

import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import {
  Button,
  Tabs,
  Collapse,
  Switch,
  Select,
  Space,
  Layout,
  InputNumber,
  Form,
  Radio,
  Tooltip,
  Spin
} from 'antd';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import styles from './IQCamera.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { BulkEditForm } from './BulkEdit/BulkEditForm';
import { BUTTON_IDS } from 'utils/globalConstants';
import { GlobalRoles, useCan } from 'features/permissions';
import { useUserInfo } from 'features/user/userSlice';
import { ExportIQConfigButton } from './ExportIQConfigButton';
import { parseErrorMessage } from 'utils/strings';

const { Header, Content, Footer } = Layout;

const getFormFieldValue = (field, localization) => {
  if (field.value && typeof field.value === 'function') {
    return field.value(localization);
  }
  return field.value;
};

const getFormValuesFromConfig = (config, localization, filterFun = configType => true) => {
  return Object.keys(config).reduce((a, dutyType) => {
    return {
      ...a,
      [dutyType]: Object.values(config[dutyType])
        .filter(filterFun)
        .reduce((a, panel) => {
          const fieldReducer = (a, field) => {
            let fieldObj = {};
            for (let index = field.name.length - 1; index >= 0; index--) {
              const innerName = field.name[index];
              fieldObj =
                index === field.name.length - 1
                  ? { [innerName]: getFormFieldValue(field, localization) }
                  : { [innerName]: fieldObj };
            }
            return { ...a, ...fieldObj };
          };
          const subPanelFields = Object.values(panel.subConfigs || {}).reduce(
            (a, sub_config_type) => merge(a, sub_config_type.fields.reduce(fieldReducer, {})),
            {}
          );
          const panelFields = merge(panel.fields.reduce(fieldReducer, {}), subPanelFields);
          return merge(a, panelFields);
        }, {})
    };
  }, {});
};

const getFormValueUnlocalizationer = configFormData => {
  const fieldsCanUnlocalized = {};
  Object.keys(configFormData || {}).forEach(dutyType => {
    Object.keys(configFormData[dutyType]).forEach(config_type => {
      configFormData[dutyType][config_type]?.fields?.forEach(f => {
        if (f.toPayloadValue) {
          set(fieldsCanUnlocalized, [dutyType, ...f.name], { toPayloadValue: f.toPayloadValue });
        }
      });
      Object.values(configFormData[dutyType][config_type].subConfigs || {}).forEach(sub_config => {
        sub_config.fields.forEach(f => {
          if (f.toPayloadValue) {
            set(fieldsCanUnlocalized, [dutyType, ...f.name], { toPayloadValue: f.toPayloadValue });
          }
        });
      });
    });
  });

  return (formValues, localization) => {
    return Object.keys(formValues).reduce((a, path) => {
      const payloadValue =
        hasIn(fieldsCanUnlocalized, path) && get(fieldsCanUnlocalized, path)?.toPayloadValue
          ? get(fieldsCanUnlocalized, path).toPayloadValue(localization, formValues[path])
          : formValues[path];
      return {
        ...a,
        [path]: payloadValue
      };
    }, {});
  };
};

const confirmWithSubmissionGetter = ({ t }) => (
  modalContentGetter,
  onConfirmWithSubmission,
  onConfirmWithoutSubmission,
  forLeaving = true
) => {
  const [title, content, okText, cancelText] = modalContentGetter({ t });
  const self = confirmationModal(
    title,
    content,
    okText,
    cancelText,
    onConfirmWithSubmission,
    'warning',
    null,
    null,
    null,
    {
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          {forLeaving && (
            <Button
              onClick={() => {
                onConfirmWithoutSubmission();
                self.destroy();
              }}
            >
              {t('RouteGuard.LeavePage')}
            </Button>
          )}
          <OkBtn />
        </>
      )
    }
  );
};

export const IQCamera = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();

  const { onFetchError, onUpdateError, onUpadteSuccess } = useMemo(() => {
    return {
      onFetchError: (error, silentFetch) => {
        if (!silentFetch) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('CompanyConfig.Notifications.IQCameraFetchError', {
                error: parseErrorMessage(error)
              })
            })
          );
        }
      },
      onUpdateError: error => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('CompanyConfig.Notifications.IQCameraUpdateError', {
              error: parseErrorMessage(error)
            })
          })
        );
      },
      onUpadteSuccess: _ => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('CompanyConfig.Notifications.IQCameraUpdateSuccess')
          })
        );
      }
    };
  }, [t, dispatch]);

  const currentCompanyId = useCurrentCompanyId();
  const { iqCameraConfig, isFetching, isSilentFetching } = useIQCameraConfig(
    currentCompanyId,
    onFetchError
  );

  const configFormData = useMemo(
    () => (iqCameraConfig ? toIQCameraFormData(iqCameraConfig) : null),
    [iqCameraConfig]
  );

  const updateStatus = useIQCameraUpdateStatus(currentCompanyId);
  const isFormProcessing = useMemo(
    () => (isFetching && !isSilentFetching) || !!updateStatus?.isUpdating,
    [isSilentFetching, isFetching, updateStatus]
  );

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.IQCamera.Title')));
    dispatch(setBackButton(false));
    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {}
      ])
    );
    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [t, dispatch]);

  const [dutyType, setDutyType] = useState(DUTY_TYPE.heavy);
  const [tab, setTab] = useState(TABS.BASIC);

  const getUnlocalizedFormValue = useMemo(() => getFormValueUnlocalizationer(configFormData), [
    configFormData
  ]);

  const [buklFormMeta, setBulkFormMeta] = useState({ isDirty: false, onSubmit: null });

  const onSubmit = useCallback(
    async (changedValues, allValues, resetForm = resetFormValues => {}) => {
      return dispatch(
        updateIQCameraConfig({
          companyId: currentCompanyId,
          configuration: toIQCameraConfigPayload(
            getUnlocalizedFormValue(changedValues, localization),
            allValues
          ),
          onError: onUpdateError,
          onSuccess: configPayload => {
            onUpadteSuccess();
            resetForm(getFormValuesFromConfig(toIQCameraFormData(configPayload), localization));
          }
        })
      );
    },
    [
      dispatch,
      currentCompanyId,
      onUpadteSuccess,
      onUpdateError,
      localization,
      getUnlocalizedFormValue
    ]
  );

  const onCurrentDutyTypeChange = useCallback(
    (duty, changedValues, formValues, resetForm = resetFormValues => {}, onDiscard = () => {}) => {
      if (!!Object.keys(changedValues || {}).length) {
        const confirm = confirmWithSubmissionGetter({ t });
        confirm(
          getDirtyCheckConfirmProps,
          async () => {
            const { updated } = await onSubmit(changedValues, formValues, resetForm);
            if (updated) {
              setDutyType(duty);
            }
          },
          () => {
            onDiscard();
            setDutyType(duty);
          }
        );
      } else {
        setDutyType(duty);
      }
    },
    [t, onSubmit]
  );

  const onCurrenTabChange = useCallback(
    (
      targetTab,
      formValues,
      tabChangedValuesGetter = tab => {},
      resetForm = resetFormValues => {}
    ) => {
      const confirm = confirmWithSubmissionGetter({ t });
      if (tab === TABS.BULK_EDIT) {
        if (buklFormMeta.isDirty) {
          confirm(
            getDirtyCheckConfirmProps,
            async () => {
              const { updated } = await buklFormMeta?.onSubmit(false);
              if (updated) {
                setTab(targetTab);
                setBulkFormMeta(prev => ({ ...prev, isDirty: false }));
              }
            },
            () => {
              setTab(targetTab);
              setBulkFormMeta(prev => ({ ...prev, isDirty: false }));
            }
          );
        } else {
          setTab(targetTab);
        }
        return;
      }
      const [currentTabChangedValues, onDiscard] = tabChangedValuesGetter(tab) || [{}, () => {}];
      if (!!Object.keys(currentTabChangedValues || {}).length) {
        confirm(
          getDirtyCheckConfirmProps,
          async () => {
            const { updated } = await onSubmit(currentTabChangedValues, formValues, resetForm);
            if (updated) {
              setTab(targetTab);
            }
          },
          () => {
            onDiscard();
            setTab(targetTab);
          }
        );
      } else {
        setTab(targetTab);
      }
    },
    [t, tab, buklFormMeta.isDirty, buklFormMeta.onSubmit, onSubmit]
  );

  return (
    <Spin spinning={isFormProcessing} className={styles.pageLoader} size="large">
      {configFormData && (
        <IQCameraForm
          t={t}
          localization={localization}
          config={configFormData}
          currentDutyType={dutyType}
          onCurrentDutyTypeChange={onCurrentDutyTypeChange}
          currentTab={tab}
          onCurrenTabChange={onCurrenTabChange}
          onSubmit={onSubmit}
          isProcessing={isFormProcessing}
        />
      )}
      {tab === TABS.BULK_EDIT && (
        <BulkEditForm formMeta={buklFormMeta} setFormMeta={setBulkFormMeta} />
      )}
    </Spin>
  );
};

const TabTitle = ({ title, tooltip }) => (
  <Space className={styles.tabTitle}>
    <span>{title}</span>
    {tooltip && (
      <Tooltip title={tooltip}>
        <i className={`tn-i-info ${styles.tabTitleTooltip}`} />
      </Tooltip>
    )}
  </Space>
);

const TabContentTitle = ({ t, tab }) => {
  return tab === TABS.ADVANCED ? null : (
    <div className={styles.tabContentHeader}>
      <span>{t('Tabs.events')}</span>
      {tab === TABS.BASIC && (
        <span>
          <span key={'Tolerance'}>{t('GeofencesFeature.Tolerance')}</span>
          <span key={'Media Controls'}>{t('CompanyConfig.IQCamera.MediaControls')}</span>
        </span>
      )}
      <span>{t('Common.TableColumns.Actions')}</span>
    </div>
  );
};

const IQCameraForm = ({
  t,
  localization,
  config,
  currentDutyType,
  onCurrentDutyTypeChange = () => {},
  currentTab,
  onCurrenTabChange,
  onSubmit = () => {},
  isProcessing = false
}) => {
  const history = useHistory();
  const user = useUserInfo();
  const can = useCan();
  const [form] = Form.useForm();

  const openTab = () => {
    if (currentTab === TABS.BULK_EDIT) history.push(PATHS.IQ_CAMERA_ASSOCIATION_AUDIT);
    else history.push(PATHS.IQ_CAMERA_CONFIG_AUDIT);
  };

  const editLevel = useMemo(() => {
    if (user.siteAdmin) {
      return EDIT_LEVEL.FULL;
    } else {
      return EDIT_LEVEL.PARTIAL;
    }
  }, [can, user?.siteAdmin]);

  const tabBarExtraContent = useMemo(() => {
    const canExport = can({ oneOfRoles: [GlobalRoles.SiteAdmin] });
    return {
      right: (
        <Space>
          {[TABS.BASIC, TABS.ADVANCED, TABS.BULK_EDIT].includes(currentTab) && (
            <Button id={BUTTON_IDS.iqCameraConfigAudit} size="medium" onClick={() => openTab()}>
              {t('CompanyConfig.IQCamera.Audit.View')}
            </Button>
          )}
          {canExport && (
            <ExportIQConfigButton t={t} localization={localization} companyConfig={config} />
          )}
        </Space>
      )
    };
  }, [can, t, localization, config, history, currentTab]);

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = useMemo(
    () => ({
      required: t('CompanyConfig.IQCamera.Validation.required'),
      number: {
        min: t('CompanyConfig.IQCamera.Validation.min', { min: '${min}' }),
        max: t('CompanyConfig.IQCamera.Validation.max', { max: '${max}' })
      },
      pattern: {
        mismatch: t('CompanyConfig.IQCamera.Validation.integerPattern')
      }
    }),
    [t]
  );
  /* eslint-enable no-template-curly-in-string */

  const {
    initialValues,
    getTabContent,
    applyFormFieldToAllDuties,
    getTabDutyTypeDiffValues
  } = useMemo(() => {
    const advancedTabFields = getFormValuesFromConfig(
      config,
      localization,
      configType => configType.isAdvanced
    );
    const isFieldInAdvancedTab = formFieldName => has(advancedTabFields, formFieldName);
    const applyFormFieldToAllDuties = (formFieldName, value) => {
      const dutyTypeOfField = formFieldName.split('.')?.[0];
      if (isFieldInAdvancedTab(formFieldName) && dutyTypeOfField) {
        return Object.keys(DUTY_TYPE).reduce((a, dutyType) => {
          return {
            ...a,
            [formFieldName.replace(dutyTypeOfField, dutyType)]: value
          };
        }, {});
      }
      return { formFieldName: value };
    };
    const initialValues = getFormValuesFromConfig(config, localization);
    return {
      initialValues,
      applyFormFieldToAllDuties,
      getTabContent: tabKey => {
        return Object.keys(config).reduce((a, dutyType) => {
          return {
            ...a,
            [dutyType]: Object.keys(config[dutyType])
              .filter(configType =>
                tabKey === TABS.ADVANCED
                  ? config[dutyType][configType].isAdvanced
                  : !config[dutyType][configType].isAdvanced
              )
              .reduce(
                (a, config_type) => ({
                  ...a,
                  [config_type]: config[dutyType][config_type]
                }),
                {}
              )
          };
        }, {});
      },
      getTabDutyTypeDiffValues: (tabKey, dutyType, dutyValues) => [
        pickBy(
          getDifferentValues(
            { [dutyType]: dutyValues },
            {
              [dutyType]: getFormValuesFromConfig(config, localization, configType =>
                tabKey === TABS.BASIC ? !configType.isAdvanced : configType.isAdvanced
              )[dutyType]
            }
          ),
          (value, key) => {
            const isAdvancedField = isFieldInAdvancedTab(key);
            return tabKey === TABS.ADVANCED ? isAdvancedField : !isAdvancedField;
          }
        ),
        merge({}, initialValues[dutyType])
      ]
    };
  }, [config, localization]);

  const [formStatus, setFormStatus] = useState({ isInvalid: false, isPristine: true });
  const updateFormStatus = useCallback(
    (reset = false, validateBeforeUpdating = false) => {
      if (reset) {
        setFormStatus({ isInvalid: false, isPristine: true });
      } else {
        const update = () => {
          const changedValues = getTabDutyTypeDiffValues(
            currentTab,
            currentDutyType,
            form.getFieldValue(currentDutyType)
          )[0];

          const isCurrentDutyInvalid = isDutyInvalid(
            getTabContent(currentTab),
            currentDutyType,
            form.getFieldsError
          );
          setFormStatus({
            isInvalid: isCurrentDutyInvalid,
            isPristine: !Object.keys(changedValues || {}).length
          });
        };
        if (validateBeforeUpdating) {
          form
            .validateFields()
            .then(_ => {
              update();
            })
            .catch(errorInfo => {
              update();
            });
        } else {
          update();
        }
      }
    },
    [currentTab, currentDutyType, form, getTabContent, getTabDutyTypeDiffValues]
  );

  const onSave = useCallback(
    (applyToAllDuties = false) => {
      const allValues = form.getFieldsValue(true);
      let changedValues = getTabDutyTypeDiffValues(
        currentTab,
        currentDutyType,
        form.getFieldValue(currentDutyType)
      )[0];
      if (applyToAllDuties) {
        changedValues = Object.keys(changedValues).reduce(
          (a, formFieldName) => ({
            ...a,
            ...applyFormFieldToAllDuties(formFieldName, changedValues[formFieldName])
          }),
          {}
        );
      }
      const confirm = confirmWithSubmissionGetter({ t });
      confirm(
        ({ t }) => getSubmissionConfirmProps({ t, tab: currentTab }),
        () => {
          onSubmit(changedValues, allValues, resetFormValues => {
            form.setFieldsValue(resetFormValues);
            updateFormStatus(true);
          });
        },
        null,
        false
      );
    },
    [
      form,
      onSubmit,
      currentTab,
      currentDutyType,
      applyFormFieldToAllDuties,
      getTabDutyTypeDiffValues,
      updateFormStatus,
      t
    ]
  );

  const onFieldsChange = ([field], allFields) => {
    if (!field) {
      return;
    }
    updateFormStatus();
  };

  const onFieldsManualChange = useCallback(
    fieldsManualUpdated => {
      if (!fieldsManualUpdated.length) {
        return;
      }
      updateFormStatus(false, true);
    },
    [updateFormStatus]
  );

  const disableSaveBtn = useMemo(
    () => isProcessing || formStatus.isPristine || formStatus.isInvalid,
    [formStatus, isProcessing]
  );
  const confirmOnLeave = useMemo(() => !formStatus.isPristine, [formStatus]);

  const onDutyTypeChange = useCallback(
    dutyType => {
      const [changedValues, initialDutyTypeValue] = getTabDutyTypeDiffValues(
        currentTab,
        currentDutyType,
        form.getFieldValue(currentDutyType)
      );
      onCurrentDutyTypeChange(
        dutyType,
        changedValues,
        form.getFieldsValue(true),
        resetFormValues => {
          form.setFieldsValue(resetFormValues);
          updateFormStatus(true);
        },
        () => {
          form.setFieldValue(currentDutyType, initialDutyTypeValue);
          updateFormStatus(true);
        }
      );
    },
    [
      onCurrentDutyTypeChange,
      form,
      updateFormStatus,
      getTabDutyTypeDiffValues,
      currentTab,
      currentDutyType
    ]
  );

  const onTabChange = useCallback(
    tab => {
      onCurrenTabChange(
        tab,
        form.getFieldsValue(true),
        tab => {
          const [changedValue, initialDutyTypeValue] = getTabDutyTypeDiffValues(
            tab,
            currentDutyType,
            form.getFieldValue(currentDutyType)
          );
          return [
            changedValue,
            () => {
              form.setFieldValue(currentDutyType, initialDutyTypeValue);
              updateFormStatus(true);
            }
          ];
        },
        resetFormValues => {
          form.setFieldsValue(resetFormValues);
          updateFormStatus(true);
        }
      );
    },
    [onCurrenTabChange, form, updateFormStatus, currentDutyType, getTabDutyTypeDiffValues]
  );

  const [cancelConfirmed, setCancelConfirmed] = useState(false);

  const applyRouteGuard = useMemo(() => confirmOnLeave && !cancelConfirmed, [
    cancelConfirmed,
    confirmOnLeave
  ]);

  const confirmModalProps = useMemo(() => {
    const [title, message, leaveBtnText, stayBtnText] = getDirtyCheckConfirmProps({ t });
    return {
      title,
      message,
      leaveBtnText,
      stayBtnText,
      getFooter: ({ OkBtn, CancelBtn, modalSelf, handleConfirmNavigationClick }) => (
        <>
          <CancelBtn />
          <Button
            onClick={() => {
              handleConfirmNavigationClick();
              modalSelf.destroy();
            }}
          >
            {t('RouteGuard.LeavePage')}
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              const { updated } = await onSubmit(
                getTabDutyTypeDiffValues(
                  currentTab,
                  currentDutyType,
                  form.getFieldValue(currentDutyType)
                )[0],
                form.getFieldsValue(true),
                resetFormValues => {
                  form.setFieldsValue(resetFormValues);
                  updateFormStatus(true);
                }
              );
              if (updated) {
                handleConfirmNavigationClick();
              }
              modalSelf.destroy();
            }}
          >
            {t('RouteGuard.SaveAndLeavePage')}
          </Button>
        </>
      )
    };
  }, [t, onSubmit, currentTab, currentDutyType, getTabDutyTypeDiffValues, updateFormStatus]);

  const onCancel = useCallback(() => {
    if (confirmOnLeave) {
      const modalSelf = confirmationModal(
        confirmModalProps.title,
        confirmModalProps.message,
        confirmModalProps.leaveBtnText,
        confirmModalProps.stayBtnText,
        () => {
          setCancelConfirmed(true);
          history.goBack();
        },
        'warning',
        null,
        null,
        null,
        confirmModalProps.getFooter
          ? {
              footer: (_, { OkBtn, CancelBtn }) =>
                confirmModalProps?.getFooter({
                  OkBtn,
                  CancelBtn,
                  modalSelf,
                  handleConfirmNavigationClick: () => {
                    setCancelConfirmed(true);
                    history.goBack();
                  }
                })
            }
          : null
      );
    } else {
      history.goBack();
    }
  }, [confirmModalProps, history, confirmOnLeave, setCancelConfirmed]);

  return (
    <>
      <>
        <EditRouteGuard
          when={applyRouteGuard}
          navigate={history.push}
          confirmModalProps={confirmModalProps}
        />
        <Form
          initialValues={initialValues}
          form={form}
          onFieldsChange={onFieldsChange}
          className={styles.iqCameraForm}
          colon={false}
          requiredMark={false}
          validateMessages={validateMessages}
          size="large"
          layout="vertical"
        >
          {
            <Tabs
              activeKey={currentTab}
              onChange={onTabChange}
              className={styles.tabs}
              tabBarExtraContent={tabBarExtraContent}
              items={Object.keys(TABS).map(tabKey => ({
                key: tabKey,
                label: (
                  <TabTitle
                    title={t(`CompanyConfig.IQCamera.${tabKey}.Title`)}
                    tooltip={
                      tabKey === TABS.BULK_EDIT
                        ? null
                        : t(`CompanyConfig.IQCamera.${tabKey}.Tooltip`)
                    }
                  />
                ),
                className: styles.tab,
                children:
                  tabKey === TABS.BULK_EDIT ? null : (
                    <FormTabContent
                      t={t}
                      editLevel={editLevel}
                      localization={localization}
                      disableSave={disableSaveBtn}
                      onCancel={onCancel}
                      onSave={() => onSave()}
                      onSaveAll={() => onSave(true)}
                      content={getTabContent(tabKey)}
                      currentTab={currentTab}
                      currentDutyType={currentDutyType}
                      onDutyTypeChange={onDutyTypeChange}
                      onFieldsManualChange={onFieldsManualChange}
                    />
                  )
              }))}
            />
          }
        </Form>
      </>
    </>
  );
};

const FormTabContent = ({
  t,
  localization,
  currentTab,
  currentDutyType,
  onDutyTypeChange,
  content,
  editLevel = EDIT_LEVEL.NONE,
  disableSave = false,
  onCancel = () => {},
  onSave = () => {},
  onSaveAll = () => {},
  onFieldsManualChange = () => {}
}) => {
  const can = useCan();
  const localizationTranslator = useCallback(
    (item, textProp = 'label') => {
      if (item?.getText) {
        return item.getText(t, localization, can);
      }
      return item?.[textProp] || item;
    },
    [t, localization]
  );

  const dutyFormPanels = useMemo(
    () =>
      Object.values(content[currentDutyType] || {}).map(panel => {
        return {
          ...panel,
          fields: panel?.fields.map(panelField => ({
            ...panelField,
            ...getPanelRules(panelField, localization),
            name: [currentDutyType, ...panelField.name]
          })),
          subConfigs: Object.keys(panel.subConfigs || {}).reduce((a, sub_config_type) => {
            return {
              ...a,
              [sub_config_type]: {
                ...panel.subConfigs[sub_config_type],
                fields: panel.subConfigs[sub_config_type].fields.map(subPanelField => ({
                  ...subPanelField,
                  ...getPanelRules(subPanelField, localization),
                  name: [currentDutyType, ...subPanelField.name]
                }))
              }
            };
          }, {})
        };
      }),
    [content, currentDutyType, localization]
  );

  return (
    <Layout className={styles.tab}>
      <Header>
        <Radio.Group
          size="middle"
          optionType="button"
          buttonStyle="solid"
          value={currentDutyType}
          onChange={e => onDutyTypeChange(e.target.value)}
        >
          {Object.keys(DUTY_TYPE).map(type => (
            <Radio.Button key={type} value={type}>
              {t(`CompanyConfig.IQCamera.${type}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Header>
      <Layout>
        <Content>
          <TabContentTitle tab={currentTab} t={t} />
          <PanelsAccordion
            editLevel={editLevel}
            currentTab={currentTab}
            localizationTranslator={localizationTranslator}
            panels={dutyFormPanels}
            currentTabContent={content}
            currentDutyType={currentDutyType}
            onFieldsManualChange={onFieldsManualChange}
          />
        </Content>
      </Layout>
      <Footer className={styles.tabFooter}>
        <Space size={[16, 0]}>
          {!(editLevel === EDIT_LEVEL.NONE) && (
            <Button
              size="large"
              id={BUTTON_IDS.iqCameraSave}
              type="primary"
              disabled={disableSave}
              onClick={onSave}
            >
              {t('Common.Save')}
            </Button>
          )}
          {!(editLevel === EDIT_LEVEL.NONE) && currentTab === TABS.ADVANCED && (
            <Button
              size="large"
              id={BUTTON_IDS.iqCameraSaveAll}
              disabled={disableSave}
              onClick={onSaveAll}
            >
              {t('CompanyConfig.IQCamera.SaveAll')}
            </Button>
          )}
          <Button size="large" id={BUTTON_IDS.iqCameraCancel} onClick={onCancel}>
            {editLevel === EDIT_LEVEL.NONE ? t('Vehicles.Form.DriverMng.Back') : t('Common.Cancel')}
          </Button>
        </Space>
      </Footer>
    </Layout>
  );
};

const PanelsAccordion = ({
  localizationTranslator,
  editLevel = EDIT_LEVEL.NONE,
  currentTab,
  panels,
  currentTabContent,
  currentDutyType,
  onFieldsManualChange = () => {},
  parentPanel = null,
  closeParentPanel = () => {}
}) => {
  const form = Form.useFormInstance();
  const [activeKey, setActiveKey] = useState();
  const { isFieldDisabled, isSwitchDisabled } = useMemo(
    () => ({
      isFieldDisabled:
        editLevel === EDIT_LEVEL.NONE ||
        (currentTab !== TABS.ADVANCED && editLevel !== EDIT_LEVEL.FULL),
      isSwitchDisabled: editLevel === EDIT_LEVEL.NONE
    }),
    [currentTab, editLevel]
  );

  const closePanel = useCallback(
    panel => setActiveKey(prev => (prev === panel?.id ? null : prev)),
    [setActiveKey]
  );

  const closeParentPanelAsNeeded = useCallback(
    ({ getFieldValue, getFieldsError }) => {
      if (parentPanel) {
        if (
          !isPanelSwithChecked(parentPanel, getFieldValue) &&
          !isPanelInvalid(parentPanel, getFieldsError)
        ) {
          closeParentPanel();
        }
      }
    },
    [parentPanel, closeParentPanel]
  );

  const getPanelExtras = useCallback(
    panel => {
      const extraFields = panel?.fields?.filter(field => !!field.isExtra);
      const swithField = getPanelSwithField(panel);
      const sortByOrder = (a, b) => (a.order || 0) - (b.order || 0);
      const Slot = () => <div></div>;
      return (
        <Space className={styles.extraFields}>
          {extraFields.sort(sortByOrder).map((extraField, index) =>
            extraField.selection?.length ? (
              <React.Fragment key={`${panel.id}-extras`}>
                {extraField.withSlotBefore && <Slot key={`${panel.id}-slot-bef-${index}`} />}
                <Form.Item key={`${panel.id}-extra-${index}`} noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      rules={extraField.rules}
                      name={extraField.name}
                      key={`${extraField.config_type}-${extraField.name.join('-')}`}
                    >
                      <Select
                        onClick={event => event.stopPropagation()}
                        disabled={
                          isFieldDisabled
                            ? true
                            : extraField?.isDisabled &&
                              extraField?.isDisabled(
                                getFieldValue(extraField.name),
                                swithField &&
                                  (swithField.getChecked
                                    ? swithField.getChecked(getFieldValue)
                                    : getFieldValue(swithField.name))
                              )
                        }
                      >
                        {extraField.selection
                          .filter(s => !s.hidden)
                          .map(opt => (
                            <Select.Option
                              key={`${extraField.config_type}-${opt.value}`}
                              value={opt.value}
                            >
                              {localizationTranslator(opt)}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
                {extraField.withSlotAfter && <Slot key={`${panel.id}-slot-aft-${index}`} />}
              </React.Fragment>
            ) : null
          )}
          {swithField ? (
            <PanelSwither
              key={`${panel.id}-toggle`}
              swithField={swithField}
              panel={panel}
              closeParentPanelAsNeeded={closeParentPanelAsNeeded}
              closePanel={closePanel}
              onFieldsManualChange={onFieldsManualChange}
              disabled={isSwitchDisabled}
            />
          ) : null}
        </Space>
      );
    },
    [
      closePanel,
      onFieldsManualChange,
      localizationTranslator,
      closeParentPanelAsNeeded,
      isFieldDisabled,
      isSwitchDisabled
    ]
  );

  return (
    <Collapse
      accordion
      expandIconPosition="end"
      ghost
      activeKey={activeKey}
      onChange={key => {
        const activeKey = typeof key === 'string' ? key : key[0];
        //if currentDuty has panel invalid, prevent change active key
        if (!isDutyInvalid(currentTabContent, currentDutyType, form.getFieldsError)) {
          setActiveKey(activeKey);
        }
      }}
      items={panels.map(panel => ({
        key: panel.id,
        label: <div className="panel-title">{localizationTranslator(panel.header)}</div>,
        extra: getPanelExtras(panel),
        className: styles.panel,
        collapsible: getPanelCollapsible({ panel, ...form }),
        children: (
          <>
            <Space
              wrap
              className={styles.panelContent}
              size={[16, 0]}
              align="start"
              key={`${panel.id}-content`}
            >
              {panel?.fields?.length
                ? getPanelContentFields({
                    panel,
                    includeSubPanels: false
                  }).map((panelField, index) => (
                    <PanelField
                      key={`${panelField.config_type}-${index}`}
                      fieldKey={`${panelField.config_type}-${panelField.name.join('-')}`}
                      panelField={panelField}
                      localizationTranslator={localizationTranslator}
                      disabled={isFieldDisabled}
                    />
                  ))
                : null}
            </Space>
            {Object.values(panel?.subConfigs || [])?.length ? (
              <PanelsAccordion
                parentPanel={panel}
                closeParentPanel={() => closePanel(panel)}
                key={`${panel.id}-sub-accordion`}
                editLevel={editLevel}
                currentTab={currentTab}
                localizationTranslator={localizationTranslator}
                panels={Object.values(panel.subConfigs)}
                currentTabContent={currentTabContent}
                currentDutyType={currentDutyType}
                onFieldsManualChange={onFieldsManualChange}
              />
            ) : null}
          </>
        )
      }))}
    />
  );
};

const PanelSwither = ({
  swithField,
  disabled = false,
  panel,
  closePanel,
  onFieldsManualChange = () => {},
  closeParentPanelAsNeeded = () => {}
}) => {
  if (swithField.onSwitch) {
    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue, setFieldsValue, getFieldsError, getFieldValue, validateFields }) => (
          <Switch
            size="small"
            checked={swithField.getChecked(getFieldValue)}
            disabled={disabled}
            onChange={checked => {
              swithField.onSwitch(checked, setFieldsValue, getFieldsValue(true));
              onFieldsManualChange(swithField.sensorFields);
            }}
            onClick={async (checked, event) => {
              event.stopPropagation();
              const isInvalidPanel = await isPanelInvalidAsync(panel, validateFields);
              if (!checked && !isInvalidPanel) {
                closePanel(panel);
                closeParentPanelAsNeeded({ getFieldValue, getFieldsError });
              }
            }}
          />
        )}
      </Form.Item>
    );
  }
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue, getFieldsError, getFieldValue }) => (
        <Form.Item
          valuePropName="checked"
          name={swithField.name}
          style={{ display: 'inline-flex' }}
          shouldUpdate
        >
          <Switch
            size="small"
            disabled={disabled}
            defaultChecked={swithField.value}
            onClick={(checked, event) => {
              event.stopPropagation();
              if (!checked && !isPanelInvalid(panel, getFieldsError)) {
                closePanel(panel);
                closeParentPanelAsNeeded({ getFieldValue, getFieldsError });
              }
              //disable/enable media control and change its value to disable/on(default)
              const fieldsNeedManualUpdated = getPanelContentFields({
                panel,
                filterFun: field => true
              }).filter(field => !!field?.onEventSwitch);
              fieldsNeedManualUpdated.forEach(field => {
                field.onEventSwitch(checked, setFieldsValue, getFieldsValue(true));
              });
              onFieldsManualChange(fieldsNeedManualUpdated);
            }}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
};

const PanelField = ({ panelField, fieldKey, localizationTranslator, disabled = false }) => {
  switch (panelField.fieldType) {
    case Form_Field_Type.InputNumber:
      return (
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            get(prevValues, panelField.name) !== get(currentValues, panelField.name)
          }
        >
          {({ getFieldError }) => {
            const error = getFieldError(panelField.name)?.[0];
            return (
              <>
                <Form.Item
                  label={localizationTranslator(panelField.label)}
                  key={fieldKey}
                  name={panelField.name}
                  rules={panelField.rules}
                >
                  <InputNumber disabled={disabled} />
                </Form.Item>
                {!error && <div className="hint">{localizationTranslator(panelField.help)}</div>}
              </>
            );
          }}
        </Form.Item>
      );
    case Form_Field_Type.Select:
      return (
        <Form.Item
          label={localizationTranslator(panelField.label)}
          key={fieldKey}
          name={panelField.name}
          rules={panelField.rules}
          help={localizationTranslator(panelField.help)}
        >
          <Select disabled={disabled}>
            {panelField.selection?.map(option => (
              <Select.Option key={`${fieldKey}-${option.id}`} value={option.value || option.id}>
                {localizationTranslator(option)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );

    case Form_Field_Type.Switch:
      return (
        <Form.Item
          label={localizationTranslator(panelField.label)}
          key={fieldKey}
          name={panelField.name}
          valuePropName="checked"
        >
          <Switch size="small" defaultChecked={panelField.value} disabled={disabled} />
        </Form.Item>
      );
    default:
      return null;
  }
};

const getPanelCollapsible = ({ panel, getFieldsError, getFieldValue }) => {
  if (!getPanelContentFields({ panel }).length) {
    //no content in panel - not applicable to expand
    return 'disabled';
  } else if (isPanelInvalid(panel, getFieldsError)) {
    // has invalid field in panel - prevent closing
    return 'disabled';
  }
  //config type (can be disabled) disabled - prevent expanding
  if (getPanelSwithField(panel) && !isPanelSwithChecked(panel, getFieldValue)) {
    return 'disabled';
  }
  return;
};

const getPanelRules = (p, localization) =>
  p.rules?.length
    ? {
        rules: p.rules.map(panelRule => {
          if (typeof panelRule === 'function') {
            return panelRule(localization);
          } else {
            return panelRule;
          }
        })
      }
    : {};

const getPanelContentFields = ({
  panel,
  filterFun = field => !field.isExtra && !field.isSwitch,
  includeSubPanels = true
}) => {
  const panelMainFields = panel?.fields?.filter(filterFun) || [];
  const panelSubFields = includeSubPanels
    ? Object.values(panel?.subConfigs || {}).reduce(
        (a, subPanel) => [
          ...a,
          ...getPanelContentFields({ panel: subPanel, filterFun, includeSubPanels })
        ],
        []
      )
    : [];
  return [...panelMainFields, ...panelSubFields];
};

const isPanelSwithChecked = (panel, getFieldValue) => {
  const swithField = getPanelSwithField(panel);
  return (
    swithField &&
    (swithField.getChecked ? swithField.getChecked(getFieldValue) : getFieldValue(swithField.name))
  );
};

const getPanelSwithField = panel => panel?.fields?.find(field => !!field.isSwitch);

const isPanelInvalid = (panel, getFieldsError, fieldNameConverter = name => name) => {
  const formFieldErrors = getFieldsError(
    getPanelContentFields({ panel, filterFun: field => true }).map(f => fieldNameConverter(f.name))
  );
  return formFieldErrors.some(formFieldError => !!formFieldError?.errors?.length);
};

const isPanelInvalidAsync = async (panel, validateFields) => {
  const valid = await validateFields(
    getPanelContentFields({ panel, filterFun: field => true }).map(f => f.name)
  )
    .then(values => true)
    .catch(errorInfo => false);
  return !valid;
};

const isDutyContentInvalid = (dutyPanels, getFieldsError, fieldNameConverter = name => name) => {
  return dutyPanels.some(panel => isPanelInvalid(panel, getFieldsError, fieldNameConverter));
};

const isDutyInvalid = (tabContent, dutyType, getFieldsError) => {
  return isDutyContentInvalid(Object.values(tabContent[dutyType]), getFieldsError, name => [
    dutyType,
    ...name
  ]);
};
