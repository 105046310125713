import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { Col, Row, Button } from 'antd';
import { Tooltip, Table, ImageUpload, Accordion } from 'components/ant';
import { LoadingTable } from 'components/grid/LoadingTable';
import {
  useCompanies,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import {
  useVehicles,
  useVehicle,
  useVehiclesFromFleetsBoth,
  useIsFetchingFinished as useIsFetchingFleetsFinished,
  useDevices
} from 'features/fleets/fleetsSlice';
import { useDeletedVehicles } from 'features/vehicles/vehiclesDeletedSlice';
import { fetchVehiclesStats, useVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { useDevicesStats } from 'features/devices/devicesStatsSlice';
import { useVehicleMeters } from 'features/vehicles/vehiclesMetersSlice';
import { useVehicleConfig } from 'features/vehicles/vehiclesConfigsSlice';
import { downloadDocument } from 'features/easydocs/documentsSlice';
import { useVehicleMaintenanceFields } from 'features/company_config/hooks';
import InfoRow from 'components/form/info-row/InfoRow';
import InfoTable from 'components/form/info-table/InfoTable';
import { setBackButton, setPageTitle, addBackButtonLink } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { MapModal } from 'components/modals/map-modal/MapModal';
import { LoadingCentered } from 'components/loading/Loading';
import {
  useVehicleSchedules,
  handleScheduleDeleteAction
} from 'features/vehicleMaintenance/schedulesSlice';
import { useDrivers, useUsers } from 'features/users/usersSlice';
import { useUser } from 'features/user/userSlice';
import { ClockCircleOutlined } from '@ant-design/icons';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { deleteVehicleApi, restoreVehicleApi } from './APICalls';
import {
  MeterType,
  getVehicleOdometer,
  getVehicleEngineHours,
  getCalculatedBy,
  formatMeterValue,
  formatOdometer,
  getMeterValue
} from 'features/meters';
import {
  entities,
  useCan,
  Can,
  useCanOneOfRoles,
  GlobalRoles,
  FeatureFlag
} from 'features/permissions';

//helpers
import { format } from 'utils/dates';
import { renderFleetsAndCompany, doesVehicleHaveHermesDevice } from './helpers';
import {
  maintenanceTypeCellRenderer,
  scheduledByCellRenderer,
  serviceDueCellRenderer,
  nextDueCellRenderer as _nextDueCellRenderer,
  lastServiceCellRenderer,
  ellipsisButtonCellRenderer,
  statusCellRenderer,
  serviceNameCellRenderer,
  notesCellRenderer
} from '../../VehicleMaintenance/utils/CellRenderers';

//constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import {
  STATUS,
  Paths as VEHICLE_MAINTENANCE_PATHS
} from '../../VehicleMaintenance/utils/constants';
import { vehicleMetersColumns, METER_TYPES, VehicleConfig, PATHS } from './constants';

//styles
import styles from './Vehicles.module.scss';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import { useForceLogoff } from 'features/users/forceLogoff';
import {
  canVehicleHasDefaultDriver,
  DEFAULT_DRIVER_FORM_FIELD_NAME,
  isDefaultDriverOfVehicle
} from './DefaultDriver/constants';
import defaultDriverStyles from './DefaultDriver/DefaultDriver.module.scss';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { BUTTON_IDS } from 'utils/globalConstants';
import VehicleAgreements from './VehicleAgreements';
import services from 'features/permissions/services';

export const VehicleView = () => {
  const { t } = useTranslation();
  const can = useCan();
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const history = useHistory();
  const vehiclesStats = useVehiclesStats();
  const devicesStats = useDevicesStats();
  const users = useUsers();
  const vehicles = useVehicles();
  const { pathname } = useLocation();
  const deletedVehicles = useDeletedVehicles();
  const allVehicles = useMemo(() => {
    return [...(vehicles || []), ...(deletedVehicles || [])];
  }, [vehicles, deletedVehicles]);
  const hasFetchedFleets = useIsFetchingFleetsFinished();
  const vehiclesBoth = useVehiclesFromFleetsBoth();
  const vehicleMeters = useVehicleMeters(id);
  const vehicleConfig = useVehicleConfig(id, VehicleConfig.OdometerForMnt.value);
  const [vehicleNotFound, setVehicleNotFound] = useState(false);

  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;
  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);
  const supportInOutServiceDate = can({ featureFlag: FeatureFlag.inOutServiceDate.flag });
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.VEHICLE_DEFAULT);
  }, [history]);

  useRedirectToMainFeaturePageOnCompanyChange('/settings/vehicles');

  const handleNotFound = useCallback(() => {
    setVehicleNotFound(true);
  }, []);

  const vehicleInfo = useVehicle(id, undefined, handleFetchError, handleNotFound);

  const dispatch = useDispatch();
  const localization = useLocalization();
  const activeScheduleStatus = useMemo(() => {
    return [STATUS.pending, STATUS.dueNow, STATUS.overdue];
  }, []);
  const { vehicleSchedules, isFetching: isFetchingSchedule } = useVehicleSchedules(
    id,
    activeScheduleStatus
  );
  const [mapModalDetails, setMapModalDetails] = useState(undefined);

  const vehicleStats = useMemo(() => {
    return vehiclesStats?.find(vs => parseInt(vs.vehicleId, 10) === parseInt(id, 10));
  }, [vehiclesStats, id]);

  const vehicleData = useMemo(() => {
    const vehicle = allVehicles.find(v => parseInt(v.id, 10) === parseInt(id, 10));
    const schedules = vehicleSchedules.map(schedule => ({
      ...schedule,
      vehicle: {
        ...schedule.vehicle,
        odometer: vehicleConfig?.isUsingRUCOdometerForMnt
          ? vehicleConfig.rucOdometer
          : getVehicleOdometer(
              vehicleMeters?.find(meter => meter.type === MeterType.Odometer),
              vehicle?.engineSummarySource,
              vehicleStats?.canOdometer,
              vehicleStats?.canDiffOdometer,
              vehicleStats?.gpsOdometer
            ),
        engineHours: getVehicleEngineHours(
          vehicleMeters?.find(meter => meter.type === MeterType.Hours),
          vehicle?.engineSummarySource,
          vehicleStats?.gpsEngineHours,
          vehicleStats?.canEngineHours,
          vehicleStats?.canDiffEngineHours
        ),
        meters: vehicleMeters
      }
    }));
    let devicesWithStats;
    if (vehicle?.devices) {
      devicesWithStats = vehicle.devices.map(device => {
        const deviceStats = devicesStats.find(
          ds => parseInt(ds.deviceId, 10) === parseInt(device.id, 10)
        );
        return {
          ...device,
          stats: deviceStats
        };
      });
    }
    const engineSummarySourceMapArray = [
      { value: 'CAN', label: 'Actual CAN' },
      { value: 'CAN_DIFF', label: 'Elapsed CAN/ignition' },
      { value: 'GPS_DIFF', label: 'Elapsed GPS/Ignition' }
    ];
    let engineSummarySourceValue =
      vehicle && vehicle.engineSummarySource
        ? engineSummarySourceMapArray.find(elem => elem.value === vehicle.engineSummarySource).label
        : '-';
    if (
      vehicleMeters?.some(meter => meter.type === METER_TYPES.ODOMETER && meter.source === 'RUC')
    ) {
      engineSummarySourceValue = `RUC / ${engineSummarySourceValue}`;
    }

    const installerNotes = vehicle?.installationEvents
      ?.filter(event => {
        return event.extras && !!JSON.parse(event.extras).notes;
      })
      .map(event => {
        const notes = JSON.parse(event.extras).notes;
        return `${notes}\n * Added via Installer App on ${format(
          event.timeAt,
          localization.formats.time.formats.dby_imp
        )} *`;
      });

    const isSupportCan = (devicesWithStats || []).some(i => i.services?.includes(services.VPM));

    return {
      ...vehicle,
      [DEFAULT_DRIVER_FORM_FIELD_NAME]: vehicleInfo?.[DEFAULT_DRIVER_FORM_FIELD_NAME],
      engineSummarySourceLabel: engineSummarySourceValue,
      devices: devicesWithStats,
      stats: vehicleStats,
      maintenanceInfo: vehicleInfo,
      operationalVehicleModel: vehicleInfo?.operationalVehicleModel,
      installerNotes,
      schedules,
      isSupportCan
    };
  }, [
    allVehicles,
    vehicleStats,
    devicesStats,
    id,
    vehicleSchedules,
    vehicleInfo,
    vehicleConfig,
    vehicleMeters
  ]);

  useEffect(() => {
    dispatch(setPageTitle(vehicleData.name && `${vehicleData.name}`));
    dispatch(setBackButton(true));

    addBackButtonLink({
      url: pathname,
      backLink: '/settings/vehicles'
    });

    // if (vehicleData) {
    //   dispatch(
    //     addBreadcrumbs([
    //       {
    //         breadcrumbName: t('Vehicles.Vehicles'),
    //         path: '/settings/vehicles'
    //       },
    //       {}
    //     ])
    //   );
    // }

    // return () => {
    //   dispatch(addBreadcrumbs([]));
    // };
  }, [dispatch, vehicleData]);

  const [icon, setIcon] = useState([false, false, false, false]);
  const userDefinedFields = useVehicleMaintenanceFields(vehicleData?.companyId);

  // Get the company by the id
  const companies = useCompanies();
  let company;
  if (vehicleData.companyId) {
    company = companies.find(comp => comp.id === vehicleData.companyId);
  }
  // Generate the fleets name
  const fleets = vehicleData.fleets && renderFleetsAndCompany(vehicleData, vehiclesBoth, companies);

  // Devices columns
  const devicesColumns = [
    {
      label: `${t('Vehicles.View.Name')}`,
      width: 180,
      cellDataGetter: ({ rowData }) => rowData.name,
      cellRenderer: ({ rowData }) => (
        <Link to={`/settings/devices/id/${rowData.id}`}>{rowData.name}</Link>
      )
    },
    {
      label: `${t('Vehicles.View.DeviceType')}`,
      width: 100,
      cellDataGetter: ({ rowData }) => rowData.type.name
    },
    {
      label: `${t('Vehicles.View.LastLocation')}`,
      width: 250,
      cellRenderer: ({ rowData }) => {
        if (rowData.stats && rowData.stats.location) {
          return (
            <>
              <span onClick={() => handleMapModal(rowData.stats)} className={styles.linkSubstitute}>
                {rowData.stats.location}
              </span>
            </>
          );
        }
      }
    },
    {
      label: `${t('Vehicles.View.LastContact')}`,
      width: 180,
      cellDataGetter: ({ rowData }) => {
        let lastContact = '';
        if (rowData.stats && rowData.stats.lastCommsAt) {
          lastContact = format(
            new Date(rowData.stats.lastCommsAt),
            localization.formats.time.formats.dby_imp
          );
        }
        return lastContact;
      }
    },
    {
      label: `${t('Vehicles.View.Driver')}`,
      width: 0,
      cellRenderer: ({ rowData }) => {
        let user = '';
        if (rowData.stats && rowData.stats.currentUser) {
          user = `${rowData.stats.currentUser.firstName} ${rowData.stats.currentUser.lastName}`;
          return <Link to={`/settings/users/id/${rowData.stats.currentUser.id}`}>{user}</Link>;
        }
        return user;
      }
    }
  ];

  const nextDueCellRenderer = useCallback(
    props => {
      const propsWithVehicleConfig = {
        ...props,
        rowData: {
          ...(props?.rowData || {}),
          vehicle: {
            ...(props?.rowData?.vehicle || {}),
            config: vehicleConfig,
            rucDevice: vehicleConfig?.rucDevice
          }
        }
      };
      return _nextDueCellRenderer({ ...propsWithVehicleConfig, localization, t });
    },
    [vehicleConfig, localization, t]
  );

  const schedulesColumns = [
    {
      label: `${t('Vehicles.View.Status')}`,
      width: 120,
      cellRenderer: statusCellRenderer
    },
    {
      label: `${t('Vehicles.View.ServiceName')}`,
      width: 150,
      cellRenderer: props => serviceNameCellRenderer({ ...props, history, can })
    },
    {
      label: `${t('Vehicles.View.MaintenanceType')}`,
      width: 150,
      cellRenderer: maintenanceTypeCellRenderer
    },
    {
      label: `${t('Vehicles.View.ScheduledBy')}`,
      width: 150,
      cellRenderer: props => scheduledByCellRenderer({ ...props, users })
    },
    {
      label: `${t('Vehicles.View.ServiceDue')}`,
      width: 200,
      cellRenderer: props => serviceDueCellRenderer({ ...props, localization, t })
    },
    {
      label: `${t('Vehicles.View.NextDue')}`,
      width: 200,
      cellRenderer: props => nextDueCellRenderer(props)
    },
    {
      label: `${t('Vehicles.View.LastService')}`,
      width: 150,
      cellRenderer: props => lastServiceCellRenderer({ ...props, localization })
    },
    {
      label: `${t('VehicleMntSchedules.View.Notes')}`,
      width: 0,
      cellRenderer: props => notesCellRenderer(props)
    },
    {
      label: `${t('Vehicles.View.Actions')}`,
      width: 60,
      cellRenderer: props =>
        ellipsisButtonCellRenderer({ ...props, handleDeleteAction, t, history, dispatch })
    }
  ];

  const vehicleScheduleLoadingInfo = useMemo(() => {
    return {
      isLoading: isFetchingSchedule,
      columnSizes: schedulesColumns?.map(schedulesColumn => schedulesColumn.width),
      numberOfLoadingRows: 2
    };
  }, [isFetchingSchedule]);

  const VehicleMeterTable = useMemo(() => {
    if (vehicleMeters && vehicleData) {
      const data = vehicleMeters.map((meter, index) => {
        return {
          key: index,
          meterType:
            meter.source === MeterType.Gpio
              ? meter?.customType
                ? t(`Vehicles.GPIO.${meter?.customType}`, { defaultValue: meter?.customType })
                : t(`Vehicles.GPIO.${meter?.type}`)
              : t(`MeterTypes.${meter?.type}`, meter?.type),
          source: meter.source,
          type: getCalculatedBy(meter),
          value:
            meter.source === MeterType.Gpio
              ? `${getMeterValue(meter).toFixed(1)} ${t('Common.hrs')}`
              : formatMeterValue(localization, meter),
          lastReadingAt: meter.updatedAt
            ? format(moment(meter.updatedAt).toDate(), localization.formats.time.formats.dby_imp)
            : '',
          rebasedAt: meter.rebasedAt
            ? format(moment(meter.rebasedAt).toDate(), localization.formats.time.formats.dby_imp)
            : '',
          rebasedBy: meter.rebasedBy
            ? meter.rebasedBy?.firstName + ' ' + meter.rebasedBy?.lastName
            : ''
        };
      });
      return <Table columns={vehicleMetersColumns(t)} dataSource={data} pagination={false} />;
    }
  }, [localization, t, users, vehicleMeters, vehicleData]);

  if (vehiclesStats.length === 0) {
    return <LoadingTable columnSizes={[400]} />;
  }

  const renderAssets = assets => {
    if (!assets || !assets.length) return;
    return assets.map(asset => (
      <>
        <div>{asset}</div>
      </>
    ));
  };

  const handleAddNewSchedule = maintenanceInfo => () => {
    history.push({
      pathname: VEHICLE_MAINTENANCE_PATHS.VEHICLEMAINTENANCE_ADD,
      state: maintenanceInfo
    });
  };

  const handleCalibrate = () => {
    history.push(`/settings/vehicles/calibrate/id/${id}`);
  };

  const handleGPIO = () => {
    history.push(`/settings/vehicles/gpio/${id}`);
  };

  const renderMaintenanceInfo = (info, name) => {
    const maintenanceInfo = {
      name,
      date: info?.date || '',
      vehicleId: vehicleData.id,
      fleetId: vehicleData.fleets?.length > 0 ? vehicleData.fleets[0].id : null
    };

    return (
      <Row>
        <Col flex="100px">
          {info?.date
            ? format(
                moment(info.date.split('+')[0], 'YYYY-MM-DD'),
                localization.formats.time.formats.dby
              )
            : ''}
        </Col>
        <Col flex="auto">{info?.note}</Col>
        <Col flex="30px">
          <Tooltip
            content={t('Vehicles.View.CreateSchedule')}
            target={
              <ClockCircleOutlined
                className={styles.vehiclesAddNewSchedule}
                onClick={handleAddNewSchedule(maintenanceInfo)}
              />
            }
          />
        </Col>
      </Row>
    );
  };

  const renderVehicleModelInfo = vehicle => {
    const operationalVehicleModel = vehicle?.operationalVehicleModel;
    const dbcFileKey = vehicle.dbcFileKey || operationalVehicleModel?.dbcFileKey;

    let vehicleModelInfo = vehicleData.isSupportCan ? t('Vehicles.View.SourceInternal') : '-';
    if (operationalVehicleModel) {
      vehicleModelInfo =
        operationalVehicleModel?.make +
        ' / ' +
        operationalVehicleModel?.model +
        ' / ' +
        operationalVehicleModel?.yearOfManufacture +
        ' / ' +
        (operationalVehicleModel?.displaySource
          ? operationalVehicleModel?.displaySource
          : operationalVehicleModel?.source) +
        `${dbcFileKey ? ' / (' + dbcFileKey + ')' : ''}`;
    }

    return (
      <Row>
        <Col xs={24} style={{ fontWeight: 600 }}>
          {vehicleModelInfo}
        </Col>
      </Row>
    );
  };

  const handleDeleteAction = schedule => () => {
    dispatch(handleScheduleDeleteAction({ schedule, t }));
  };

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteVehicleApi(vehicleData, history));
        break;
      case 'restore':
        dispatch(restoreVehicleApi(vehicleData));
        break;
      default:
    }
  };

  const handleMapModal = stats => {
    setMapModalDetails({
      location: stats?.location,
      coordinates: {
        Lat: stats?.gps?.Lat,
        Lng: stats?.gps?.Lng
      }
    });
  };

  const handleDocumentDownload = document => () => dispatch(downloadDocument(document.id));

  const renderDocuments = () => {
    const { vehicleDocs } = vehicleInfo || [];
    if (!vehicleDocs || !vehicleDocs.length) {
      return;
    }

    return vehicleDocs.map(doc => (
      <div className={styles.linkStyle} key={doc.id} onClick={handleDocumentDownload(doc)}>
        {doc.filename || doc.id}
      </div>
    ));
  };

  const renderUserDefinedLabel = userDefinedField => {
    if (!userDefinedField) {
      return `${t('Vehicles.View.UserDefined')}`;
    }
    return userDefinedField;
  };

  if (id <= 0 || isNaN(id) || (vehicleNotFound && !vehicleInfo)) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t('Common.Invalid Request ID')
      })
    );
    handleFetchError();
  }

  if (!hasFetchedFleets) {
    return <LoadingCentered />;
  }

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{ entityName: AUDIT_ENTITY.VEHICLE, ...vehicleData }}
        editPath={`/settings/vehicles/edit/id/${id}`}
        auditPath={`/settings/vehicles/audit/id/${id}`}
        canUse="VEHICLE"
        handleButtonAction={handleButtonAction}
        typeOfEntityToDelete={t('Common.vehicle')}
      >
        <Can everyEntity={[entities[`VEHICLE_UPDATE`]]}>
          <Button size="large" onClick={handleCalibrate} id={BUTTON_IDS.vehicleViewCalibrate}>
            {t('Common.EllipsisButton.Calibrate')}
          </Button>
        </Can>
        <Can
          everyEntity={[entities[`VEHICLE_UPDATE`]]}
          otherConditions={[() => doesVehicleHaveHermesDevice(vehicleData)]}
        >
          <Button size="large" onClick={handleGPIO} id={BUTTON_IDS.vehicleViewGPIO}>
            {t('Common.EllipsisButton.GPIO')}
          </Button>
        </Can>
      </ViewHeaderWrapper>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
        className={styles.vehicleView}
      >
        <DeletedEntityAlert
          entity={DeletableEntityType.Vehicle}
          entityStatus={vehicleData?.status}
        />
        <InfoRow
          label={t('Vehicles.View.Company')}
          value={company ? company.name : ''}
          styles={styles}
        />
        <InfoRow label={t('Vehicles.View.Fleet')} value={fleets} styles={styles} />
        <VehicleDriverRow
          label={t('Vehicles.View.Driver')}
          vehicleData={vehicleData}
          styles={styles}
        />
        <InfoRow
          label={t('Vehicles.View.Devices')}
          value={
            vehicleData.devices?.length > 0 ? (
              <InfoTable
                data={vehicleData.devices}
                columns={devicesColumns}
                styles={styles}
                customRowStyle={{ alignItems: 'center', background: '#fff' }}
              />
            ) : (
              '-'
            )
          }
          styles={styles}
          sxValue={{
            width: '75%',
            minHeight: `${vehicleData.devices ? vehicleData.devices.length * 64 + 32 : 0}px`
          }}
        />
        <InfoRow
          label={t('Vehicles.View.Photo')}
          styles={styles}
          value={<ImageUpload disabled imageUrl={vehicleInfo?.imageUrl} />}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            maxWidth: '100%',
            borderBottom: '1px solid #EEE',
            padding: '10px 0',
            lineHeight: '24px'
          }}
        >
          <div style={{ width: '100%' }}>
            <Accordion
              title={t('Vehicles.View.TechnicalDetails')}
              body={
                <>
                  <InfoRow
                    label={t('Vehicles.View.VehicleType')}
                    value={vehicleData.type ? vehicleData.type.name : ''}
                    styles={styles}
                  />
                  <InfoRow
                    label={t('Vehicles.View.Registration')}
                    value={`${vehicleData.registrationState || ''} | ${vehicleData.registration ||
                      ''}`}
                    styles={styles}
                  />
                  <InfoRow label={t('Vehicles.View.VIN')} value={vehicleData.vin} styles={styles} />
                  <InfoRow
                    label={t('Vehicles.View.EngineNumber')}
                    value={vehicleData.engineNumber}
                    styles={styles}
                  />
                  {!supportInOutServiceDate && (
                    <InfoRow
                      label={t('Vehicles.View.DateInService')}
                      value={
                        vehicleData.serviceOn
                          ? format(
                              moment(vehicleData.serviceOn, 'YYYY-MM-DD'),
                              localization.formats.time.formats.dby
                            )
                          : '-'
                      }
                      styles={styles}
                    />
                  )}
                  <InfoRow
                    label={t('Vehicles.View.ManufactureYear')}
                    value={
                      vehicleData.manufacturedOn && vehicleData.manufacturedOn !== '1899-12-31'
                        ? vehicleData.manufacturedOn.split('-')[0]
                        : '-'
                    }
                    styles={styles}
                  />
                  <InfoRow
                    label={t('Vehicles.View.Make')}
                    value={vehicleData.make || '-'}
                    styles={styles}
                  />
                  <InfoRow
                    label={t('Vehicles.View.Model')}
                    value={vehicleData.model || '-'}
                    styles={styles}
                  />
                  {(isSiteAdmin || hasSiteAdminOrResellerRole) && (
                    <InfoRow
                      label={t('Vehicles.View.Source')}
                      value={renderVehicleModelInfo(vehicleData)}
                      styles={styles}
                    />
                  )}
                  <InfoRow label={t('Vehicles.View.EngineProfile')} value={'-'} styles={styles} />
                  {vehicleConfig.hasEDRDevice && (
                    <InfoRow
                      label={t('VehicleMntSchedules.Table.RucOdometer')}
                      value={formatOdometer(localization, vehicleConfig.rucOdometer) || '-'}
                      styles={styles}
                    />
                  )}
                  <InfoRow
                    label={t('Vehicles.View.Phone')}
                    value={vehicleData.phone || '-'}
                    styles={styles}
                  />
                  <InfoRow
                    label={t('Vehicles.View.ExternalID')}
                    value={vehicleData.externalId || '-'}
                    styles={styles}
                  />
                  <InfoRow
                    label={t('Vehicles.View.Note')}
                    value={vehicleData.note || '-'}
                    styles={styles}
                  />
                  {isSiteAdmin && (
                    <InfoRow
                      label={t('Vehicles.View.InstallerNotes')}
                      value={vehicleData.installerNotes?.length ? vehicleData.installerNotes : '-'}
                      delimiter={'\n'}
                      sxValue={{ whiteSpace: 'pre-wrap' }}
                      styles={styles}
                    />
                  )}
                  <InfoRow
                    label={t('Vehicles.View.Documents')}
                    value={renderDocuments()}
                    styles={styles}
                  />
                  {supportInOutServiceDate && (
                    <>
                      <InfoRow
                        label={t('Vehicles.View.InServiceAtLabel')}
                        value={
                          vehicleData.inServiceAt
                            ? format(
                                new Date(vehicleData.inServiceAt),
                                localization.formats.time.formats.dby_imp
                              )
                            : ''
                        }
                        styles={styles}
                      />
                      <InfoRow
                        label={t('Vehicles.View.OutServiceAtLabel')}
                        value={
                          vehicleData.outServiceAt
                            ? format(
                                new Date(vehicleData.outServiceAt),
                                localization.formats.time.formats.dby_imp
                              )
                            : ''
                        }
                        styles={styles}
                      />
                    </>
                  )}
                  <InfoRow
                    label={t('Vehicles.View.CreatedAt')}
                    value={format(
                      new Date(vehicleData.createdAt || null),
                      localization.formats.time.formats.dby_imp
                    )}
                    styles={styles}
                  />
                  <InfoRow
                    label={t('Vehicles.View.UpdatedAt')}
                    value={
                      vehicleData.updatedAt
                        ? format(
                            new Date(vehicleData.updatedAt),
                            localization.formats.time.formats.dby_imp
                          )
                        : '-'
                    }
                    styles={styles}
                  />
                  <div className={styles.vehiclesMetersLabel}>
                    {t('Vehicles.View.VehicleMeters')}
                  </div>
                  {VehicleMeterTable}
                </>
              }
              icon={icon[0]}
              onChange={() => setIcon([!icon[0], icon[1], icon[2], icon[3]])}
            />
            {hasSiteAdminOrResellerRole && (
              <Accordion
                title={t('Agreement.Agreements')}
                body={<VehicleAgreements vehicleId={vehicleData.id} />}
                icon={icon[1]}
                onChange={() => setIcon([icon[0], !icon[1], icon[2], icon[3]])}
              />
            )}
            <Accordion
              title={t('Vehicles.View.VehicleMaintenanceInformation')}
              body={
                <>
                  <InfoRow
                    label={t('Vehicles.View.VehicleAssets')}
                    value={renderAssets(vehicleData?.maintenanceInfo?.assets)}
                    styles={styles}
                  />
                  {userDefinedFields &&
                    Object.keys(userDefinedFields).map((data, index) => {
                      return (
                        <InfoRow
                          key={index}
                          label={renderUserDefinedLabel(userDefinedFields[data])}
                          sxValue={{ width: '75%' }}
                          value={renderMaintenanceInfo(
                            vehicleData?.maintenanceInfo?.vehicleMaintenanceInfo?.customFields[
                              data
                            ],
                            userDefinedFields[data]
                          )}
                          styles={styles}
                        />
                      );
                    })}
                  {vehicleConfig?.isUsingRUCOdometerForMnt && (
                    <InfoRow
                      label={t('Vehicles.UseRucOdometer')}
                      value={vehicleConfig.isUsingRUCOdometerForMnt}
                      styles={styles}
                    />
                  )}
                </>
              }
              icon={icon[2]}
              onChange={() => setIcon([icon[0], icon[1], !icon[2], icon[3]])}
            />
            <Accordion
              title={t('Vehicles.View.VehicleMaintenanceSchedules')}
              body={
                <>
                  <div
                    style={{
                      flex: '1 0 0',
                      height: 'calc(100% - 64px)',
                      width: '100%'
                    }}
                  >
                    {(vehicleScheduleLoadingInfo.isLoading || !!vehicleData?.schedules?.length) && (
                      <InfoRow
                        value={
                          <InfoTable
                            infoTableCls={
                              vehicleData?.schedules?.length < 3 ? styles.smallTable : ''
                            }
                            isLoading={vehicleScheduleLoadingInfo.isLoading}
                            columnSizes={vehicleScheduleLoadingInfo.columnSizes}
                            numberOfLoadingRows={vehicleScheduleLoadingInfo.numberOfLoadingRows}
                            data={vehicleData.schedules || []}
                            columns={schedulesColumns}
                            styles={styles}
                            customRowStyle={{
                              alignItems: 'center',
                              background: '#fff',
                              lineHeight: '1.1'
                            }}
                          />
                        }
                        label=""
                        hideBorder
                        labelWidth={'0%'}
                        styles={styles}
                        sxValue={{
                          width: '100%',
                          minHeight: `${
                            vehicleData?.schedules ? vehicleData.schedules.length * 64 + 32 : 0
                          }px`
                        }}
                      />
                    )}
                    {!vehicleData?.schedules?.length &&
                      !vehicleScheduleLoadingInfo.isLoading &&
                      `${t('Vehicles.View.NoSchedules')}`}
                  </div>
                </>
              }
              icon={icon[3]}
              onChange={() => setIcon([icon[0], icon[1], icon[2], !icon[3]])}
            />
          </div>
        </div>
        {mapModalDetails && (
          <MapModal
            show={mapModalDetails}
            coordinates={
              mapModalDetails?.coordinates && {
                lat: mapModalDetails.coordinates?.Lat,
                lng: mapModalDetails.coordinates?.Lng
              }
            }
            title={mapModalDetails?.location}
            onHide={() => setMapModalDetails(undefined)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const VehicleDriverRow = ({ label, vehicleData, styles }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const drivers = useDrivers();
  const devices = useDevices();
  const vehicleCanDefaultDriver = useMemo(() => canVehicleHasDefaultDriver(vehicleData, devices), [
    devices,
    vehicleData
  ]);

  const driver = useMemo(() => {
    const defaultDriver =
      vehicleCanDefaultDriver && vehicleData?.[DEFAULT_DRIVER_FORM_FIELD_NAME]
        ? drivers?.find(
            driver =>
              parseInt(driver.id, 10) ===
              parseInt(vehicleData?.[DEFAULT_DRIVER_FORM_FIELD_NAME], 10)
          ) || { id: vehicleData[DEFAULT_DRIVER_FORM_FIELD_NAME] }
        : null;
    const currentDriver = vehicleData?.stats?.currentUser || defaultDriver;
    return currentDriver
      ? {
          ...currentDriver,
          isDefaultDriver: isDefaultDriverOfVehicle(currentDriver, vehicleData)
        }
      : null;
  }, [vehicleData, drivers, vehicleCanDefaultDriver]);

  const onForceLogoff = useCallback(
    loggedOff => {
      if (loggedOff) {
        dispatch(fetchVehiclesStats());
      }
    },
    [dispatch]
  );
  const { canForceLoggoff, ForceLogoffBtn } = useForceLogoff(
    driver,
    vehicleData,
    entities.VEHICLE,
    onForceLogoff
  );
  const driverLink = useMemo(() => {
    if (driver?.id) {
      return (
        <Row align="middle" justify="start" gutter={8}>
          <Col flex={canForceLoggoff ? 'none' : 1}>
            <span>
              <Link
                to={`/settings/users/id/${driver.id}`}
              >{`${driver.firstName} ${driver.lastName}`}</Link>
            </span>
            {driver.isDefaultDriver && (
              <span className={defaultDriverStyles.boldDefault}>{t('Default')}</span>
            )}
          </Col>
          {canForceLoggoff && (
            <Col span={12}>
              <ForceLogoffBtn />
            </Col>
          )}
        </Row>
      );
    }
    return '-';
  }, [driver, canForceLoggoff, ForceLogoffBtn, t]);

  return <InfoRow label={label} value={driverLink} styles={styles} sxValue={{ width: '75%' }} />;
};
