import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import i18n from 'i18next';
import * as Yup from 'yup';
import { Form, Formik, useField } from 'formik';
import { Col, Row, FormGroup, FormLabel } from 'react-bootstrap';
import { Select, Space, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import FormInput from 'components/form/form-input/FormInput';
import FormSelect from 'components/form/form-select/FormSelect';
import { FeatureFlag } from 'features/permissions';
import SearchableListMultiSelect from 'components/form/searchable-list-multi-select/SearchableListMultiSelect';
import { ToastType } from 'components/notifications/toasts/Toast';
import {
  useCompanies,
  useCurrentCompany,
  useRedirectToMainFeaturePageOnCompanyChange,
  useIsCompanyKeyDifferent
} from 'features/company/companySlice';
import { useDeviceModelsList } from 'features/device_models/deviceModelsSlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import {
  fetchFleets,
  useVehicles,
  useDevices,
  useFleetsBoth,
  extractVehicles,
  useIsFetchingDevicesFinished
} from 'features/fleets/fleetsSlice';
import { saveDeviceFleetsApi, saveDeviceApi } from 'features/devices/devicesSlice';
import { fetchIQCamerDevicesConfig } from 'features/company_config';
import { openToast } from 'features/toasts/toastsSlice';
import { useUser, useUserKey } from '../../../features/user/userSlice';
import { getFleetsByCompany } from 'containers/Administration/Fleets/APICalls';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  useDeletedDevices,
  fetchDeletedDevices,
  useIsFetchingDeletedDevicesFinished
} from 'features/devices/devicesDeletedSlice';
import { PhoneInputField, usePhoneNumber } from 'components/form/phone-input-field';
import { BulkEditRouteGuard } from './BulkEdit/BulkEditRouteGuard';
import { Button, FormDatePicker } from 'components/ant';
import { useTranslation } from 'react-i18next';
import { canHistoryGoBack } from 'utils/methods';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCanEveryEntity, useCan, GlobalRoles } from 'features/permissions';
import entities from 'features/permissions/entities';

import styles from './Devices.module.scss';
import { PATHS, IQCameraDeviceConfig } from './constants';
import { BUTTON_IDS } from 'utils/globalConstants';
import { isIQCamModelId } from 'features/camera/CameraModelConfig';
import {
  ngGpioConfigurationApi,
  useGpioConfigurations
} from 'services/nextgen/ngGpioConfigurationApi';
import services from 'features/permissions/services';
import { OrderedTextRenderer } from './CellRenderers';
import { sortBy } from 'lodash';
import { DeviceTransferModal } from './DeviceTransferModal';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

const DEVICE_EDIT_PATH = '/settings/devices/edit';

const validationSchema = payload => {
  const { allDeviceNames, allDeviceIMEI, deviceData, types, models, action } = payload;
  return Yup.object().shape({
    name: Yup.string()
      .max(50, i18n.t('Devices.FormValidation.NameTooLong'))
      .required(i18n.t('Devices.FormValidation.NameRequired'))
      .matches(/\S/, i18n.t('Devices.FormValidation.NameRequired'))
      .test(
        'Unique',
        i18n.t('Devices.FormValidation.IdenticalName'),
        values => !allDeviceNames.includes(values) || values === deviceData?.name
      ),
    imei: Yup.string()
      .min(1, i18n.t('Devices.FormValidation.ImeiTooShort'))
      .matches(/^[a-zA-Z0-9_-]*$/, i18n.t('Devices.FormValidation.OnlyAlphanumeric'))
      .required(i18n.t('Devices.FormValidation.ImeiRequired'))
      .test(
        'Unique',
        i18n.t('Devices.FormValidation.IdenticalIMEI'),
        values => !allDeviceIMEI.includes(values) || values === deviceData?.imei
      ),
    serialNumber: Yup.string()
      .min(1, i18n.t('Devices.FormValidation.SerialNumberTooShort'))
      .matches(/^[a-zA-Z0-9_-]*$/, i18n.t('Devices.FormValidation.OnlyAlphanumeric'))
      .required(i18n.t('Devices.FormValidation.SerialNumberRequired')),
    sim: Yup.string().matches(
      /^[a-zA-Z0-9_-]*$/,
      i18n.t('Devices.FormValidation.OnlyAlphanumeric')
    ),
    type: Yup.string()
      .required(i18n.t('Devices.FormValidation.TypeRequired'))
      .test(
        'Unique',
        i18n.t('Devices.FormValidation.TypeRequired'),
        values =>
          action !== 'edit' ||
          types.find(type => type.id === parseInt(values)).name.toUpperCase() !== 'DEFAULT'
      ),
    model: Yup.string()
      .required(i18n.t('Devices.FormValidation.ModelRequired'))
      .test(
        'Unique',
        i18n.t('Devices.FormValidation.ModelRequired'),
        values =>
          action !== 'edit' ||
          (models.find(type => type.id === parseInt(values)) &&
            models.find(type => type.id === parseInt(values)).name.toUpperCase() !== 'DEFAULT')
      ),
    companyId: Yup.string().required(i18n.t('Devices.FormValidation.CompanyRequired')),
    audioAlertsEnabled: Yup.array().when('model', {
      is: modelId => isIQCamModelId(modelId, models),
      then: schema => {
        const visibleAudioAlertTypes = Object.values(IQCameraDeviceConfig.AudioAlertTypes).map(
          type => type.value
        );
        return schema.test(
          'Required',
          i18n.t('Devices.FormValidation.AudioAlertsRequired'),
          value => !!value?.some(v => visibleAudioAlertTypes.includes(v))
        );
      },
      otherwise: schema => schema.min(0)
    })
  });
};

const postBodyShape = {
  id: 0,
  name: '',
  path: null,
  version: null,
  status: 'ENABLED',
  companyId: 0,
  vehicle: undefined,
  type: {},
  model: {},
  did: 0,
  registration: 0,
  externalId: 0,
  serialNumber: '',
  imei: '',
  sim: '',
  phone: '',
  note: '',
  auto: false,
  iapId: null,
  releaseVersion: null,
  modemType: '',
  modemImei: '',
  tags: null
};

const newDevice = {
  name: '',
  companyId: '',
  did: '',
  imei: '',
  serialNumber: '',
  externalId: '',
  sim: '',
  phone: '',
  type: {},
  model: {},
  fleets: [],
  fleetInfo: [],
  vehicle: undefined,
  note: ''
};

export const DeviceForm = ({ action }) => {
  const path = window.location.pathname;
  const deviceId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const companies = useCompanies();
  const allFleets = useFleetsBoth('UP');
  const history = useHistory();
  const currentCompany = useCurrentCompany();
  const localization = useLocalization();
  const currentUser = useUser();
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const initialVehicles = useVehicles();
  const allDevices = useDevices();
  const deletedDevices = useDeletedDevices();
  const allDeviceNames = allDevices.map(device => device?.name);
  const allDeviceIMEI = allDevices.concat(deletedDevices).map(device => device?.imei);
  const [vehicles, setVehicles] = useState([]);
  const [disableMultiSelect, setDisableMultiselect] = useState(false);
  const [fleets, setFleets] = useState([]);
  const [deviceData, setDeviceData] = useState(newDevice);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [selectedType, setSelectedType] = useState('');
  const { phoneNumber, phoneNumberWithoutDialCode, handlePhoneNumberChange } = usePhoneNumber(
    deviceData?.phone
  );
  const promptModalWhenLeaving = true;
  const [userCheckedFleets, setUserCheckedFleets] = useState(fleets);
  const [fleetsLoaded, setFleetsLoaded] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [iqCamera, setIqCamera] = useState(false);
  const types = useDeviceTypesList();
  const models = useDeviceModelsList();
  const [showDeviceTransferModal, setShowDeviceTransferModal] = useState(false);
  const [transferData, setTransferData] = useState({});
  const { data: gpioConfigurationTemplates } = useGpioConfigurations();

  const { t } = useTranslation();
  const canDissociateDevice = useCanEveryEntity([entities.DEVICE_DISSOCIATE]);
  const can = useCan();
  const canShowAudioAlert = useMemo(() => iqCamera && action === 'edit', [iqCamera, action]);
  const hasDevicesFetched = useIsFetchingDevicesFinished();
  const hasDeletedDevicesFetched = useIsFetchingDeletedDevicesFinished();
  //Edit
  const deviceEdit = allDevices.find(device => device.id === parseInt(deviceId, 10)) || newDevice;

  const sortedTypes = types
    .slice()
    .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
  const sortedModels = models
    .slice()
    .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
  const defaultType = sortedTypes.find(type => type.name.toUpperCase() === 'DEFAULT');
  const defaultModel = sortedModels.find(model => model.name.toUpperCase() === 'DEFAULT');

  const canControlGpioConfiguration = can({
    oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
    oneOfCompanyServices: [services.GPIO]
  });

  const hasCompanyChanged = useIsCompanyKeyDifferent('devices');
  const canSubCompanyManagement = can({
    featureFlag: FeatureFlag.subCompanyManagement.flag,
    oneOfRoles: [GlobalRoles.SubCompanyAdmin]
  });
  const [canTransferCompany, setCanTransferCompany] = useState(true);
  useRedirectToMainFeaturePageOnCompanyChange('/settings/devices');

  const handleFetchError = useCallback(() => {
    history.replace(PATHS.DEVICE_DEFAULT);
  }, [history]);

  const invalidRequest = useCallback(() => {
    if (history.location.pathname !== PATHS.DEVICE_DEFAULT) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [dispatch, handleFetchError, history]);

  useEffect(() => {
    const parsedId = parseInt(deviceId);
    if (action === 'edit' && (parsedId <= 0 || isNaN(parsedId))) {
      invalidRequest();
    }
  }, [deviceId, action, invalidRequest]);

  useEffect(() => {
    if (action === 'edit' && hasDevicesFetched && hasDeletedDevicesFetched) {
      const parsedId = parseInt(deviceId);
      if (
        !allDevices.some(d => d.id === parsedId) &&
        !deletedDevices?.some(d => d.id === parsedId)
      ) {
        invalidRequest();
      }
    }
  }, [hasDevicesFetched, hasDeletedDevicesFetched, deviceId, action, allDevices, deletedDevices]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (action === 'edit') {
      dispatch(setPageTitle(deviceData.name && `Edit ${deviceData.name}`));
    } else {
      dispatch(setPageTitle(`${t('Devices.ActualForm.AddNewDevice')}`));
    }
  }, [dispatch, deviceData, action]);

  useEffect(() => {
    if (deviceEdit?.companyId && allFleets) {
      updateFormOnCompanyChange(deviceEdit.companyId);
    }
  }, [deviceEdit, allFleets]);

  useEffect(() => {
    // Do not update edit form when the company changes from the dropdown
    if (!path || !path.includes(DEVICE_EDIT_PATH)) {
      updateFormOnCompanyChange(currentCompany.id);
    }
    setDisableMultiselect(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, currentCompany]);

  useEffect(() => {
    const formFleets = allFleets.map(fleet => {
      const companyFleet = companies.find(company => company.id === fleet.company?.id);
      return {
        value: fleet.id,
        label: fleet?.name ? `${fleet.name} (${companyFleet?.name || ''})` : '',
        checked: false,
        vehicles: fleet.vehicles
      };
    });
    setFleets(formFleets);
  }, [allFleets, currentCompany.id]);

  const onVehicleChange = (vehicles, vehicle, setFieldValue) => {
    setFieldValue('vehicle', vehicle);
    setDisableMultiselect(vehicle ? true : false);
    setSelectedVehicle(vehicles.find(veh => veh.id === parseInt(vehicle, 10)));
  };

  const onGpioConfigurationTemplateChange = (templateId, setFieldValue) => {
    setFieldValue('gpioTemplateId', templateId);
    setIsFormDirty(true);
  };

  const isVehicle = vehicle => {
    if (vehicle === undefined || vehicle === null || vehicle === '') {
      return false;
    }
    return true;
  };

  const updateFormOnCompanyChange = (newCompanyId, transferOption, setFieldValue) => {
    setFleetsLoaded(false);
    const embed = 'vehicles,devices';
    getFleetsByCompany(newCompanyId, userKey, 'UP', embed).then(newFleets => {
      const formFleets = newFleets.reduce((acc, fleet) => {
        const companyFleet = companies.find(company => company.id === fleet.company?.id);
        fleet.id &&
          acc.push({
            value: fleet.id,
            label: fleet?.name ? `${fleet.name} (${companyFleet?.name || ''})` : '',
            checked: false,
            vehicles: vehicles
          });
        return acc;
      }, []);
      setFleets(formFleets);
      setFleetsLoaded(true);

      //keep vehicle option if user wish to transfer device with vehicle
      if (
        transferOption === undefined ||
        Object.keys(transferOption).length === 0 ||
        transferOption?.companyId === deviceData.companyId ||
        transferOption?.deviceOnly
      ) {
        const newVehicles = extractVehicles(newFleets).filter(
          vehicle => vehicle.id && vehicle.companyId === parseInt(newCompanyId, 10)
        );
        setVehicles(newVehicles);
        if (setFieldValue) {
          const attachedVehicleId =
            parseInt(deviceData.companyId) === parseInt(newCompanyId, 10)
              ? deviceData.vehicle
              : undefined;
          onVehicleChange(newVehicles, attachedVehicleId, setFieldValue);
        }
      }
    });
  };

  const onCompanyChange = (company, setFieldValue, transferOption) => {
    setFieldValue('companyId', company);

    if (!company) {
      return;
    }
    updateFormOnCompanyChange(company, transferOption, setFieldValue);
  };

  const onTypeChange = (type, setFieldValue) => {
    setFieldValue('type', type);
    setSelectedType(type);

    //when type is changed, we will unassign the template
    onGpioConfigurationTemplateChange('', setFieldValue);
    if (defaultModel?.id) {
      onModelChange(defaultModel?.id, setFieldValue);
    }
  };

  const onModelChange = (id, setFieldValue) => {
    setFieldValue('model', id);
    setIqCamera(isIQCamModelId(id, models));
  };

  useEffect(() => {
    const formVehicles = initialVehicles.filter(
      vehicle => vehicle.id && vehicle.companyId === currentCompany.id
    );
    setVehicles(formVehicles);
  }, [initialVehicles, deviceEdit, currentCompany.id]);

  useEffect(() => {
    if (action === 'edit') {
      if (deviceEdit.vehicle !== undefined) {
        setDisableMultiselect(true);
      }
      const deviceTempEdit = {
        name: deviceEdit.name,
        companyId: deviceEdit.companyId,
        did: deviceEdit.did,
        imei: deviceEdit.imei,
        serialNumber: deviceEdit.serialNumber,
        externalId: deviceEdit.externalId || '',
        sim: deviceEdit.sim || '',
        phone: deviceEdit.phone || '',
        inServiceAt: deviceEdit.inServiceAt || '',
        outServiceAt: deviceEdit.outServiceAt || '',
        type: deviceEdit.type.id,
        model: deviceEdit.model?.id,
        cameraSensitivity:
          deviceEdit.cameraSensitivity || IQCameraDeviceConfig.DefaultSensitivityValue,
        volume: deviceEdit.volume || IQCameraDeviceConfig.DefaultVolumeValue,
        audioAlertsEnabled:
          deviceEdit.audioAlertsEnabled || IQCameraDeviceConfig.DefaultAudioAlertTypes,
        enableDriverCamera: `${deviceEdit.enableDriverCamera ??
          IQCameraDeviceConfig.DefaultEnableDriverCamera}`,
        vehicle: (deviceEdit.vehicle || {}).id,
        note: deviceEdit.note || '',
        gpioTemplateId: deviceEdit.gpioTemplateId,
        fleets: deviceEdit.fleetInfo.map(fleet => ({
          value: fleet.id,
          label: fleet.name,
          checked: true
        }))
      };

      setSelectedVehicle(
        vehicles.find(veh => veh.id === parseInt((deviceEdit.vehicle || {}).id, 10))
      );
      setSelectedType(deviceEdit.type?.id || '');
      const isIqCamera = isIQCamModelId(deviceEdit.model?.id, models);
      setIqCamera(isIqCamera);
      if (isIqCamera) {
        dispatch(
          fetchIQCamerDevicesConfig({
            companyId: currentCompany.id,
            deviceIds: [deviceId],
            onSuccess: ([data], keyConvertor) => {
              Object.entries(data?.config || {}).forEach(([key, value]) => {
                const convertedKey = keyConvertor(key);
                const keysNeedStrValue = ['enableDriverCamera'];
                deviceTempEdit[convertedKey] = keysNeedStrValue.includes(convertedKey)
                  ? `${value}`
                  : value;
              });
              setDeviceData(deviceTempEdit);
            },
            onError: msg => {
              // If error, we proceed displaying the data with default value for camera sensitivity and volume.
              setDeviceData(deviceTempEdit);
              console.error('Error fetching IQ Camera configuration due to ' + msg);
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: t('Devices.ActualForm.IQCameraConfigError')
                })
              );
            }
          })
        );
      } else {
        setDeviceData(deviceTempEdit);
      }
    }
  }, [action, deviceEdit, userKey, vehicles]);

  useEffect(() => {
    if (deviceData?.phone !== phoneNumber) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [phoneNumber, deviceData.phone]);

  const handleSuccess = (actions, values) => {
    let messageType = 'DeviceAdded';
    if (action === 'edit') {
      messageType = 'DeviceUpdated';
    }
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t(`Devices.ActualForm.${messageType}`, {
          name: values.name
        })
      })
    );

    dispatch(ngGpioConfigurationApi.util.invalidateTags(['gpioConfigurationTemplate']));
    dispatch(fetchFleets());
    dispatch(fetchDeletedDevices());
    if (iqCamera) {
      dispatch(
        fetchIQCamerDevicesConfig({
          companyId: currentCompany?.id,
          deviceIds: [deviceId],
          forceFetch: true
        })
      );
    }
    actions.resetForm();
    actions.setSubmitting(false);
    setIsFormDirty(false);
    canHistoryGoBack(history, PATHS.DEVICE_DEFAULT);
  };

  useEffect(() => {
    const selectedTypeObj = types.find(t => t.id === selectedType);
    setCanTransferCompany(
      !canSubCompanyManagement || currentUser?.siteAdmin || selectedTypeObj?.code !== 'EDR'
    );
  }, [selectedType]);

  const preSubmitMethod = (e, handleSubmit) => {
    e.preventDefault();

    const selectedTypeObj = types.find(t => t.id === selectedType);
    const content =
      selectedTypeObj?.code === 'EDR' ? (
        <div>
          {t('Vehicles.Form.ChangeCompanySaveWarningEDR')}
          <br />
          {t('Vehicles.Form.ChangeCompanySaveWarningEDR2')}
        </div>
      ) : (
        undefined
      );

    if (content && canSubCompanyManagement && transferData.companyId !== deviceData.companyId) {
      confirmationModal(
        t('Roles.ConfirmationModal.AreYouSure'),
        content,
        t('Common.Modal.OK'),
        t('Common.Modal.Cancel'),
        () => handleSubmit(),
        'delete'
      );
      document.body.click();
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema({
          allDeviceNames,
          allDeviceIMEI,
          deviceData,
          types,
          models,
          action
        })}
        initialValues={{
          ...deviceData,
          companyId: (deviceEdit && deviceEdit.companyId) || currentCompany.id
        }}
        onSubmit={async (values, actions) => {
          const hasVehicleAssociated = isVehicle(values.vehicle);

          let postBody = {
            ...postBodyShape
          };
          postBody = {
            ...postBody,
            name: values.name,
            companyId: values.companyId,
            vehicle: hasVehicleAssociated
              ? { id: vehicles.find(vehicle => vehicle.id === parseInt(values.vehicle))?.id }
              : null,
            model: models.find(model => model.id === parseInt(values.model)),
            type: types.find(
              type =>
                type.id === (action === 'add' ? parseInt(defaultType.id) : parseInt(values.type))
            ),
            externalId: values.externalId,
            serialNumber: values.serialNumber,
            imei: values.imei,
            sim: values.sim,
            phone: phoneNumberWithoutDialCode ? phoneNumber : '',
            did: values.did,
            registration: values.did,
            note: values.note,
            gpioTemplateId: values.gpioTemplateId,
            inServiceAt: values.inServiceAt,
            outServiceAt: values.outServiceAt
          };
          if (iqCamera) {
            postBody['cameraSensitivity'] =
              values.cameraSensitivity || IQCameraDeviceConfig.DefaultSensitivityValue;
            postBody['volume'] = values.volume || IQCameraDeviceConfig.DefaultVolumeValue;
            postBody['audioAlertsEnabled'] = values.audioAlertsEnabled?.filter(value => value);
            postBody['enableDriverCamera'] = JSON.parse(values.enableDriverCamera);
          }

          if (canSubCompanyManagement && transferData) {
            postBody['transferVehicle'] = transferData.deviceOnly === false;
            postBody['agreements'] = transferData.agreements;
          }
          let checkedFleets = disableMultiSelect
            ? allFleets
                .filter(
                  fleet =>
                    selectedVehicle.fleets?.find(vehFleet => vehFleet.id === fleet.id) !== undefined
                )
                .map(fleet => fleet.id)
            : userCheckedFleets.reduce((accumulator, fleet) => {
                if (fleet.checked) {
                  accumulator.push(fleet.value);
                }
                return accumulator;
              }, []);

          if (action === 'add') {
            const devicesResponse = await dispatch(
              saveDeviceApi('POST', false, postBody, hasVehicleAssociated)
            );
            if (devicesResponse && devicesResponse.id) {
              const { id } = devicesResponse;
              if (!hasVehicleAssociated) {
                await dispatch(saveDeviceFleetsApi(id, checkedFleets));
                handleSuccess(actions, values);
              } else {
                handleSuccess(actions, values);
              }
            }
          }
          if (action === 'edit') {
            const id = deviceEdit.id;
            if (!hasVehicleAssociated) {
              await dispatch(saveDeviceFleetsApi(id, checkedFleets));
              const devicesResponse = await dispatch(saveDeviceApi('PUT', id, postBody));
              if (devicesResponse) {
                handleSuccess(actions, values);
              }
            } else {
              const devicesResponse = await dispatch(
                saveDeviceApi('PUT', id, postBody, hasVehicleAssociated)
              );
              if (devicesResponse) {
                handleSuccess(actions, values);
              }
            }
          }
        }}
      >
        {({ isSubmitting, isValid, status, handleSubmit, setFieldValue, dirty }) => (
          <>
            <BulkEditRouteGuard
              when={((dirty && promptModalWhenLeaving) || isFormDirty) && !hasCompanyChanged}
              exclude={location => location.pathname.includes(PATHS.DEVICE_DEFAULT)}
            />
            <Form id="DeviceForm" className={styles.deviceForm}>
              <div className={styles.formContainer}>
                <Row xs="2">
                  <Col className={styles.columnPanel}>
                    <FormInput
                      name="name"
                      label={t('Devices.ActualForm.NameLabel')}
                      placeholder={t('Devices.ActualForm.NamePlaceholder')}
                      isRequired
                    />
                  </Col>
                  <Col className={styles.columnPanel}>
                    <Space align="end" size={0} className={styles.spacePanel}>
                      <FormSelect
                        name="companyId"
                        label={`${t('Devices.ActualForm.CompanyLabel')}`}
                        values={companies.map(company => ({
                          label: company.name,
                          value: company.id
                        }))}
                        onChange={id => {
                          if (
                            canSubCompanyManagement &&
                            action === 'edit' &&
                            parseInt(deviceData.companyId) !== parseInt(id)
                          ) {
                            setTransferData(prev => ({
                              ...prev,
                              targetCompanyId: parseInt(id)
                            }));
                            setShowDeviceTransferModal(true);
                          } else {
                            setTransferData({});
                            onCompanyChange(id, setFieldValue);
                          }
                        }}
                        isRequired
                        isDisabled={
                          canSubCompanyManagement
                            ? !canTransferCompany
                            : disableMultiSelect || (!currentUser?.siteAdmin && action === 'edit')
                        }
                      />
                      {Object.keys(transferData)?.length > 1 && (
                        <Button
                          size="large"
                          onClick={() => {
                            setShowDeviceTransferModal(true);
                          }}
                          type="link"
                        >
                          {t('Common.Edit')}
                        </Button>
                      )}
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <FormInput
                    name="imei"
                    label={t('Devices.ActualForm.ImeiLabel')}
                    placeholder={t('Devices.ActualForm.ImeiPlaceholder')}
                    isRequired
                  />
                  <FormInput
                    name="serialNumber"
                    label={t('Devices.ActualForm.SerialNumberLabel')}
                    placeholder={t('Devices.ActualForm.SerialNumberPlaceholder')}
                    isRequired
                  />
                  {action === 'edit' && FeatureFlag.sapEquipmentId && currentUser.siteAdmin && (
                    <FormInput
                      name="sapEquipmentId"
                      label={t('Devices.ActualForm.SAPEquipmentID')}
                      disabled
                    />
                  )}
                </Row>
                <Row className={action !== 'edit' ? styles.hidden : ''}>
                  <FormSelect
                    name="type"
                    label={t('Devices.ActualForm.TypeLabel')}
                    placeholder={t('Devices.ActualForm.TypePlaceholder')}
                    defaultValue={defaultType?.id}
                    onChange={type => onTypeChange(type, setFieldValue)}
                    values={sortedTypes.map(type => ({
                      label: type.name,
                      value: type.id
                    }))}
                    isRequired
                  />
                  <FormSelect
                    name="model"
                    label={t('Devices.ActualForm.ModelLabel')}
                    placeholder={t('Devices.ActualForm.ModelPlaceholder')}
                    defaultValue={defaultModel?.id}
                    onChange={id => onModelChange(id, setFieldValue)}
                    values={
                      selectedType !== ''
                        ? sortedModels
                            .filter(model => model.deviceType.id === parseInt(selectedType))
                            .map(model => ({
                              label:
                                model.name.toLowerCase() === 'iqcamera' ? 'IQ Camera' : model.name,
                              value: model.id
                            }))
                        : sortedModels.map(model => ({
                            label:
                              model.name.toLowerCase() === 'iqcamera' ? 'IQ Camera' : model.name,
                            value: model.id
                          }))
                    }
                    isRequired
                  />
                </Row>
                {iqCamera && action === 'edit' && (
                  <>
                    <Row>
                      <FormSelect
                        name="cameraSensitivity"
                        label={
                          <Space>
                            {t('Devices.ActualForm.CameraSensitivity')}
                            <Tooltip
                              overlayInnerStyle={{
                                width: 500
                              }}
                              title={
                                <OrderedTextRenderer
                                  text={[
                                    t('Devices.ActualForm.CameraSensitivityTooltip.part1'),
                                    t('Devices.ActualForm.CameraSensitivityTooltip.part2'),
                                    t('Devices.ActualForm.CameraSensitivityTooltip.part3'),
                                    t('Devices.ActualForm.CameraSensitivityTooltip.part4'),
                                    [
                                      t('Devices.ActualForm.CameraSensitivityTooltip.light'),
                                      t('Devices.ActualForm.CameraSensitivityTooltip.medium'),
                                      t('Devices.ActualForm.CameraSensitivityTooltip.heavy')
                                    ],
                                    t('Devices.ActualForm.CameraSensitivityTooltip.part5')
                                  ]}
                                />
                              }
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </Space>
                        }
                        supressSorting={true}
                        values={IQCameraDeviceConfig.Sensitivities.map(opt => ({
                          ...opt,
                          label: t(opt.label)
                        }))}
                      />
                      <FormSelect
                        name="volume"
                        label={t('Devices.ActualForm.DeviceVolumeSetting')}
                        supressSorting={true}
                        values={IQCameraDeviceConfig.Volumes}
                      />
                    </Row>
                    <Row>
                      <AudioAlertsFormField t={t} name="audioAlertsEnabled" />
                      <FormSelect
                        name="enableDriverCamera"
                        label={t('Devices.ActualForm.DriverCamera')}
                        values={[
                          {
                            label: t('Common.On'),
                            value: 'true'
                          },
                          {
                            label: t('Common.Off'),
                            value: 'false'
                          }
                        ]}
                      />
                    </Row>
                  </>
                )}
                <SqueezableColsLayout
                  fields={[
                    {
                      Comp: FormInput,
                      props: {
                        name: 'sim',
                        label: t('Devices.ActualForm.SimLabel'),
                        placeholder: t('Devices.ActualForm.SimPlaceholder'),
                        isValidated: true
                      }
                    },
                    {
                      Comp: PhoneInputField,
                      props: {
                        preferredCountryCodes: [localization.region, currentCompany.country],
                        initialValue: phoneNumber,
                        label: t('Devices.ActualForm.PhoneLabel'),
                        onChange: handlePhoneNumberChange,
                        countryCodeEditable: action === 'edit'
                      }
                    },
                    {
                      Comp: FormSelect,
                      props: {
                        name: 'vehicle',
                        label: t('Devices.ActualForm.VehicleLabel'),
                        placeholder: t('Devices.ActualForm.VehiclePlaceholder'),
                        onChange: id => onVehicleChange(vehicles, id, setFieldValue),
                        isDisabled:
                          !canDissociateDevice ||
                          (transferData.deviceOnly === false &&
                            transferData.companyId !== deviceData.companyId),
                        values: vehicles.map(vehicle => ({
                          label: vehicle.name,
                          value: vehicle.id
                        }))
                      }
                    },
                    {
                      Comp: FormInput,
                      props: {
                        name: 'externalId',
                        label: t('Devices.ActualForm.ExternalIDLabel'),
                        placeholder: t('Devices.ActualForm.ExternalIDPlaceholder'),
                        isValidated: true
                      }
                    },
                    {
                      Comp: FormDatePicker,
                      props: {
                        name: 'inServiceAt',
                        label: t('Devices.ActualForm.InServiceAtLabel'),
                        placeholder: t('Devices.ActualForm.InServiceAtPlaceHolder'),
                        setFieldValue: setFieldValue,
                        timePicker: true,
                        format: localization.formats.time.formats.dby_imp
                      },
                      hidden: !can({ featureFlag: FeatureFlag.inOutServiceDate.flag })
                    },
                    {
                      Comp: FormDatePicker,
                      props: {
                        name: 'outServiceAt',
                        label: t('Devices.ActualForm.OutServiceAtLabel'),
                        placeholder: t('Devices.ActualForm.OutServiceAtPlaceHolder'),
                        setFieldValue: setFieldValue,
                        timePicker: true,
                        format: localization.formats.time.formats.dby_imp
                      },
                      hidden: !can({ featureFlag: FeatureFlag.inOutServiceDate.flag })
                    },
                    {
                      Comp: FormSelect,
                      squeezeToColWhen: () => canControlGpioConfiguration,
                      hidden:
                        !deviceEdit.services?.includes(services.GPIO) ||
                        !canControlGpioConfiguration ||
                        action !== 'edit' ||
                        types
                          .find(type => type.id === parseInt(selectedType))
                          ?.name?.toUpperCase() !== 'HERMES',
                      props: {
                        name: 'gpioTemplateId',
                        label: t('Devices.ActualForm.GpioConfig'),
                        placeholder: t('Devices.ActualForm.GpioConfigPlaceHolder'),
                        onChange: id => onGpioConfigurationTemplateChange(id, setFieldValue),
                        values: gpioConfigurationTemplates.map(template => ({
                          label: template.name,
                          value: template.id
                        }))
                      }
                    },
                    {
                      Comp: FormInput,
                      squeezeToColWhen: () => canControlGpioConfiguration,
                      props: {
                        name: 'note',
                        label: t('Devices.ActualForm.NotesLabel'),
                        rows: canShowAudioAlert ? '5' : '9',
                        placeholder: t('Devices.ActualForm.NotesPlaceholder'),
                        as: 'textarea'
                      }
                    },
                    {
                      Comp: SearchableListMultiSelect,
                      squeezeToColWhen: () => canControlGpioConfiguration,
                      props: {
                        name: 'fleet',
                        label: t('Devices.ActualForm.FleetLabel'),
                        placeholder: t('Devices.ActualForm.FleetPlaceholder'),
                        allLabel: t('Devices.AllFleets'),
                        initialValues: deviceData.fleets,
                        values: fleets,
                        setFieldValue: setUserCheckedFleets,
                        height: 250,
                        isDisabled: disableMultiSelect || !canDissociateDevice,
                        disabledMessage: t('Devices.ActualForm.FleetDisabledMessage'),
                        isLoading: !fleetsLoaded,
                        onCheckboxChanged: setIsFormDirty
                      }
                    }
                  ]}
                />
              </div>
              <div className={styles.formFooter}>
                <Button
                  type="primary"
                  size="large"
                  disabled={(!dirty && !isFormDirty) || !isValid || isSubmitting}
                  htmlType="submit"
                  id={BUTTON_IDS.deviceFormSave}
                  onClick={e => preSubmitMethod(e, handleSubmit)}
                >
                  {t('Common.SaveButton')}
                </Button>
                <Button size="large" id={BUTTON_IDS.deviceFormCancel} onClick={history.goBack}>
                  {t('Common.CancelButton')}
                </Button>
              </div>
            </Form>
            <DeviceTransferModal
              device={deviceData}
              vehicle={selectedVehicle}
              isOpen={showDeviceTransferModal}
              transferData={transferData}
              onComplete={e => {
                setTransferData(e);
                onCompanyChange(e.companyId, setFieldValue, e);
                setShowDeviceTransferModal(false);
              }}
              onClose={() => {
                setTransferData(prev => ({
                  ...prev,
                  targetCompanyId: undefined
                }));
                setShowDeviceTransferModal(false);
              }}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DeviceForm;

const AudioAlertsFormField = ({ t, name }) => {
  const [field, meta, helper] = useField({ name });
  const { options, isAllSelected, onToggleSelectAll, maxTagPlaceholder } = useMemo(() => {
    const options = sortBy(
      Object.values(IQCameraDeviceConfig.AudioAlertTypes).map(opt => ({
        value: opt.value,
        label: t(`Devices.View.CameraAudioAlert.${opt.label}`)
      })),
      'label'
    );
    const isAllSelected = selected => options.every(opt => selected?.includes(opt.value));
    return {
      options,
      isAllSelected,
      onToggleSelectAll: values => {
        const selectedAll = isAllSelected(values);
        const perservedValues = values.filter(v => !IQCameraDeviceConfig.AudioAlertTypes[v]);
        helper.setValue(
          selectedAll ? perservedValues : [...perservedValues, ...options.map(opt => opt.value)],
          true
        );
      },
      maxTagPlaceholder: omittedValues => (
        <span>{`+${
          omittedValues.filter(({ value }) => options.some(opt => opt.value === value)).length
        }...`}</span>
      )
    };
  }, [t]);

  return (
    <FormGroup as={Col}>
      <FormLabel className={styles.requiredLabel}>
        {t('Devices.ActualForm.CameraAudioAlerts')}
      </FormLabel>
      <Select
        placeholder={t('Devices.ActualForm.CameraAudioAlertsPlaceholder')}
        status={meta.error ? 'error' : ''}
        className={styles.multiSelect}
        maxTagCount={'responsive'}
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        tagRender={({ label, ...props }) =>
          IQCameraDeviceConfig.AudioAlertTypes[props.value] ? (
            <Tag {...props} style={{ marginRight: 3, color: '#000000e0', borderColor: '#dadee3' }}>
              {label}
            </Tag>
          ) : null
        }
        maxTagPlaceholder={maxTagPlaceholder}
        value={field.value}
        options={options}
        onChange={v => helper.setValue(v, true)}
        dropdownRender={menu => (
          <div>
            <Button
              type="text"
              onClick={() => onToggleSelectAll(field.value)}
              className={styles.selectAll}
            >
              {`${isAllSelected(field.value) ? t('Home.UnselectAll') : t('Home.SelectAll')} ${t(
                'Home.Event Types'
              )}`}
            </Button>
            <hr style={{ margin: '2px 0' }} />
            {menu}
          </div>
        )}
      />
      {meta.error && <div className={styles.errorMsg}>{meta.error}</div>}
    </FormGroup>
  );
};

const SqueezableColsLayout = ({ fields = [], colNum = 2 }) => {
  const visibleRows = useMemo(() => {
    let lastIndex = {
        col: -1,
        row: 0
      },
      inFlexColMode = false;
    return fields
      ?.filter(f => !f.hidden)
      .reduce((rows, field, index, visibleFields) => {
        const squeezeToCol = field.squeezeToColWhen && field.squeezeToColWhen(visibleFields),
          expand = () => {
            if (lastIndex.col === colNum - 1) {
              lastIndex = {
                row: lastIndex.row + 1,
                col: 0
              };
            } else {
              lastIndex = {
                row: lastIndex.row,
                col: lastIndex.col + 1
              };
            }
          };
        if (squeezeToCol) {
          if (!inFlexColMode) {
            expand();
          }
          inFlexColMode = true;
        } else {
          inFlexColMode = false;
          expand();
        }
        rows[lastIndex.row] = rows[lastIndex.row] || [];
        rows[lastIndex.row][lastIndex.col] = rows[lastIndex.row][lastIndex.col] || [];
        rows[lastIndex.row][lastIndex.col].push(field);
        return rows;
      }, []);
  }, [fields, colNum]);

  return visibleRows.map((cols, rowIndex) => (
    <Row key={`form-row-${rowIndex}`}>
      {cols.map((col, colIndex) => (
        <React.Fragment key={`form-col-${rowIndex}-${colIndex}`}>
          {col.length > 1 ? (
            <Col className={styles.columnPanel}>
              {col.map((field, fieldIndex) => {
                const Field = field.Comp;
                return <Field key={`form-field-${rowIndex}-${fieldIndex}`} {...field.props} />;
              })}
            </Col>
          ) : (
            col.map((field, fieldIndex) => {
              const Field = field.Comp;
              return <Field key={`form-field-${rowIndex}-${fieldIndex}`} {...field.props} />;
            })
          )}
        </React.Fragment>
      ))}
    </Row>
  ));
};
