import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Space } from 'antd';
import { Buttons } from 'components/ant';
import { BUTTON_IDS } from 'utils/globalConstants';
import { trigger, CustomEvents } from 'utils/hooks/useCustomEventListener';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import {
  getRouteGuardProps,
  getSelectedRows,
  toBulkEditTableData,
  toBulkUpdatePayload
} from './helper';
import { format } from 'utils/dates';
import { useCan, entities, Can, FeatureFlag } from 'features/permissions';
import { useLocalization } from 'features/localization/localizationSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  bulkDeleteDevices,
  bulkUpdateDevices,
  clearBulkEditError,
  useIsBulkEditing,
  swithBulkEditMode,
  useIsBulkAgreementAssignment,
  switchBulkAgreementAssignmentMode
} from 'features/devices/devicesSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { PATHS } from '../constants';

import { fetchBulkEditAudits } from 'features/audits';
import { BULK_EDIT_AUDIT_ENTITY } from 'components/auditsTable/BulkEditAudits';
import GlobalRoles from 'features/permissions/GlobalRoles';

export const useDevicesBulkEdit = ({ devices, isLoading, handleDeleteAction, filterTab }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const can = useCan();
  const canBulkEdit = useMemo(
    () =>
      can({
        oneOfEntities: [entities.DEVICE_UPDATE, entities.DEVICE_DESTROY]
      }) && filterTab !== 'deleted',
    [filterTab]
  );
  const localization = useLocalization();
  const getDateTime = useCallback(date => format(date, localization.formats.time.formats.dby_imp), [
    localization
  ]);

  const isBulkAssigningAgreement = useIsBulkAgreementAssignment();
  const isBulkEditing = useIsBulkEditing() || isBulkAssigningAgreement;
  const [touchedRowsMap, setTouchedRowsMap] = useState({});

  const { tableData, onTableRowChecked } = useMemo(() => {
    const tableData = toBulkEditTableData(devices);
    return {
      tableData,
      onTableRowChecked: deviceId => e => {
        const checked = e.target.checked;
        if (deviceId) {
          setTouchedRowsMap(records => ({
            ...records,
            [deviceId]: {
              ...tableData.find(d => String(d.id) === String(deviceId)),
              ...records[deviceId],
              checked
            }
          }));
        } else {
          setTouchedRowsMap(records =>
            tableData.reduce(
              (a, d) => ({ ...a, [d.id]: { ...d, ...records?.[d.id], checked } }),
              {}
            )
          );
        }
      }
    };
  }, [devices]);

  useEffect(() => {
    setTouchedRowsMap({});
  }, [devices]);

  const startBulkEdit = useCallback(() => {
    //auto close side nav
    trigger(CustomEvents.autoCloseSidebar);
    dispatch(swithBulkEditMode(true));
  }, [dispatch]);

  const startBulkAgreementAssignment = useCallback(() => {
    //auto close side nav
    trigger(CustomEvents.autoCloseSidebar);
    dispatch(switchBulkAgreementAssignmentMode(true));
  }, [dispatch]);

  const exitBulkEdit = useCallback(
    (afterExit = () => {}) => {
      const isTableTouched = !!getSelectedRows(touchedRowsMap).length;
      if (isTableTouched) {
        const confirmModalProps = getRouteGuardProps(t);
        confirmationModal(
          confirmModalProps.title,
          confirmModalProps.message,
          confirmModalProps.leaveBtnText,
          confirmModalProps.stayBtnText,
          () => {
            if (afterExit) {
              afterExit();
            }
            setTouchedRowsMap({});
            dispatch(clearBulkEditError());
          },
          'warning'
        );
      } else {
        if (afterExit) {
          afterExit();
        }
        setTouchedRowsMap({});
        dispatch(clearBulkEditError());
      }
    },
    [dispatch, touchedRowsMap]
  );

  useEffect(() => {
    if (isBulkEditing) {
      dispatch(
        setPageTitle(
          <Space size={4}>
            <Button
              type="text"
              onClick={() => exitBulkEdit()}
              icon={<i className="tn-i-chevron-left"></i>}
              style={{ fontSize: '24px' }}
            />
            {t(isBulkAssigningAgreement ? 'Common.BulkAssignAgreement' : 'Common.BulkEdit')}
          </Space>
        )
      );
    } else {
      dispatch(setBackButton(false));
      dispatch(setPageTitle(t('Devices.Devices')));
    }
  }, [dispatch, t, isBulkEditing, exitBulkEdit]);

  const BtnsWithBulkEdit = useCallback(
    ({ children }) => {
      if (isBulkEditing) {
        return null;
      }
      return (
        <Space size={16}>
          {canBulkEdit ? (
            <>
              {children[0]}
              <Can
                everyEntity={[entities.DEVICE_UPDATE]}
                featureFlag={FeatureFlag.subCompanyManagement.flag}
                oneOfRoles={[GlobalRoles.SubCompanyAdmin]}
              >
                <Button
                  type="default"
                  size="large"
                  disabled={isLoading}
                  onClick={startBulkAgreementAssignment}
                >
                  {t('Devices.BulkEdit.AssignAgreements')}
                </Button>
              </Can>
              <Buttons
                preventClose
                id={BUTTON_IDS.devicesBulkEdit}
                primaryButton={{
                  name: t('Common.BulkEdit'),
                  onClick: startBulkEdit,
                  id: BUTTON_IDS.devicesBulkEdit,
                  type: 'default',
                  disabled: isLoading
                }}
                moreButtons={[
                  ...children.slice(1).map(child => ({
                    ...child.props,
                    name: child.props.children
                  })),
                  {
                    name: t('Devices.BulkEdit.Audits'),
                    onClick: () => {
                      history.push(PATHS.AUDIT_BULKEDIT);
                    },
                    canPermissions: {
                      everyEntity: [entities.DEVICE_AUDIT]
                    },
                    id: BUTTON_IDS.devicesBulkEditAudits
                  }
                ]}
              />
            </>
          ) : (
            children
          )}
        </Space>
      );
    },
    [t, canBulkEdit, isBulkEditing, isLoading]
  );

  const onBulkDelete = useCallback(
    (devices = []) => {
      confirmationModal(
        t('Common.Modal.SureTitle'),
        t('Common.Modal.SureQuestion', {
          name: `${devices.length} ${
            devices.length > 1 ? t('Devices.Devices') : t('Devices.Device')
          }`
        }),
        t('Common.DeleteButton'),
        t('Common.CancelButton'),
        () => {
          dispatch(
            bulkDeleteDevices({
              devices: devices.map(d => ({ id: d.id })),
              onDeleted: ({ successCount, failedCount }) => {
                const allSuccess = successCount === devices.length;
                const allFailed = !successCount;
                dispatch(
                  openToast({
                    type: allSuccess
                      ? ToastType.Success
                      : allFailed
                      ? ToastType.Error
                      : ToastType.Warning,
                    message: allSuccess
                      ? t('Devices.BulkEdit.Notifications.DeleteSuccess')
                      : allFailed
                      ? t('Devices.BulkEdit.Notifications.DeleteFailed')
                      : t('Devices.BulkEdit.Notifications.PartialDeleteSuccess', {
                          successCount,
                          failedCount
                        })
                  })
                );
                setTouchedRowsMap({});
                if (!allFailed) {
                  dispatch(fetchBulkEditAudits({ entityName: BULK_EDIT_AUDIT_ENTITY.DEVICE }));
                }
              }
            })
          );
        },
        'delete'
      );
    },
    [t, dispatch]
  );

  const onBulkAssignment = useCallback(
    data => {
      setTouchedRowsMap({});
      dispatch(clearBulkEditError());
      history.push(`/settings/devices/agreement`, {
        devices: data.map(i => {
          return {
            id: i.id
          };
        })
      });
    },
    [t, dispatch]
  );

  const onBulkSave = useCallback(
    (
      entity,
      entityValue,
      selectedRows,
      callback = ({ allSuccess, allFailed, devices, entity, entityValue }) => {}
    ) => {
      const devices = toBulkUpdatePayload(entity, entityValue, selectedRows, { getDateTime });
      if (!!devices.length) {
        const count = devices.length;
        confirmationModal(
          t('Common.Modal.SureTitle'),
          t('CompanyConfig.IQCamera.BULK_EDIT.SureBulkEdit', {
            count,
            entity: count > 1 ? t('Devices.Devices') : t('Devices.Device')
          }),
          t('Common.Modal.Confirm'),
          t('Common.Modal.Cancel'),
          () => {
            dispatch(
              bulkUpdateDevices({
                devices,
                onUpdated: ({ successCount, failedCount }) => {
                  const allSuccess = successCount === devices.length;
                  const allFailed = !successCount;
                  dispatch(
                    openToast({
                      type: allSuccess
                        ? ToastType.Success
                        : allFailed
                        ? ToastType.Error
                        : ToastType.Warning,
                      message: allSuccess
                        ? t('Devices.BulkEdit.Notifications.UpdateSuccess')
                        : allFailed
                        ? t('Devices.BulkEdit.Notifications.UpdateFailed')
                        : t('Devices.BulkEdit.Notifications.PartialUpdateSuccess', {
                            successCount,
                            failedCount
                          })
                    })
                  );
                  setTouchedRowsMap({});
                  if (!allFailed) {
                    dispatch(fetchBulkEditAudits({ entityName: BULK_EDIT_AUDIT_ENTITY.DEVICE }));
                  }
                  if (callback) {
                    callback({ allSuccess, allFailed, devices, entity, entityValue });
                  }
                }
              })
            );
          },
          'warning'
        );
      }
    },
    [t, dispatch, getDateTime]
  );

  return {
    isBulkEditing,
    isBulkAssigningAgreement,
    BtnsWithBulkEdit,
    tableProps: {
      tableData,
      touchedRowsMap,
      onTableRowChecked,
      onBulkSave,
      onBulkAssignment,
      onBulkDelete,
      onIndividualDelete: handleDeleteAction,
      exitBulkEdit
    }
  };
};
