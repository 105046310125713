import React, { useState, useEffect } from 'react';
import { Drawer, Form, Tabs } from 'antd';
import styles from '../Devices.module.scss';
import {
  useGetSpeedAssistDeviceSelectIdQuery,
  useGetSpeedAssistValuesQuery
} from 'services/nextgen/ngSpeedAssistConfigurationApi';
import {
  useGetAssetDeviceSelectIdQuery,
  useGetAssetValuesQuery
} from 'services/nextgen/ngAssetConfigurationApi';
import { useTranslation } from 'react-i18next';
import { useCurrentCompany } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { SpeedAssist } from '../Configurations/SpeedAssist';
import { Assets } from '../Configurations/Assets';

export const Configure = ({
  open,
  onClose,
  size,
  width,
  data,
  speedAssistAvailable,
  assetsAvailable
}) => {
  const [mode, setMode] = useState('left');
  const [activeTab, setActiveTab] = useState('1');
  const currentCompany = useCurrentCompany();
  const localization = useLocalization();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [initialValuesAssets, setInitialValuesAssets] = useState({});

  const { data: selectedId, refetch: refetchSelectId } = useGetSpeedAssistDeviceSelectIdQuery(
    { companyId: currentCompany?.id, deviceId: data.id },
    { skip: !open || currentCompany?.id === undefined }
  );

  const { data: selectedIdAssets, refetch: refetchSelectIdAssets } = useGetAssetDeviceSelectIdQuery(
    { companyId: currentCompany?.id, deviceId: data.id },
    { skip: !open || currentCompany?.id === undefined }
  );

  const {
    data: speedAssistValues,
    refetch: refetchSpeedAssistValues
  } = useGetSpeedAssistValuesQuery({ deviceId: data.id }, { skip: !open });

  const { data: assetsValues, refetch: refetchAssetsValues } = useGetAssetValuesQuery(
    { deviceId: data.id },
    { skip: !open }
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      refetchSelectId();
      refetchSpeedAssistValues();
    }
  }, [open, refetchSelectId, refetchSpeedAssistValues]);

  useEffect(() => {
    if (open) {
      refetchSelectIdAssets();
      refetchSpeedAssistValues();
    }
  }, [open, refetchSelectIdAssets, refetchAssetsValues]);

  useEffect(() => {
    if (open && speedAssistValues && speedAssistAvailable) {
      const updatedValues = {
        speedassistTemplateId: selectedId?.id || '',
        maxThreshold: localization.convertSpeed(
          speedAssistValues?.find(config => config.key === 'company.max.threshold')?.value || 0
        ),
        maxOffset: localization.convertSpeed(
          speedAssistValues?.find(config => config.key === 'company.max.offset')?.value || 0
        ),
        maxDuration:
          speedAssistValues?.find(config => config.key === 'company.max.duration')?.value || '',
        signpostedDisable:
          speedAssistValues?.find(config => config.key === 'signposted.disable')?.value ===
            'true' || false,
        companyManaged:
          speedAssistValues?.find(config => config.key === 'company.managed')?.value === 'true' ||
          false
      };

      setInitialValues(updatedValues);
      form.setFieldsValue({
        ...updatedValues,
        'company.max.threshold': updatedValues.maxThreshold,
        'company.max.offset': updatedValues.maxOffset,
        'company.max.duration': updatedValues.maxDuration,
        'signposted.disable': updatedValues.signpostedDisable,
        'company.managed': updatedValues.companyManaged
      });
    }
  }, [open, speedAssistValues, selectedId, speedAssistAvailable]);

  useEffect(() => {
    if (open && assetsValues && assetsAvailable) {
      const updatedValues = {
        assetsTemplateId: selectedId?.id || '',
        radioSelected:
          assetsValues?.find(config => config.key === 'asset.selection')?.value || 'movement',
        inUse: assetsValues?.find(config => config.key === 'movement.inuse')?.value || false,
        gpioChannel: assetsValues?.find(config => config.key === 'gpio.channel')?.value || '',
        gpioStatus:
          assetsValues?.find(config => config.key === 'gpio.status')?.value === 'true' || '',
        rpmValue: assetsValues?.find(config => config.key === 'rpm.over')?.value === 'true' || 0,
        rpmSeconds: assetsValues?.find(config => config.key === 'rpm.min')?.value === 'true' || 0
      };

      setInitialValues(updatedValues);
      form.setFieldsValue({
        ...updatedValues,
        'asset.selection': updatedValues.radioSelected,
        'movement.inuse': updatedValues.inUse,
        'gpio.channel': updatedValues.gpioChannel,
        'gpio.status': updatedValues.gpioStatus,
        'rpm.over': updatedValues.rpmValue,
        'rpm.min': updatedValues.rpmSeconds
      });
    }
  }, [open, assetsValues, selectedIdAssets, assetsAvailable]);

  const handleTabChange = key => {
    setActiveTab(key);
  };

  return (
    <Drawer
      width={width}
      placement="right"
      onClose={onClose}
      open={open}
      size={size}
      bodyStyle={{ padding: '8px' }}
      headerStyle={{
        borderBottom: 'none',
        padding: '0px',
        flexDirection: 'column',
        marginTop: '15px',
        alignItems: 'end'
      }}
    >
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          position: 'absolute',
          top: '12px',
          left: '10px'
        }}
      >
        {data.name + ' ' + t('Devices.DeviceConfigurations.Configurations')}
      </div>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flex: '1' }}>
          <Tabs
            tabPosition={mode}
            onChange={handleTabChange}
            className={styles.configureTabs}
            moreIcon={null}
            activeKey={activeTab}
          >
            {speedAssistAvailable && (
              <Tabs.TabPane
                tab={
                  <div
                    style={{
                      fontSize: '16px',
                      textShadow: 'none',
                      color: 'black',
                      fontWeight: activeTab === '1' ? 'bold' : 'normal'
                    }}
                  >
                    Speed Assist
                  </div>
                }
                key="1"
              ></Tabs.TabPane>
            )}
            {/* {assetsAvailable && (
              <Tabs.TabPane
                tab={
                  <div
                    style={{
                      fontSize: '16px',
                      textShadow: 'none',
                      color: 'black',
                      fontWeight: activeTab === '2' ? 'bold' : 'normal'
                    }}
                  >
                    Assets
                  </div>
                }
                key="2"
              ></Tabs.TabPane>
            )} */}
          </Tabs>
        </div>
        <div
          style={{
            flex: '3',
            padding: '5px 8px 0px 8px',
            overflowY: 'auto',
            marginTop: '10px',
            marginRight: '13px'
          }}
          className={styles.form}
        >
          <>
            {speedAssistAvailable && activeTab === '1' && (
              <>
                <div className={styles.formHeader}>
                  {t('CompanyConfig.DeviceConfigurations.SPEEDASSISTConfig.Title')}
                </div>
                <div className={styles.formWrapper}>
                  <SpeedAssist
                    data={data}
                    onClose={onClose}
                    initialValues={initialValues}
                    selectedId={selectedId}
                  />
                </div>
              </>
            )}
            {/* {assetsAvailable && activeTab === '2' && (
              <>
                <div className={styles.formHeader}>
                  {t('CompanyConfig.DeviceConfigurations.AssetsConfig.Title')}
                </div>
                <div className={styles.formWrapper}>
                  <Assets
                    data={data}
                    onClose={onClose}
                    initialValues={initialValuesAssets}
                    selectedId={selectedIdAssets}
                  />
                </div>
              </>
            )} */}
          </>
        </div>
      </div>
    </Drawer>
  );
};
