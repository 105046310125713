import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import cn from 'classnames';
import {
  ColumnKeys,
  Columns,
  columnWidth,
  EventTypes,
  Paths,
  TabKeys,
  xlsRows,
  EmptyRow,
  ScorecardEventType,
  GEOFENCE_EXCLUDE_PREFIX
} from './constants';
import iconArrowDown from 'static/images/icons/event-type-down-icon.svg';
import iconArrowUp from 'static/images/icons/event-type-up-icon.svg';
import iconSame from 'static/images/icons/event-type-same-icon.svg';
import { StarFilled } from '@ant-design/icons';
import { Tooltip } from 'components/ant';
import { getRoundValue } from 'utils/methods';
import { hasCharacters, toTitleCase } from 'utils/strings';
import XLSX from 'xlsx';
import { filterUsersByBranches, filterVehiclesByFleets } from './utils';

export const disabledDate = current => {
  // Can not select days after today or farther than 30 days from the start date
  const isDateToSelected = document.querySelector(
    '.ant-picker-input:first-child.ant-picker-input-active'
  );
  const inputValue = document.querySelector('.ant-picker-input:first-child input')?.value;
  const dateFrom = inputValue ? moment(inputValue) : moment();
  if (isDateToSelected) {
    return current && current > moment().endOf('day');
  }
  return current && (current > moment().endOf('day') || current > dateFrom.add(30, 'day'));
};

export const toggleAllEntities = (
  companyId,
  allEntities,
  setFilteredEntities,
  labelAll,
  labelNo
) => {
  const newFilteredEntities = [
    { id: 0, label: labelAll, checked: true },
    ...allEntities
      .filter(entity => (entity.companyId || entity.company?.id) === companyId)
      .map(entity => ({ id: entity.id, label: entity.name, checked: true }))
  ];
  if (labelNo) {
    const noEntityIndex = newFilteredEntities.findIndex(entity => entity.id === -1);
    if (noEntityIndex > -1) {
      newFilteredEntities[noEntityIndex] = {
        ...newFilteredEntities[noEntityIndex],
        label: labelNo,
        checked: true
      };
    } else {
      newFilteredEntities.push({ id: -1, label: labelNo, checked: true });
    }
  }
  setFilteredEntities(newFilteredEntities);
};

export const toggleEntity = (setFilteredEntities, newEntities) => {
  setFilteredEntities(newEntities);
};

export const setNewValues = ({ values, buckets, setValues, localization }) => {
  if (buckets.length > 0) {
    const newValues = JSON.parse(JSON.stringify(values));
    buckets.forEach(bucket => {
      newValues[bucket.type] = bucket.values;
    });
    setValues(newValues);
  } else {
    setValues(prepareEventsForValues(EventTypes(localization)));
  }
};

export const resetEnabledEventTypes = enabledEventTypes => {
  enabledEventTypes.forEach(event => {
    event.enabled = true;
  });
};

export const formatDateDifference = (dateFrom, dateTo, t, localization) => {
  const momentDateFrom = moment(dateFrom);
  const momentDateTo = moment(dateTo);
  const formatDate = localization.formats.time.formats.dby.toUpperCase();
  const nrOfDays = momentDateTo.diff(momentDateFrom, 'days') + 1;

  if (
    moment()
      .endOf('day')
      .diff(momentDateTo, 'days') === 0
  ) {
    if (nrOfDays === 1) {
      return t('Scorecard.Last1Day');
    }
    return t('Scorecard.LastDays', { days: nrOfDays });
  } else {
    return `${momentDateFrom.format(formatDate)} - ${momentDateTo.format(
      formatDate
    )}, (${nrOfDays} ${nrOfDays > 1 ? t('Common.days') : t('Common.day')})`;
  }
};

export const prepareEventsForValues = EventTypes => {
  const values = {};
  Object.values(EventTypes).forEach(event => {
    values[event.key] = new Array(event.buckets.length).fill(0);
  });

  return values;
};

export const confirmationModalMetricScoring = (confirmationModal, callback, entity, dirty, t) => {
  if (dirty) {
    confirmationModal(
      t('Roles.ConfirmationModal.AreYouSure'),
      `${t('Scorecard.ValuesChanged', { entity })}`,
      t('Common.Modal.OK'),
      t('Common.CancelButton'),
      callback,
      'delete'
    );
  } else {
    callback();
  }
};

export const filterEntities = (allUsers, allVehicles, companyId, branches, fleets) => {
  // Users
  const allUsersInCurrentCompany = allUsers.filter(user => user.companyId === companyId);
  const allVehiclesInCurrentCompany = allVehicles.filter(
    vehicle => vehicle.companyId === companyId
  );
  const isAllBranchesChecked = branches.find(branch => branch.id === 0)?.checked;
  const users = isAllBranchesChecked
    ? allUsersInCurrentCompany
    : allUsersInCurrentCompany.filter(user => filterUsersByBranches(user, companyId, branches));

  // Vehicles
  const isAllFleetsChecked = fleets.find(fleet => fleet.id === 0)?.checked;
  const vehicles = isAllFleetsChecked
    ? allVehiclesInCurrentCompany
    : allVehiclesInCurrentCompany.filter(vehicle =>
        filterVehiclesByFleets(vehicle, companyId, fleets)
      );

  return { users, vehicles };
};

export const getScorecardEventsFromFeatures = features => {
  const scorecardEvents = features?.filter(item => item.startsWith(GEOFENCE_EXCLUDE_PREFIX)) || [];
  return scorecardEvents;
};

export const getNonScorecardEventsFromFeatures = features => {
  const nonScorecardEvents =
    features?.filter(item => !item.startsWith(GEOFENCE_EXCLUDE_PREFIX)) || [];
  return nonScorecardEvents;
};

export const getEventTypesListForSelect = (enabledEventTypes, localization, t) => {
  const eventTypes = Object.values(EventTypes(localization));
  if (eventTypes?.length > 0 && enabledEventTypes?.length > 0) {
    const eventTypesForSelect = eventTypes.map(eventType => {
      const eventEnabled = enabledEventTypes?.find(event => event.type === eventType.key)?.enabled;
      if (eventEnabled) {
        return {
          id: `${GEOFENCE_EXCLUDE_PREFIX}${eventType.key}`,
          name: t(eventType.name)
        };
      }
      return null;
    });

    //filter ewd events since they are not location based
    return eventTypesForSelect
      .filter(item => item)
      .filter(item => item.id !== 'SC_FATIGUE_UNDER_REST' && item.id !== 'SC_FATIGUE_OVER_WORKED');
  }
  return;
};

export const getSelectedEventTypeNames = (features, localization, t) => {
  const eventTypes = EventTypes(localization);

  const selectedEventTypes =
    features?.filter(item => item?.startsWith(GEOFENCE_EXCLUDE_PREFIX)) || [];

  const selectedEventNames = selectedEventTypes
    .map(item => {
      const eventType = eventTypes[item.substring(3)];
      return eventType ? (t ? t(eventType.name) : eventType.englishTitle) : null;
    })
    .sort((a, b) => a?.localeCompare(b));

  return selectedEventNames;
};

// Filter which event types are enbaled based on the config data coming from the API
export const getEnabledEventTypes = (eventTypes, eventTypesConfig) => {
  let enabledEventTypes = eventTypes;

  if (eventTypesConfig) {
    enabledEventTypes = enabledEventTypes.filter(eventType => {
      const found = eventTypesConfig.find(
        eventTypeConfig => eventTypeConfig.type === eventType.key
      );
      return found ? found.enabled : false;
    });
  } else {
    // If we don't have which event types are enabled yet, return blank array so we don't render yet
    enabledEventTypes = [];
  }

  return enabledEventTypes;
};

// Filter which event types are enbaled based on the config data coming from the API
export const getEnabledEventTypesForMetricScoring = (eventTypes, buckets) => {
  let enabledEventTypes = eventTypes;

  if (buckets && buckets.length > 0) {
    // Filter by "hidden" first, then add "enabled" to the remaining events
    enabledEventTypes = enabledEventTypes
      .filter(eventType => {
        const found = buckets.find(bucket => bucket.type === eventType.key);
        return found ? !found.hidden : true;
      })
      .map(eventType => {
        const found = buckets.find(bucket => bucket.type === eventType.key);
        eventType.enabled = found ? found.enabled : true;
        return eventType;
      });
  } else {
    // If we don't have bucket config yet, filter seatbelt manually for now so it doesn't accidentally show while loading
    // Need to think of a better solution for this
    enabledEventTypes = enabledEventTypes.filter(
      eventType => eventType.key !== ScorecardEventType.SEAT_BELT_NON_USAGE
    );
  }

  return enabledEventTypes;
};

// Filter which columns are enbaled based on the config data coming from the API
const filterColumnsByEnabledEventTypes = (columns, eventTypesConfig) => {
  let enabledColumns = columns;

  if (eventTypesConfig && eventTypesConfig.length > 0) {
    enabledColumns = enabledColumns.filter(column => {
      let found;
      if (column.eventType) {
        found = eventTypesConfig.find(eventTypeConfig => eventTypeConfig.type === column.eventType);
      }
      const isEventEnabled = found ? found.enabled : true;
      const isColEnabled = column.isEnabled !== undefined ? column.isEnabled : true;

      return isColEnabled && isEventEnabled;
    });
  } else {
    // If we don't have which event types are enabled yet, return blank array so we don't render yet
    enabledColumns = [];
  }

  return enabledColumns;
};

export const prepareColumnsForTable = ({
  average,
  style,
  t,
  localization,
  dateFrom,
  dateTo,
  tab,
  selectedCompany,
  VPMSupport,
  eventTypesConfig
}) => {
  // Filter which event type columns are shown based on config coming from API
  const columns = filterColumnsByEnabledEventTypes(Columns(t, localization, tab), eventTypesConfig);

  return columns
    .filter(column => VPMSupport || (!VPMSupport && column.key !== ColumnKeys.FUEL_USE))
    .map(column => {
      return {
        ...column,
        title: column.title || t(`Scorecard.${column.key}`),
        sorter:
          column.sortable &&
          ((a, b) =>
            column.isNumber || column.isBoolean
              ? a[column.key] - b[column.key]
              : a[column.key].localeCompare(b[column.key])),
        render: column.isNumber
          ? (text, record) => {
              let trendsIcon = null;
              if (column.showTrends) {
                const currentValue = record[column.key];
                const previousValue = record[`${column.key}ForTrends`];
                const positiveTrendIcon =
                  column.key !== ColumnKeys.RANK && column.key !== ColumnKeys.GROUP_RANK
                    ? iconArrowDown
                    : iconArrowUp;
                const negativeTrendIcon =
                  column.key !== ColumnKeys.RANK && column.key !== ColumnKeys.GROUP_RANK
                    ? iconArrowUp
                    : iconArrowDown;
                const icon =
                  currentValue > previousValue
                    ? positiveTrendIcon
                    : currentValue === previousValue || previousValue === 'NA'
                    ? iconSame
                    : negativeTrendIcon;

                const arrowClass = cn(style.trendIcon, {
                  [style.arrowAscending]: icon === iconArrowUp,
                  [style.arrowDescending]: icon === iconArrowDown
                });

                trendsIcon = (
                  <Tooltip
                    content={`${t('Scorecard.PreviousValue')} ${
                      previousValue === 'NA' ? t('Tracking.NotAvailable') : previousValue
                    }`}
                    target={<img className={arrowClass} src={icon} alt="" />}
                  />
                );
              }

              const rowClass = cn(style.floatingRange, style[`range${record.band.color}`]);
              const bandLabel = t(`Scorecard.${record.band.label}`, record.band.label);
              const floatingRange = column.key === ColumnKeys.RANK && (
                <div className={rowClass}>{bandLabel}</div>
              );

              return (
                <>
                  {floatingRange}
                  <span>{text}</span>
                  {trendsIcon}
                </>
              );
            }
          : column.isBoolean
          ? (text, record) => (
              <>
                {record.metTargetScore && <StarFilled className={style.metCompanyTargetIconGrid} />}
              </>
            )
          : text =>
              column.actions ? (
                <Link
                  to={`${Paths.MAIN_PAGE}/${tab}/id/${text}/${dateFrom}/${dateTo}/${selectedCompany}`}
                >
                  {t('Common.View')}
                </Link>
              ) : (
                text
              )
      };
    });
};

const extractDataForRow = (entity, normalizedEntities, activeTab, localization) => {
  const row = Object.assign({}, EmptyRow);

  // get event related values (normalizedScore for Harsh Acc, Harsh Braking etc)
  Object.keys(ColumnKeys).forEach(key => {
    if (entity.types) {
      const defaultValue =
        ColumnKeys[key] !== ColumnKeys.ACTIVE_DAYS &&
        ColumnKeys[key] !== ColumnKeys.FUEL_USE &&
        ColumnKeys[key] !== ColumnKeys.DISTANCE
          ? 100
          : 0;
      row[ColumnKeys[key]] = getRoundValue(
        entity.types[key]?.normalizedScore >= 0 ? entity.types[key]?.normalizedScore : defaultValue,
        1
      );
      row[`${ColumnKeys[key]}ForTrends`] = getRoundValue(
        entity.previous?.types[key]?.normalizedScore >= 0
          ? entity.previous?.types[key]?.normalizedScore
          : defaultValue,
        1
      );
    }
  });
  // get distance, distanceUOM, duration, distanceTravelled, distanceUOM, duration, fuelConsumed, fuelUOM, normalizedScore, rank, trips
  Object.keys(entity)
    .filter(key => typeof entity[key] !== 'object')
    .forEach(key => {
      if (Number(entity[key])) {
        if (key === ColumnKeys.FUEL_USE && localization) {
          row[key] = localization.convertFuel(entity[key], 1);
        } else if (key === ColumnKeys.DISTANCE && localization) {
          row[key] = localization.convertDistance(entity[key], 1);
        } else {
          row[key] = getRoundValue(entity[key], 1);
        }
        if (normalizedEntities) {
          if (key === ColumnKeys.RANK && entity.previous[key] === 0) {
            row[`${key}ForTrends`] = 'NA';
          } else {
            if (key === ColumnKeys.FUEL_USE && localization) {
              row[`${key}ForTrends`] = localization.convertFuel(entity.previous[key], 0);
            } else if (key === ColumnKeys.DISTANCE && localization) {
              row[`${key}ForTrends`] = localization.convertDistance(entity.previous[key], 0);
            } else {
              row[`${key}ForTrends`] = getRoundValue(entity.previous[key], 1);
            }
          }
        }
      } else {
        row[key] = entity[key];
        if (normalizedEntities) {
          row[`${key}ForTrends`] = entity.previous[key];
        }
      }
    });
  // get the name of the entity (vehicle/driver)
  if (normalizedEntities) {
    if (activeTab === TabKeys.vehicles) {
      const vehicle = normalizedEntities[entity.entityId] || {};
      row.name = vehicle.name || '';
    } else {
      const user = normalizedEntities[entity.entityId] || {};
      row.name = `${user.firstName || ''} ${user.lastName || ''}`;
    }
  }
  // get range details
  row.band = entity.band;

  // Convert EXCESSIVE_IDLE
  if (
    row[ColumnKeys.EXCESSIVE_IDLE] &&
    entity.types.EXCESSIVE_IDLE?.points &&
    row[ColumnKeys.DISTANCE] &&
    localization
  ) {
    row[ColumnKeys.EXCESSIVE_IDLE] = localization.convertExcessiveIdle(
      row[ColumnKeys.EXCESSIVE_IDLE],
      entity[ColumnKeys.DISTANCE]
    );
  }

  return row;
};

export const prepareDataForTable = ({
  data,
  entities,
  activeTab,
  debouncedSearchText,
  hasGroup,
  localization
}) => {
  const normalizedEntities = entities?.reduce((acc, entity) => {
    acc[entity.id] = entity;
    return acc;
  }, {});
  const dataForTable =
    data?.entities?.map(entity =>
      extractDataForRow(entity, normalizedEntities, activeTab, localization)
    ) || [];
  const filteredDataForTable = debouncedSearchText
    ? dataForTable.filter(
        row => row.name.toLowerCase().indexOf(debouncedSearchText.toLowerCase()) > -1
      )
    : dataForTable.filter(row => hasCharacters(row.name));
  const average = data.company
    ? extractDataForRow(data.company, normalizedEntities, activeTab, localization)
    : {};
  const liveAverage = getLiveAverage(filteredDataForTable);
  const group =
    hasGroup &&
    data.group &&
    extractDataForRow(data.group, normalizedEntities, activeTab, localization);

  return {
    data: filteredDataForTable,
    average,
    liveAverage,
    ...(group && { group })
  };
};

const getLiveAverage = dataForTable =>
  dataForTable.reduce(
    (acc, currentValue) => {
      const newAcc = {};
      Object.keys(acc).forEach(key => {
        newAcc[key] = acc[key] + (Number(currentValue[key]) || 0) / dataForTable.length;
      });
      return newAcc;
    },
    {
      fuelConsumed: 0,
      fuelConsumedForTrends: 0,
      distanceTravelled: 0,
      distanceTravelledForTrends: 0,
      normalizedScore: 0,
      normalizedScoreForTrends: 0,
      excessiveIdle: 0,
      excessiveIdleForTrends: 0,
      harshAcceleration: 0,
      harshAccelerationForTrends: 0,
      harshBraking: 0,
      harshBrakingForTrends: 0,
      harshCornering: 0,
      harshCorneringForTrends: 0,
      overRev: 0,
      overRevForTrends: 0,
      speed: 0,
      speedForTrends: 0
    }
  );

export const getRangeColor = (value, ranges) => {
  let rangeForRow;
  ranges.forEach(range => {
    if (value >= range.value) {
      rangeForRow = range;
    }
  });
  return rangeForRow?.color;
};

export const getRowClassName = (record, ranges, style) => {
  const color = getRangeColor(record.normalizedScore, ranges);
  const classRange = `rangeRow${color}`;
  const classNames = cn(style[classRange], style.rowRange);
  return classNames;
};

export const prepareDataForLineChart = (data, eventTypes, id, localization, t) => {
  const chartData = {
    chart: [],
    lines: [],
    handleToggle: (index, lines, setLines) => {
      const newData = lines;
      newData[index] = {
        ...newData[index],
        checked: !newData[index]?.checked
      };

      setLines(newData);
    }
  };
  const daysArray = data.entities && data.entities[0]?.days ? data.entities[0]?.days : {};
  const filteredData = Object.keys(daysArray)
    .sort((dateA, dateB) => new Date(dateA).getTime() - new Date(dateB).getTime())
    .map(key =>
      extractDataForRow({
        ...daysArray[key],
        timeAt: moment(key).format(localization.formats.time.formats.dby.toUpperCase())
      })
    );

  // Filter the event types we show in the UI by the ones that are enabled
  const enabledEventTypes = getEnabledEventTypes(
    Object.values(EventTypes(localization)),
    eventTypes
  );

  const eventTypeKeys = enabledEventTypes.map(et => et.dataKey);

  chartData.chart = filteredData.map(fd => {
    const relevantValues = {};
    eventTypeKeys.forEach(rk => (relevantValues[rk] = fd[rk]));

    return { ...relevantValues, timeAt: fd.timeAt };
  });

  chartData.lines = enabledEventTypes.map(et => ({
    name: t(et.name),
    value: t(et.name),
    color: et.color,
    key: et.dataKey,
    order: et.order,
    checked: true
  }));

  chartData.lines.sort((a, b) => a.order - b.order);
  return chartData;
};

const checkMetricEnabled = (eventTypesConfig, type) => {
  let metricEnabled = false;
  if (eventTypesConfig) {
    const found = eventTypesConfig.find(et => et.type === type);
    metricEnabled = found ? found.enabled : false;
  }
  return metricEnabled;
};

export const prepareFileForExcelExport = (
  data = [],
  info = {},
  average,
  t,
  localization,
  eventTypesConfig
) => {
  const { company, scope, selection, isDriverScope, dateFrom, dateTo } = info;
  const allSelected = selection.find(s => s.id === 0)?.checked === true;
  const filteredSelected = selection
    .filter(s => s.id !== 0 && s.checked)
    .map(s => s.label)
    .join(', ');
  const formatDate = localization.formats.time.formats.dby.toUpperCase();
  const dateFromFormatted = moment(dateFrom).format(formatDate);
  const dateToFormatted = moment(dateTo).format(formatDate);
  const period = moment(dateTo).diff(moment(dateFrom), 'days') + 1;

  // check if each metric is enabled in the entityTypes config array from summary api call
  const isSpeedEnabled = checkMetricEnabled(eventTypesConfig, ScorecardEventType.SPEED);
  const isHarshAccelerationEnabled = checkMetricEnabled(
    eventTypesConfig,
    ScorecardEventType.HARSH_ACCELERATION
  );
  const isHarshBrakingEnabled = checkMetricEnabled(
    eventTypesConfig,
    ScorecardEventType.HARSH_BRAKING
  );
  const isHarshCorneringEnabled = checkMetricEnabled(
    eventTypesConfig,
    ScorecardEventType.HARSH_CORNERING
  );
  const isOverRevvingEnabled = checkMetricEnabled(eventTypesConfig, ScorecardEventType.OVERREV);
  const isIdleTimeEnabled = checkMetricEnabled(eventTypesConfig, ScorecardEventType.EXCESSIVE_IDLE);
  const isSeatbeltEnabled = checkMetricEnabled(
    eventTypesConfig,
    ScorecardEventType.SEAT_BELT_NON_USAGE
  );
  const isUnderRestEnabled = checkMetricEnabled(
    eventTypesConfig,
    ScorecardEventType.FATIGUE_UNDER_REST
  );
  const isOverWorkedEnabled = checkMetricEnabled(
    eventTypesConfig,
    ScorecardEventType.FATIGUE_OVER_WORKED
  );

  const header = [
    [t('Common.Scorecard').toUpperCase()],
    [t('Scorecard.Company'), company],
    [t('Scorecard.Scope'), t(`Tabs.${scope}`)],
    [t('Scorecard.Selection'), allSelected ? t('Common.All') : filteredSelected],
    [
      t('Scorecard.TimePeriod'),
      `${dateFromFormatted} - ${dateToFormatted} (${period} ${
        period > 1 ? t('Common.days') : t('Common.day')
      })`
    ],
    [],
    [`${t('Scorecard.Legend')}:`],
    [t('Scorecard.Previous')],
    [t('Scorecard.Current')],
    [],
    [
      `${t('Scorecard.CompanyRank')} ${t('Scorecard.(P)')}`,
      `${t('Scorecard.CompanyRank')} ${t('Scorecard.(C)')}`,
      ...(isDriverScope ? [`${t('Scorecard.BranchRank')} ${t('Scorecard.(P)')}`] : []),
      ...(isDriverScope ? [`${t('Scorecard.BranchRank')} ${t('Scorecard.(C)')}`] : []),
      `${t('Scorecard.Name')}`,
      `${t('Scorecard.activeDays')} ${t('Scorecard.(P)')}`,
      `${t('Scorecard.activeDays')} ${t('Scorecard.(C)')}`,
      `${t('Scorecard.distanceTravelled')} (${localization?.formats?.speed?.unit_pluralize}) ${t(
        'Scorecard.(P)'
      )}`,
      `${t('Scorecard.distanceTravelled')} (${localization?.formats?.speed?.unit_pluralize}) ${t(
        'Scorecard.(C)'
      )}`,
      `${toTitleCase(t('Scorecard.fuelConsumed'))} (${localization?.formats?.fuel?.usage}) ${t(
        'Scorecard.(P)'
      )}`,
      `${toTitleCase(t('Scorecard.fuelConsumed'))} (${localization?.formats?.fuel?.usage}) ${t(
        'Scorecard.(C)'
      )}`,
      `${t('Scorecard.MetTargetScore')} ${t('Scorecard.(P)')}`,
      `${t('Scorecard.MetTargetScore')} ${t('Scorecard.(C)')}`,
      `${t('Scorecard.Score')} ${t('Scorecard.(P)')}`,
      `${t('Scorecard.Score')} ${t('Scorecard.(C)')}`,
      ...(isSpeedEnabled ? [`${t('Scorecard.SpeedEvent')} ${t('Scorecard.(P)')}`] : []),
      ...(isSpeedEnabled ? [`${t('Scorecard.SpeedEvent')} ${t('Scorecard.(C)')}`] : []),
      ...(isHarshAccelerationEnabled
        ? [`${t('Scorecard.HarshAcceleration')} ${t('Scorecard.(P)')}`]
        : []),
      ...(isHarshAccelerationEnabled
        ? [`${t('Scorecard.HarshAcceleration')} ${t('Scorecard.(C)')}`]
        : []),
      ...(isHarshBrakingEnabled ? [`${t('Scorecard.HarshBraking')} ${t('Scorecard.(P)')}`] : []),
      ...(isHarshBrakingEnabled ? [`${t('Scorecard.HarshBraking')} ${t('Scorecard.(C)')}`] : []),
      ...(isHarshCorneringEnabled
        ? [`${t('Scorecard.HarshCornering')} ${t('Scorecard.(P)')}`]
        : []),
      ...(isHarshCorneringEnabled
        ? [`${t('Scorecard.HarshCornering')} ${t('Scorecard.(C)')}`]
        : []),
      ...(isOverRevvingEnabled ? [`${t('Scorecard.OverRevving')} ${t('Scorecard.(P)')}`] : []),
      ...(isOverRevvingEnabled ? [`${t('Scorecard.OverRevving')} ${t('Scorecard.(C)')}`] : []),
      ...(isIdleTimeEnabled ? [`${t('Scorecard.IdleTime')} ${t('Scorecard.(P)')}`] : []),
      ...(isIdleTimeEnabled ? [`${t('Scorecard.IdleTime')} ${t('Scorecard.(C)')}`] : []),
      ...(isSeatbeltEnabled ? [`${t('Scorecard.seatbeltNonUsage')} ${t('Scorecard.(P)')}`] : []),
      ...(isSeatbeltEnabled ? [`${t('Scorecard.seatbeltNonUsage')} ${t('Scorecard.(C)')}`] : []),
      ...(isUnderRestEnabled ? [`${t('Scorecard.underRest')} ${t('Scorecard.(P)')}`] : []),
      ...(isUnderRestEnabled ? [`${t('Scorecard.underRest')} ${t('Scorecard.(C)')}`] : []),
      ...(isOverWorkedEnabled ? [`${t('Scorecard.overWorked')} ${t('Scorecard.(P)')}`] : []),
      ...(isOverWorkedEnabled ? [`${t('Scorecard.overWorked')} ${t('Scorecard.(C)')}`] : [])
    ]
  ];

  const rows = data.map(row => {
    const {
      activeDaysForTrends,
      activeDays,
      distanceTravelled,
      distanceTravelledForTrends,
      fuelConsumed,
      fuelConsumedForTrends,
      metTargetScoreForTrends,
      metTargetScore,
      normalizedScoreForTrends,
      normalizedScore,
      name,
      rankForTrends,
      rank,
      groupRankForTrends,
      groupRank,
      speedForTrends,
      speed,
      harshAccelerationForTrends,
      harshAcceleration,
      harshBrakingForTrends,
      harshBraking,
      harshCorneringForTrends,
      harshCornering,
      overRevForTrends,
      overRev,
      excessiveIdleForTrends,
      excessiveIdle,
      seatbeltNonUsageForTrends,
      seatbeltNonUsage,
      underRestForTrends,
      underRest,
      overWorkedForTrends,
      overWorked
    } = row;

    let rowData = {
      [xlsRows.rankForTrends]: rankForTrends,
      [xlsRows.rank]: rank,
      ...(isDriverScope && { [xlsRows.groupRankForTrends]: groupRankForTrends }),
      ...(isDriverScope && { [xlsRows.groupRank]: groupRank }),
      [xlsRows.name]: name,
      [xlsRows.activeDaysForTrends]: activeDaysForTrends,
      [xlsRows.activeDays]: activeDays,
      [xlsRows.distanceTravelledForTrends]: distanceTravelledForTrends,
      [xlsRows.distanceTravelled]: distanceTravelled,
      [xlsRows.fuelConsumedForTrends]: fuelConsumedForTrends,
      [xlsRows.fuelConsumed]: fuelConsumed,
      [xlsRows.metTargetScoreForTrends]: metTargetScoreForTrends ? t('Common.Yes') : t('Common.No'),
      [xlsRows.metTargetScore]: metTargetScore ? t('Common.Yes') : t('Common.No'),
      [xlsRows.normalizedScoreForTrends]: normalizedScoreForTrends,
      [xlsRows.normalizedScore]: normalizedScore,
      ...(isSpeedEnabled && { [xlsRows.speedForTrends]: speedForTrends }),
      ...(isSpeedEnabled && { [xlsRows.speed]: speed }),
      ...(isHarshAccelerationEnabled && {
        [xlsRows.harshAccelerationForTrends]: harshAccelerationForTrends
      }),
      ...(isHarshAccelerationEnabled && { [xlsRows.harshAcceleration]: harshAcceleration }),
      ...(isHarshBrakingEnabled && { [xlsRows.harshBrakingForTrends]: harshBrakingForTrends }),
      ...(isHarshBrakingEnabled && { [xlsRows.harshBraking]: harshBraking }),
      ...(isHarshCorneringEnabled && {
        [xlsRows.harshCorneringForTrends]: harshCorneringForTrends
      }),
      ...(isHarshCorneringEnabled && { [xlsRows.harshCornering]: harshCornering }),
      ...(isOverRevvingEnabled && { [xlsRows.overRevForTrends]: overRevForTrends }),
      ...(isOverRevvingEnabled && { [xlsRows.overRev]: overRev }),
      ...(isIdleTimeEnabled && { [xlsRows.idleTimeForTrends]: excessiveIdleForTrends }),
      ...(isIdleTimeEnabled && { [xlsRows.idleTime]: excessiveIdle }),
      ...(isSeatbeltEnabled && { [xlsRows.seatbeltNonUsageForTrends]: seatbeltNonUsageForTrends }),
      ...(isSeatbeltEnabled && { [xlsRows.seatbeltNonUsage]: seatbeltNonUsage }),
      ...(isUnderRestEnabled && { [xlsRows.underRestForTrends]: underRestForTrends }),
      ...(isUnderRestEnabled && { [xlsRows.underRest]: underRest }),
      ...(isOverWorkedEnabled && { [xlsRows.overWorkedForTrends]: overWorkedForTrends }),
      ...(isOverWorkedEnabled && { [xlsRows.overWorked]: overWorked })
    };

    return rowData;
  });

  // Need to create from scratch since it changes with each export due to
  // enabled/disabled metrics
  const xlsOptions = {
    header: [
      xlsRows.rankForTrends,
      xlsRows.rank,
      ...(isDriverScope ? [xlsRows.groupRankForTrends] : []),
      ...(isDriverScope ? [xlsRows.groupRank] : []),
      xlsRows.name,
      xlsRows.activeDaysForTrends,
      xlsRows.activeDays,
      xlsRows.distanceTravelledForTrends,
      xlsRows.distanceTravelled,
      xlsRows.fuelConsumedForTrends,
      xlsRows.fuelConsumed,
      xlsRows.metTargetScoreForTrends,
      xlsRows.metTargetScore,
      xlsRows.normalizedScoreForTrends,
      xlsRows.normalizedScore
    ],
    skipHeader: true,
    origin: 11
  };

  let summaryData = {
    [xlsRows.rank]: average.rank,
    name: t('Scorecard.AverageScore'),
    [xlsRows.metTargetScore]: average.metTargetScore ? t('Common.Yes') : t('Common.No'),
    [xlsRows.normalizedScore]: getRoundValue(average.normalizedScore, 1)
  };

  if (isSpeedEnabled) {
    summaryData[xlsRows.speed] = getRoundValue(average.speed, 1);
    xlsOptions.header.push(xlsRows.speedForTrends);
    xlsOptions.header.push(xlsRows.speed);
  }

  if (isHarshAccelerationEnabled) {
    summaryData[xlsRows.harshAcceleration] = getRoundValue(average.harshAcceleration, 1);
    xlsOptions.header.push(xlsRows.harshAccelerationForTrends);
    xlsOptions.header.push(xlsRows.harshAcceleration);
  }

  if (isHarshBrakingEnabled) {
    summaryData[xlsRows.harshBraking] = getRoundValue(average.harshBraking, 1);
    xlsOptions.header.push(xlsRows.harshBrakingForTrends);
    xlsOptions.header.push(xlsRows.harshBraking);
  }

  if (isHarshCorneringEnabled) {
    summaryData[xlsRows.harshCornering] = getRoundValue(average.harshCornering, 1);
    xlsOptions.header.push(xlsRows.harshCorneringForTrends);
    xlsOptions.header.push(xlsRows.harshCornering);
  }

  if (isOverRevvingEnabled) {
    summaryData[xlsRows.overRev] = getRoundValue(average.overRev, 1);
    xlsOptions.header.push(xlsRows.overRevForTrends);
    xlsOptions.header.push(xlsRows.overRev);
  }

  if (isIdleTimeEnabled) {
    summaryData[xlsRows.idleTime] = getRoundValue(average.excessiveIdle, 1);
    xlsOptions.header.push(xlsRows.idleTimeForTrends);
    xlsOptions.header.push(xlsRows.idleTime);
  }

  if (isSeatbeltEnabled) {
    summaryData[xlsRows.seatbeltNonUsage] = getRoundValue(average.seatbeltNonUsage, 1);
    xlsOptions.header.push(xlsRows.seatbeltNonUsageForTrends);
    xlsOptions.header.push(xlsRows.seatbeltNonUsage);
  }

  if (isUnderRestEnabled) {
    summaryData[xlsRows.underRest] = getRoundValue(average.underRest, 1);
    xlsOptions.header.push(xlsRows.underRestForTrends);
    xlsOptions.header.push(xlsRows.underRest);
  }

  if (isOverWorkedEnabled) {
    summaryData[xlsRows.overWorked] = getRoundValue(average.overWorked, 1);
    xlsOptions.header.push(xlsRows.overWorkedForTrends);
    xlsOptions.header.push(xlsRows.overWorked);
  }

  rows.unshift(summaryData);

  const worksheet = XLSX.utils.aoa_to_sheet(header);
  XLSX.utils.sheet_add_json(worksheet, rows, xlsOptions);
  worksheet['!cols'] = columnWidth;
  worksheet['!autoFilter'] = { ref: 'A1:T102' };

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Scorecard');
  XLSX.writeFile(
    workbook,
    `${t('Common.Scorecard')} ${t(
      `Tabs.${scope}`
    )} (${dateFromFormatted} - ${dateToFormatted}).xlsx`
  );
};
