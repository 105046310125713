import { deviceNameCellRenderer, fleetsRenderer, vehicleCellRenderer } from './CellRenderers';
import { Button, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

export const templateTypeEnum = {
  GPIO: 'GPIO',
  Assets: 'ASSETSUSAGE',
  SpeedAssist: 'SPEEDASSIST',
  DriverId: 'DRIVERID'
};

export const deviceColumns = (t, handleViewTemplate, templateType) => {
  let columns = [
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Device Name'),
      dataIndex: 'name',
      render: (_, record) => deviceNameCellRenderer({ rowData: record, canViewDevice: true }),
      key: 'name'
    },
    {
      title: t('Common.Fleets'),
      dataIndex: 'fleet',
      render: (_, record) => fleetsRenderer({ rowData: record }),
      key: 'fleet'
    },
    {
      title: t('Devices.Table.TypeLabel'),
      dataIndex: 'type',
      key: 'type',
      render: type => <span>{type.name} </span>
    },
    {
      title: t('Devices.ActualForm.ModelLabel'),
      dataIndex: 'model',
      key: 'model',
      render: model => <span>{model.name} </span>
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Vehicle Name'),
      dataIndex: 'vehicle',
      render: (_, record) => vehicleCellRenderer({ rowData: record, canViewVehicle: true }),
      key: 'vehicle'
    },
    {
      title: t('Common.VehicleType'),
      dataIndex: 'vehicleType',
      render: (_, record) => <span>{record.vehicle?.type?.name} </span>,
      key: 'vehicleType'
    }
  ];

  //Change made so that we can display the devices on the configuration view page
  if (handleViewTemplate) {
    columns.push({
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template'),
      dataIndex: 'template',
      key: 'template',
      render: (_, record) => (
        <Link to={'#'} onClick={() => handleViewTemplate(record)}>
          {record?.template
            ? record?.template?.name
            : record?.deviceConfiguration
            ? t('Common.DeviceLevelSettings')
            : ''}
        </Link>
      )
    });
  }

  return columns;
};
