import {
  companyDeviceFeaturesCheck,
  deviceCapabilities
} from 'features/permissions/deviceFeaturesByFirmware';
import services from 'features/permissions/services';
import i18n from 'i18nextConfig';
import { nanoid } from 'nanoid';
import ALERT_ACTIONS from './reducer/alertFormActions';

const ALERT_FORMS_ELEMS_OFFSET = 1;

const ALERT_FORMS_ELEMS_SPAN = {
  SELECT: 4,
  MULTISELECT: 16,
  INPUT: 8,
  LABEL: 24
};

const LABEL_SIZE = {
  offset: ALERT_FORMS_ELEMS_OFFSET,
  span: ALERT_FORMS_ELEMS_SPAN.LABEL
};

export const LABEL_WRAPPER_COL = {
  offset: ALERT_FORMS_ELEMS_OFFSET,
  span: ALERT_FORMS_ELEMS_SPAN.INPUT
};

export const EXPIRATION_OPTIONS = [
  {
    id: String(nanoid()),
    value: 5259490,
    get label() {
      return i18n.t('Alerts.No Expiry');
    }
  },
  { id: String(nanoid()), value: 5, label: '5' },
  { id: String(nanoid()), value: 10, label: '10' },
  { id: String(nanoid()), value: 15, label: '15' },
  { id: String(nanoid()), value: 30, label: '30' },
  { id: String(nanoid()), value: 60, label: '60' }
];

export const DEVICE_TYPE_OPTIONS = [
  {
    id: String(nanoid()),
    value: 'ATS',
    get label() {
      return 'ATS';
    }
  },
  { id: String(nanoid()), value: 5, label: 'Other device types' }
  // { id: String(nanoid()), value: 10, label: '10' },
  // { id: String(nanoid()), value: 15, label: '15' },
  // { id: String(nanoid()), value: 30, label: '30' },
  // { id: String(nanoid()), value: 60, label: '60' }
];

export const METER_TYPE_OPTIONS = [
  {
    id: String(nanoid()),
    value: 'Battery',
    get label() {
      return 'Battery';
    }
  },
  { id: String(nanoid()), value: 5, label: 'Other meter types' }
  // { id: String(nanoid()), value: 10, label: '10' },
  // { id: String(nanoid()), value: 15, label: '15' },
  // { id: String(nanoid()), value: 30, label: '30' },
  // { id: String(nanoid()), value: 60, label: '60' }
];

export const METER_CONFIGURATION_OPTIONS = {
  SOURCE: [
    {
      id: String(nanoid()),
      value: 'Source1',
      get label() {
        return 'Source1';
      }
    },
    { id: String(nanoid()), value: 5, label: 'Other meter types' }
  ],
  OPERATION: [
    {
      id: String(nanoid()),
      value: 'Operation1',
      get label() {
        return 'Operation1';
      }
    },
    { id: String(nanoid()), value: 5, label: 'Other meter types' }
  ],
  METER_TYPE: METER_TYPE_OPTIONS,
  DEVICE_TYPE: DEVICE_TYPE_OPTIONS
};

export const MULTISELECT_TYPE = {
  FLEETS: 'Fleets',
  VEHICLES: 'Vehicles',
  DRIVERS: 'Drivers',
  BRANCHES: 'Branches',
  USERS: 'Users',
  SECONDALERTUSERS: 'SecondAlertUsers',
  THIRDALERTUSERS: 'ThidAlertUsers',
  FORMS: 'Forms',
  GEOFENCES: 'Geofences',
  MANAGED_GEOFENCES: 'Managed Geofences',
  PRETRIP_CHECKLISTS: 'Inspections',
  GPIO: 'Gpio',
  DURESS: 'Duress',
  DOCUMENTEXPIRATIONFOLDERS: 'DocumentExpirationFolders'
};

export const PROPERTIES = {
  CHECKBOXES: {
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    name: 'alertCheckboxes',
    label: 'Checkbox container title goes here'
  }
};

export const CHECKBOX_PROPERTIES = {
  ENGINE: {
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    name: 'eventAlertCheckbox',
    label: '',
    showInfoAlert: true
  },
  ENGINE_DRIVER_VEHICLE: {
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    name: 'eventAlertCheckbox',
    label: '',
    showInfoAlert: false,
    style: { marginTop: 0 }
  },
  ROUTE_COMPLIANCE: {
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    name: 'routeComplianceAlertCheckbox',
    label: ''
  },
  SENTINEL: {
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    name: 'sentinelAlertCheckbox',
    label: ''
  },
  SPEED: {
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    name: 'speedAlertCheckbox',
    label: ''
  },
  DRIVER_AND_VEHICLE: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'driverAndVehicleCheckbox',
    label: '',
    showInfoAlert: true
  },
  ELD: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'eldCheckbox',
    label: ''
  },
  ELD_VIOLATIONS: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'eldCheckboxViolations',
    get label() {
      return i18n.t('ELD.Violations');
    }
  },
  ELD_WARNINGS: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'eldCheckboxWarnings',
    get label() {
      //uncomment this line to show the warnings header on the ELD Alert page when ready
      //return i18n.t('ELD.Warnings');
      return '';
    }
  },
  INSPECTIONS: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'inspectionCheckbox',
    label: ''
  },
  SMARTJOBS: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'smartjobsCheckbox',
    label: ''
  },
  GEOFENCES: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'geofencesCheckbox',
    label: ''
  },
  MANAGED_GEOFENCES: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'managedGeofencesCheckbox',
    label: ''
  },
  OOH: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'oohCheckbox',
    label: ''
  },
  SECOND_ALERT: {
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    name: 'secondAlert',
    label: i18n.t('Alerts.IdleTimeSecondAlert')
  },
  THIRD_ALERT: {
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    name: 'thirdAlert',
    label: i18n.t('Alerts.IdleTimeThirdAlert')
  },
  TACHO: {
    labelCol: LABEL_SIZE,
    wrapperCOL: LABEL_WRAPPER_COL,
    name: 'tachoCheckbox',
    label: ''
  }
};

export const INPUT_PROPERTIES = {
  NAME: {
    name: 'alertNameInput',
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    get label() {
      return i18n.t('Alerts.Alert Name');
    },
    get tooltip() {
      return i18n.t('Alerts.AlertNameTooltip');
    },
    rules: [
      {
        required: true,
        get message() {
          return i18n.t('Alerts.ValidationRules.AlertName');
        },
        whitespace: true
      }
    ]
  },
  NUMBER: {
    name: 'inputNumber',
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    get label() {
      return i18n.t('Reports.Driver Feedback');
    }
  },
  SPEED_LIMIT_KM: {
    name: 'speedInputNumber',
    labelCol: LABEL_SIZE,
    max: 99,
    wrapperCol: LABEL_WRAPPER_COL,
    get label() {
      return i18n.t('Alerts.SpeedOverLimit', { unit: 'km/h' });
    }
  },
  SPEED_LIMIT_MI: {
    name: 'speedInputNumber',
    labelCol: LABEL_SIZE,
    max: 99,
    wrapperCol: LABEL_WRAPPER_COL,
    get label() {
      return i18n.t('Alerts.SpeedOverLimit', { unit: 'mph' });
    }
  },
  DAYSTOEXPIRE: {
    name: 'daystoexpire',
    labelCol: LABEL_SIZE,
    wrapperCol: LABEL_WRAPPER_COL,
    max: 999,
    get label() {
      return i18n.t('Alerts.ExpirationVerbiage');
    }
  },
  SECOND_TIMER: {
    name: 'secondTimerSelect',
    get label() {
      return i18n.t('Alerts.TimeAfterInitialAlert');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.INPUT
    },
    max: 60,
    precision: 0,
    actionType: ALERT_ACTIONS.SET_SECOND_ALERT_TIMER
  },
  THIRD_TIMER: {
    name: 'thirdTimerSelect',
    get label() {
      return i18n.t('Alerts.TimeAfterSecondAlert');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.INPUT
    },
    max: 60,
    precision: 0,
    actionType: ALERT_ACTIONS.SET_THIRD_ALERT_TIMER
  }
};

export const SELECT_PROPERTIES = {
  EXPIRATION: {
    name: 'expirationSelect',
    get label() {
      return i18n.t('Alerts.Alert notification expiration (mins)');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.SELECT
    },
    actionType: ALERT_ACTIONS.SET_EXPIRY
  },
  TIMEZONE: {
    name: 'timezoneSelect',
    get label() {
      return i18n.t('Alerts.TimeZoneForOOH');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.SELECT
    },
    actionType: ALERT_ACTIONS.SET_TIMEZONE
  },
  DEVICE_TYPE: {
    name: 'deviceTypeSelect',
    get label() {
      return i18n.t('Alerts.DeviceType');
    },
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectOption');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.SELECT
    },
    actionType: ALERT_ACTIONS.SET_EXPIRY,
    rules: [
      {
        required: true,
        get message() {
          return i18n.t('Alerts.ValidationRules.DeviceType');
        },
        whitespace: true
      }
    ]
  },
  METER_TYPE: {
    name: 'meterTypeSelect',
    get label() {
      return i18n.t('Alerts.MeterType');
    },
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectOption');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.SELECT
    },
    actionType: ALERT_ACTIONS.SET_EXPIRY,
    rules: [
      {
        required: true,
        get message() {
          return i18n.t('Alerts.ValidationRules.MeterType');
        },
        whitespace: true
      }
    ]
  },
  METER_CONFIGURATION_TYPE: {
    name: {
      source: 'meterConfigurationSource',
      operation: 'meterConfigurationOperation',
      value: 'meterConfigurationValue',
      meterType: 'meterTypeSelect',
      deviceType: 'deviceTypeSelect',
      meterConfiguration: 'meterConfigurationGroup',
      type: 'radioType'
    },
    label: {
      get meterType() {
        return i18n.t('Alerts.MeterType');
      },
      get deviceType() {
        return i18n.t('Alerts.DeviceType');
      },
      get meterConfiguration() {
        return i18n.t('Alerts.MeterConfiguration');
      },
      get type() {
        return i18n.t('Alerts.Type');
      }
    },
    placeholder: {
      get source() {
        return i18n.t('Alerts.Placeholders.SelectSource');
      },
      get operation() {
        return i18n.t('Alerts.Placeholders.SelectOperation');
      },
      get value() {
        return i18n.t('Alerts.Placeholders.EnterValue');
      },
      get meterType() {
        return i18n.t('Alerts.Placeholders.SelectOption');
      },
      get deviceType() {
        return i18n.t('Alerts.Placeholders.SelectOption');
      }
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.SELECT
    },
    actionType: ALERT_ACTIONS.SET_EXPIRY,
    rules: {
      type: [
        {
          required: true,
          get message() {
            return i18n.t('Alerts.ValidationRules.Type');
          }
        }
      ],
      deviceType: [
        {
          required: true,
          get message() {
            return i18n.t('Alerts.ValidationRules.DeviceType');
          }
        }
      ],
      meterType: [
        {
          required: true,
          get message() {
            return i18n.t('Alerts.ValidationRules.MeterType');
          }
        }
      ],
      source: [
        {
          required: true,
          get message() {
            return i18n.t('Alerts.ValidationRules.Source');
          }
        }
      ],
      operation: [
        {
          required: true,
          get message() {
            return i18n.t('Alerts.ValidationRules.Operation');
          }
        }
      ],
      value: [
        {
          required: true,
          get message() {
            return i18n.t('Alerts.ValidationRules.Value');
          }
        },
        {
          pattern: /^[-+]?[0-9]*\.?[0-9]+$/,
          get message() {
            return i18n.t('Alerts.ValidationRules.OnlyFloatNumbers');
          }
        }
      ]
    }
  },
  GPIO_TYPE: {
    name: 'gpioSelect',
    get label() {
      return i18n.t('Alerts.GPIO Type');
    },
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectGpioType');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    actionType: ALERT_ACTIONS.SET_GPIO_TYPES,
    rules: [
      {
        required: true,
        get message() {
          return i18n.t('Alerts.AlertSelectTypeValidationRule');
        }
      }
    ]
  },
  DURESS_TYPE: {
    name: 'duressSelect',
    get label() {
      return i18n.t('Alerts.Duress Type');
    },
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectDuressType');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    actionType: ALERT_ACTIONS.SET_DURESS_TYPES,
    rules: [
      {
        required: true,
        get message() {
          return i18n.t('Alerts.AlertSelectTypeValidationRule');
        }
      }
    ]
  },
  SPEED_KM: {
    name: 'speedSelect',
    get label() {
      return i18n.t('Alerts.SpeedOverLimit', { unit: 'km/h' });
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.SELECT
    },
    actionType: ALERT_ACTIONS.SET_EXPIRY
  },
  SPEED_MI: {
    name: 'speedSelect',
    get label() {
      return i18n.t('Alerts.SpeedOverLimit', { unit: 'mph' });
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.SELECT
    },
    actionType: ALERT_ACTIONS.SET_EXPIRY
  },
  FORMS: {
    name: 'formsSelect',
    get label() {
      return i18n.t('Common.Forms');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectForms');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Company');
      },
      get thirdColumnValue() {
        return i18n.t('Common.Fleets');
      }
    },
    actionType: ALERT_ACTIONS.SET_FORMS
  },
  GEOFENCES: {
    name: 'geofencesSelect',
    get label() {
      return i18n.t('Common.Geofences');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectGeofences');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Fleets');
      }
    },
    actionType: ALERT_ACTIONS.SET_GEOFENCES
  },
  DOCUMENTEXPIRATIONFOLDERS: {
    name: 'documentExpirationFoldersSelect',
    get label() {
      return i18n.t('Alerts.DocumentFolder');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectFolders');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      }
    },
    actionType: ALERT_ACTIONS.SET_DOCUMENTEXPIRATIONFOLDERS
  },
  MANAGED_GEOFENCES: {
    name: 'managedGeofencesSelect',
    get label() {
      return `${i18n.t('Common.Managed')} ${i18n.t('Common.Geofences')}`;
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectManagedGeofences');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Fleets');
      }
    },
    actionType: ALERT_ACTIONS.SET_MANAGED_GEOFENCES
  },
  PRETRIP_CHECKLISTS: {
    name: 'pretripChecklistSelect',
    get label() {
      return i18n.t('Common.PretripChecklist');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectPretrips');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Companies');
      },
      get thirdColumnValue() {
        return i18n.t('Common.Fleets');
      }
    },
    actionType: ALERT_ACTIONS.SET_PRETRIPS
  },
  VEHICLES: {
    name: 'vehicleSelect',
    get label() {
      return i18n.t('Common.Vehicles');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectVehicles');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Registration');
      },
      get thirdColumnValue() {
        return i18n.t('Common.Fleets');
      }
    },
    actionType: ALERT_ACTIONS.SET_VEHICLES
  },
  FLEETS: {
    name: 'fleetSelect',
    get label() {
      return i18n.t('Common.Fleets');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectFleets');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Description');
      },
      get thirdColumnValue() {
        return i18n.t('Common.Vehicles');
      }
    },
    actionType: ALERT_ACTIONS.SET_FLEETS
  },
  DRIVERS: {
    name: 'driverSelect',
    get label() {
      return i18n.t('Common.Drivers');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectDrivers');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Registration');
      },
      get thirdColumnValue() {
        return i18n.t('Common.Branch');
      }
    },
    actionType: ALERT_ACTIONS.SET_DRIVERS
  },
  BRANCHES: {
    name: 'branchSelect',
    get label() {
      return i18n.t('Common.Branches');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectBranches');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Drivers');
      }
    },
    actionType: ALERT_ACTIONS.SET_BRANCHES
  },
  USERS: {
    name: 'userSelect',
    get label() {
      return i18n.t('Common.Users');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectUsers');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Branch');
      },
      get thirdColumnValue() {
        return i18n.t('Users.View.UserType');
      }
    },
    actionType: ALERT_ACTIONS.SET_USERS
  },
  SECOND_ALERT_USERS: {
    name: 'secondAlertUserSelect',
    get label() {
      return i18n.t('Common.Users');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectUsers');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Branch');
      },
      get thirdColumnValue() {
        return i18n.t('Users.View.UserType');
      }
    },
    actionType: ALERT_ACTIONS.SET_SECOND_ALERT_USERS
  },
  THIRD_ALERT_USERS: {
    name: 'thirdAlertUserSelect',
    get label() {
      return i18n.t('Common.Users');
    },
    mode: 'multiple',
    get placeholder() {
      return i18n.t('Alerts.Placeholders.SelectUsers');
    },
    labelCol: LABEL_SIZE,
    wrapperCol: {
      offset: ALERT_FORMS_ELEMS_OFFSET,
      span: ALERT_FORMS_ELEMS_SPAN.MULTISELECT
    },
    columnHeaderLabels: {
      get firstColumnValue() {
        return i18n.t('Common.Name');
      },
      get secondColumnValue() {
        return i18n.t('Common.Branch');
      },
      get thirdColumnValue() {
        return i18n.t('Users.View.UserType');
      }
    },
    actionType: ALERT_ACTIONS.SET_THIRD_ALERT_USERS
  }
};

export const ALERT_TYPES = {
  CAMERA: 'Ng::CameraAlertSubscription',
  ENGINE: 'Ng::EngineAlertSubscription',
  FORM: 'Ng::FormAlertSubscription',
  GEOFENCE: 'Ng::GeofenceAlertSubscription',
  OOH: 'Ng::OutOfHoursAlertSubscription',
  PRETRIP: 'Ng::PtcAlertSubscription',
  ROUTE: 'Ng::RouteComplianceAlertSubscription',
  SENTINEL: 'Ng::FatigueAlertSubscription',
  SPEED: 'Ng::SpeedAlertSubscription',
  VEHICLE_MAINTENANCE: 'Ng::VehicleMaintenanceAlertSubscription',
  DRIVER_MANAGEMENT: 'Ng::DriverManagementAlertSubscription',
  DRIVER_AND_VEHICLE: 'Ng::AlarmAlertSubscription',
  SMARTJOBS: 'Ng::SmartjobsAlertSubscription',
  ELD: 'Ng::EldAlertSubscription',
  MASS_MANAGEMENT: 'Ng::MassManagementAlertSubscription',
  METER: 'Ng::MeterAlertSubscription',
  GPIO: 'Ng::GpioAlertSubscription',
  DURESS: 'Ng::DuressAlertSubscription',
  DOCUMENTEXPIRATION: 'Ng::DocumentExpirationSubscription',
  TACHO: 'Ng::TachoAlertSubscription'
};

export const ALERT_LINKS = {
  CAMERA: 'camera',
  HARSHDRIVING: 'harshDriving',
  FORM: 'form',
  GEOFENCE: 'geofence',
  OOH: 'ooh',
  PRETRIP: 'pretripChecklist',
  ROUTE: 'routeCompliance',
  SENTINEL: 'sentinel',
  SPEED: 'speed',
  VEHICLE_MAINTENANCE: 'vehicleMaintenance',
  DRIVER_MANAGEMENT: 'driverManagement',
  DRIVER_AND_VEHICLE: 'driverAndVehicle',
  SMARTJOBS: 'smartjobs',
  ELD: 'eld',
  MASS_MANAGEMENT: 'massManagement',
  METER: 'meter',
  GPIO: 'gpio',
  DURESS: 'duress',
  DOCUMENTEXPIRATION: 'documentExpiration',
  TACHO: 'tacho'
};

export const ALERT_NAMES = {
  get CAMERA() {
    return i18n.t('Alerts.Camera Alert');
  },
  get ENGINE() {
    return i18n.t('Alerts.Engine Alert');
  },
  get HARSHDRIVING() {
    return i18n.t('Alerts.Harsh Driving Alert');
  },
  get FORM() {
    return i18n.t('Alerts.Form Alert');
  },
  get GEOFENCE() {
    return i18n.t('Alerts.Geofence Alert');
  },
  get PRETRIP() {
    return i18n.t('Alerts.Pre-Trip Checklist Alert');
  },
  get ROUTE() {
    return i18n.t('Alerts.Route Compliance Alert');
  },
  get SENTINEL() {
    return i18n.t('Alerts.Sentinel Alert');
  },
  get SPEED() {
    return i18n.t('Alerts.Speed Alert');
  },
  get VEHICLE_MAINTENANCE() {
    return i18n.t('Alerts.Vehicle Maintenance Alert');
  },
  get DRIVER_AND_VEHICLE() {
    return i18n.t('Alerts.Driver & Vehicle Alert');
  },
  get ELD() {
    return i18n.t('Alerts.ELD Alert');
  },
  get DRIVER_MANAGEMENT() {
    return i18n.t('Alerts.Driver Management Alert');
  },
  get SMARTJOBS() {
    return i18n.t('Alerts.SmartJobsAlert.title');
  },
  get OOH() {
    return i18n.t('Alerts.Out of Hours Alert');
  },
  get MASS_MANAGEMENT() {
    return i18n.t('Alerts.MassManagementAlert.title');
  },
  get METER() {
    return i18n.t('Alerts.MeterAlert.title');
  },
  get GPIO() {
    return i18n.t('Alerts.GPIO Alert');
  },
  get DURESS() {
    return i18n.t('Alerts.Duress Alert');
  },
  get DOCUMENTEXPIRATION() {
    return i18n.t('Alerts.DocumentExpiration Alert');
  },
  get TACHO() {
    return i18n.t('Alerts.Tacho Alert');
  }
};

export const companyAlertForms = [
  {
    type: ALERT_TYPES.CAMERA,
    get name() {
      return ALERT_NAMES.CAMERA;
    },
    link: ALERT_LINKS.CAMERA
  },
  {
    type: ALERT_TYPES.ENGINE,
    get name() {
      return ALERT_NAMES.HARSHDRIVING;
    },
    link: ALERT_LINKS.HARSHDRIVING
  },
  {
    type: ALERT_TYPES.FORM,
    get name() {
      return ALERT_NAMES.FORM;
    },
    link: ALERT_LINKS.FORM
  },
  {
    type: ALERT_TYPES.GEOFENCE,
    get name() {
      return ALERT_NAMES.GEOFENCE;
    },
    link: ALERT_LINKS.GEOFENCE
  },
  {
    type: ALERT_TYPES.OOH,
    get name() {
      return ALERT_NAMES.OOH;
    },
    link: ALERT_LINKS.OOH
  },
  {
    type: ALERT_TYPES.PRETRIP,
    get name() {
      return ALERT_NAMES.PRETRIP;
    },
    link: ALERT_LINKS.PRETRIP
  },
  {
    type: ALERT_TYPES.ROUTE,
    get name() {
      return ALERT_NAMES.ROUTE;
    },
    link: ALERT_LINKS.ROUTE
  },
  {
    type: ALERT_TYPES.SENTINEL,
    get name() {
      return ALERT_NAMES.SENTINEL;
    },
    link: ALERT_LINKS.SENTINEL
  },
  {
    type: ALERT_TYPES.SPEED,
    get name() {
      return ALERT_NAMES.SPEED;
    },
    link: ALERT_LINKS.SPEED
  },
  {
    type: ALERT_TYPES.VEHICLE_MAINTENANCE,
    get name() {
      return ALERT_NAMES.VEHICLE_MAINTENANCE;
    },
    link: ALERT_LINKS.VEHICLE_MAINTENANCE
  },
  {
    type: ALERT_TYPES.DRIVER_MANAGEMENT,
    get name() {
      return ALERT_NAMES.DRIVER_MANAGEMENT;
    },
    link: ALERT_LINKS.DRIVER_MANAGEMENT
  },
  {
    type: ALERT_TYPES.DRIVER_AND_VEHICLE,
    get name() {
      return ALERT_NAMES.DRIVER_AND_VEHICLE;
    },
    link: ALERT_LINKS.DRIVER_AND_VEHICLE
  },
  {
    type: ALERT_TYPES.ELD,
    get name() {
      return ALERT_NAMES.ELD;
    },
    link: ALERT_LINKS.ELD
  },
  {
    type: ALERT_TYPES.SMARTJOBS,
    get name() {
      return ALERT_NAMES.SMARTJOBS;
    },
    link: ALERT_LINKS.SMARTJOBS
  },
  {
    type: ALERT_TYPES.MASS_MANAGEMENT,
    get name() {
      return ALERT_NAMES.MASS_MANAGEMENT;
    },
    link: ALERT_LINKS.MASS_MANAGEMENT
  },
  {
    type: ALERT_TYPES.METER,
    get name() {
      return ALERT_NAMES.METER;
    },
    link: ALERT_LINKS.METER
  },
  {
    type: ALERT_TYPES.GPIO,
    get name() {
      return ALERT_NAMES.GPIO;
    },
    link: ALERT_LINKS.GPIO
  },
  {
    type: ALERT_TYPES.DURESS,
    get name() {
      return ALERT_NAMES.DURESS;
    },
    link: ALERT_LINKS.DURESS
  },
  {
    type: ALERT_TYPES.DOCUMENTEXPIRATION,
    get name() {
      return ALERT_NAMES.DOCUMENTEXPIRATION;
    },
    link: ALERT_LINKS.DOCUMENTEXPIRATION
  },
  {
    type: ALERT_TYPES.TACHO,
    get name() {
      return ALERT_NAMES.TACHO;
    },
    link: ALERT_LINKS.TACHO
  }
];

export const routeTypes = [
  {
    value: 'started',
    get label() {
      return i18n.t('Alerts.RouteCompliance.Started');
    }
  },
  {
    value: 'exit',
    get label() {
      return i18n.t('Alerts.RouteCompliance.OffRoute');
    }
  },
  {
    value: 'finished',
    get label() {
      return i18n.t('Alerts.RouteCompliance.Finished');
    }
  }
];

export const fatigueViolationsNotif = [
  {
    get label() {
      return i18n.t('Alerts.SentinelAlert.On Violation');
    },
    value: 'violation'
  },
  {
    get label() {
      return i18n.t('Alerts.SentinelAlert.15 minutes after');
    },
    value: '15m.after'
  },
  {
    get label() {
      return i18n.t('Alerts.SentinelAlert.30 minutes after');
    },
    value: '30m.after'
  },
  {
    get label() {
      return i18n.t('Alerts.SentinelAlert.45 minutes after');
    },
    value: '45m.after'
  },
  {
    get label() {
      return i18n.t('Alerts.SentinelAlert.60 minutes after');
    },
    value: '60m.after'
  }
];

export const tachoLabels = [
  {
    value: 2,
    get label() {
      return i18n.t('Alerts.TachoAlert.ContinuousDrivingTime');
    }
  },
  {
    value: 4,
    get label() {
      return i18n.t('Alerts.TachoAlert.DailyDriving');
    }
  }
];

export const alarmCodesLabels = [
  {
    value: 1,
    get label() {
      return i18n.t('Alerts.AlarmAlert.External power supply disconnected from IVU');
    }
  },
  {
    value: 2,
    get label() {
      return i18n.t('Alerts.AlarmAlert.External power supply reconnected to IVU');
    }
  },
  {
    value: 1002,
    get label() {
      return i18n.t('Alerts.AlarmAlert.Vehicle moving with no driver logged on');
    }
  },
  {
    value: 1003,
    get label() {
      return i18n.t('Alerts.AlarmAlert.Vehicle moving with driver in rest state');
    }
  },
  {
    value: 1007,
    get label() {
      return i18n.t('Alerts.AlarmAlert.Vehicle moving with iface turned off');
    }
  },
  {
    value: 1014,
    get label() {
      return i18n.t(
        'Alerts.AlarmAlert.Vehicle moving for an extended period with no driver logged on'
      );
    }
  },
  {
    value: 1015,
    get label() {
      return i18n.t(
        'Alerts.AlarmAlert.Vehicle moving for an extended period with driver in rest state'
      );
    }
  },
  {
    value: 2005,
    get label() {
      return i18n.t('Alerts.AlarmAlert.Vehicle rolled over');
    }
  },
  {
    value: 2015,
    get label() {
      return i18n.t('Alerts.AlarmAlert.Vehicle Tow Away');
    },
    can: {
      otherConditions: [() => companyDeviceFeaturesCheck([deviceCapabilities.TowAway], true)]
    }
  },
  {
    value: 2014,
    get label() {
      return i18n.t('Alerts.AlarmAlert.MIL Indicator On');
    }
  },
  {
    value: 2006,
    get label() {
      return i18n.t('Alerts.AlarmAlert.Vehicle seatbelt off while driving');
    }
  },
  {
    value: 26,
    get label() {
      return i18n.t('Alerts.AlarmAlert.Forced logoff');
    }
  },
  {
    value: 2019,
    get label() {
      return i18n.t('Alerts.AlarmAlert.Driver Denied Forced Logoff');
    }
  },
  {
    value: 2021,
    get label() {
      return i18n.t('Alerts.AlarmAlert.ST-Fatigue');
    }
  }
];

export const eldUSAlertViolationLabels = [
  {
    value: 'cycle_onduty',
    get label() {
      return i18n.t('Alerts.ELDAlert.USAlerts.CycleOnDutyTime');
    }
  },
  {
    value: 'shift_drive',
    get label() {
      return i18n.t('Alerts.ELDAlert.USAlerts.WorkShiftDriveTime');
    }
  },
  {
    value: 'shift_onduty',
    get label() {
      return i18n.t('Alerts.ELDAlert.USAlerts.WorkShiftOnDutyTime');
    }
  },
  {
    value: 'shift_onduty_elapse',
    get label() {
      return i18n.t('Alerts.ELDAlert.USAlerts.WorkShiftOnDutyElapsedTime');
    }
  }
];

export const eldCANAlertViolationLabels = [
  {
    value: 'rest_inday',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.DayRest');
    }
  },
  {
    value: 'rest_incycle',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.CycleRest');
    }
  },
  {
    value: 'deferred_drive',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.DeferredDriving');
    }
  },
  {
    value: 'deferred_rest',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.DeferredRest');
    }
  },
  {
    value: 'deferred_too_long',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.DeferredTooLong');
    }
  }
];
export const eldUSAlertWarningLabels = [
  {
    value: 'cycle_onduty',
    get label() {
      return i18n.t('Alerts.ELDAlert.USAlerts.CycleOnDutyTime');
    }
  },
  {
    value: 'shift_drive',
    get label() {
      return i18n.t('Alerts.ELDAlert.USAlerts.WorkShiftDriveTime');
    }
  },
  {
    value: 'shift_onduty',
    get label() {
      return i18n.t('Alerts.ELDAlert.USAlerts.WorkShiftOnDutyTime');
    }
  },
  {
    value: 'shift_onduty_elapse',
    get label() {
      return i18n.t('Alerts.ELDAlert.USAlerts.WorkShiftOnDutyElapsedTime');
    }
  }
];

export const eldCANAlertWarningLabels = [
  {
    value: 'rest_inday',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.DayRest');
    }
  },
  {
    value: 'rest_incycle',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.CycleRest');
    }
  },
  {
    value: 'deferred_drive',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.DeferredDriving');
    }
  },
  {
    value: 'deferred_rest',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.DeferredRest');
    }
  },
  {
    value: 'deferred_too_long',
    get label() {
      return i18n.t('Alerts.ELDAlert.CANAlerts.DeferredTooLong');
    }
  }
];
export const eldCodesLabels = [
  {
    value: 1,
    get label() {
      return i18n.t('Alerts.ELDAlert.395 3(a)(3)(ii) 8 hour rule violation (Property)');
    }
  },
  {
    value: 2,
    get label() {
      return i18n.t('Alerts.ELDAlert.395 3A1R 11 hour rule violation (Property)');
    }
  },
  {
    value: 3,
    get label() {
      return i18n.t('Alerts.ELDAlert.395 3A2R 14 hour rule violation (Property)');
    }
  },
  {
    value: 4,
    get label() {
      return i18n.t('Alerts.ELDAlert.395 3BR 60/70 hour rule violation (Property)');
    }
  }
  //{ value: 5, get label() {return i18n.t('Alerts.ELDAlert.395 1(o) 16 hour rule violation (Property)') }}
];

export const canadaEldCodesLabels = [
  {
    value: 20,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-5, Drive time');
    }
  },
  {
    value: 30,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-5, On-duty time');
    }
  },
  {
    value: 33,
    get label() {
      return i18n.t(
        'Rulesets.SOR/2019-165, s-5, 13(3), Minimum 8-hours rest after 16-hour work shift'
      );
    }
  },
  {
    value: 71,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-5, 14(2) - 8-hour minimum time');
    }
  },
  {
    value: 81,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-5, 14(3) - 10-hour minimum time');
    }
  },
  {
    value: 50,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-5, 16(b) - Less than 20 hours in two days');
    }
  },
  {
    value: 41,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-8, 26 - Cycle 1, 70 hour restriction');
    }
  },
  {
    value: 43,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-8, 27(a) - 70 hours duty, 24 hours rest');
    }
  },
  {
    value: 42,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-9, 27(a) - Cycle 2, 120 hour restriction');
    }
  },
  {
    value: 82,
    get label() {
      return i18n.t(
        'Rulesets.SOR/2019-165, s-6, 18(b) - Split, single Driver, 10-hour minimum time'
      );
    }
  },
  {
    value: 72,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-7, 19 - Split, team Drivers, 8-hour minimum time');
    }
  },
  {
    value: 25,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-11, 39 (Drive) - Drive time');
    }
  },
  {
    value: 35,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-11, 39 (On-Duty) - On-duty time');
    }
  },
  {
    value: 38,
    get label() {
      return i18n.t(
        'Rulesets.SOR/2019-165, s-11, 39(2) - On-duty time (no driving after 20 elapsed since shift start)'
      );
    }
  },
  {
    value: 76,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-11, 39 (Rest) - 8-hour minimum time');
    }
  },
  {
    value: 95,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-14, 50(b) - Mandatory Off Duty in 14-day period');
    }
  },
  {
    value: 46,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-14, 51 - Cycle 1, 80 hour restriction');
    }
  },
  {
    value: 47,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-14, 51(a) - Cycle 2, 120 hour restriction');
    }
  },
  {
    value: 48,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-14, 51(b) - 80 hours duty, 24 hours rest');
    }
  },
  {
    value: 90,
    get label() {
      return i18n.t(
        'Rulesets.SOR/2019-165, s-15, 50 - Minimum 24 consecutive hours rest in the preceding 14 days'
      );
    }
  },
  {
    value: 87,
    get label() {
      return i18n.t(
        'Rulesets.SOR/2019-165, s-12, 41(b) - Split, single Driver, 8-hour minimum time'
      );
    }
  },
  {
    value: 77,
    get label() {
      return i18n.t('Rulesets.SOR/2019-165, s-13, 42 - Split, team Drivers, 8-hour minimum time');
    }
  }
];

const inspectionDvirOptionsIds = {
  dvirFailed: String(nanoid()),
  dvirPassed: String(nanoid()),
  dvirRepaired: String(nanoid())
};
export const inspectionDvirOptionsLabels = [
  {
    id: inspectionDvirOptionsIds.dvirFailed,
    get label() {
      return i18n.t('Alerts.InspectionAlert.DVIRFailed');
    },
    value: 'dvir_failed'
  },
  {
    id: inspectionDvirOptionsIds.dvirPassed,
    get label() {
      return i18n.t('Alerts.InspectionAlert.DVIRPassed');
    },
    value: 'dvir_passed'
  },
  {
    id: inspectionDvirOptionsIds.dvirRepaired,
    get label() {
      return i18n.t('Alerts.InspectionAlert.DVIRRepaired');
    },
    value: 'dvir_repaired'
  }
];

export const duressTypes = [
  {
    get label() {
      return i18n.t('Alerts.DuressAlert.GPIO');
    },
    value: 'GPIO'
  }
  // Commented out as: TN360WEB-4415
  // {
  //   get label() {
  //     return i18n.t('Alerts.DuressAlert.Mandown');
  //   },
  //   value: 'Mandown'
  // },
  // {
  //   get label() {
  //     return i18n.t('Alerts.DuressAlert.Pendant');
  //   },
  //   value: 'Pendant'
  // },
  // {
  //   get label() {
  //     return i18n.t('Alerts.DuressAlert.Workerlink');
  //   },
  //   value: 'Workerlink'
  // }
];

export const signposted = [
  {
    get label() {
      return i18n.t('Alerts.SpeedAlert.Signposted limit');
    },
    value: 'signposted'
  }
];

const ALERT_CODES = {
  COMPLETED: 'completed',
  PARTIALLY_COMPLETED: 'partially_completed',
  NON_DELIVERED: 'non_delivered',
  DRIVER_FEEDBACK: 'driver_feedback'
};

export const smartjobsOptions = [
  {
    value: ALERT_CODES.COMPLETED,
    get label() {
      return i18n.t('Alerts.SmartJobsAlert.CompletedJob');
    }
  },
  {
    value: ALERT_CODES.PARTIALLY_COMPLETED,
    get label() {
      return i18n.t('Alerts.SmartJobsAlert.PartiallyCompleted');
    }
  },
  {
    value: ALERT_CODES.NON_DELIVERED,
    get label() {
      return i18n.t('Alerts.SmartJobsAlert.NonDelivered');
    }
  }
  // {
  //   value: ALERT_CODES.DRIVER_FEEDBACK,
  //   get label() {
  //     return i18n.t('Alerts.SmartJobsAlert.DriverFeedback');
  //   }
  // }
];

const oohOptionIds = {
  onIgnition: String(nanoid()),
  onDistance: String(nanoid()),
  onGeofence: String(nanoid())
};

export const oohOptionsLabels = [
  {
    id: oohOptionIds.onIgnition,
    value: 'io',
    get label() {
      return i18n.t('Alerts.Alert on Ignition On and Off Events');
    }
  },
  {
    id: oohOptionIds.onDistance,
    value: 'movement',
    get label() {
      return i18n.t('Alerts.Alert If Vehicle Moves Or Stops');
    }
  }
  // Removed due to TN360WEB-4614
  // {
  //   id: oohOptionIds.onGeofence,
  //   value: 'geofence',
  //   get label() {
  //     return i18n.t('Alerts.Alert If Vehicle Exits Or Enters a Geofence');
  //   }
  // }
];

const geofenceOptionsIds = {
  entry: String(nanoid()),
  exit: String(nanoid()),
  thrsh_undertime: String(nanoid()),
  thrsh_overtime: String(nanoid()),
  managed_entry: String(nanoid()),
  managed_exit: String(nanoid())
};

export const geofenceOptionsLabels = [
  {
    id: geofenceOptionsIds.entry,
    value: 'entry',
    get label() {
      return i18n.t('Alerts.Entry into Geofence');
    }
  },
  {
    id: geofenceOptionsIds.exit,
    value: 'exit',
    get label() {
      return i18n.t('Alerts.Exit out of Geofence');
    }
  },
  {
    id: geofenceOptionsIds.thrsh_undertime,
    value: 'thrsh_undertime',
    get label() {
      return i18n.t('Alerts.Under allocated time inside of Geofence');
    }
  },
  {
    id: geofenceOptionsIds.thrsh_overtime,
    value: 'thrsh_overtime',
    get label() {
      return i18n.t('Alerts.Over allocated time of Geofence');
    }
  }
];

export const managedGeofenceOptionsLabels = [
  {
    id: geofenceOptionsIds.managed_entry,
    value: 'entry',
    get label() {
      return i18n.t('Alerts.Entry into Managed Geofence');
    },
    disable: managedGeofences => !managedGeofences?.length
  },
  {
    id: geofenceOptionsIds.managed_exit,
    value: 'exit',
    get label() {
      return i18n.t('Alerts.Exit out of Managed Geofence');
    },
    disable: managedGeofences => !managedGeofences?.length
  }
];

const typesForFleetsAndVehicles = [
  ALERT_TYPES.CAMERA,
  ALERT_TYPES.ENGINE,
  ALERT_TYPES.FORM,
  ALERT_TYPES.GEOFENCE,
  ALERT_TYPES.PRETRIP,
  ALERT_TYPES.ROUTE,
  ALERT_TYPES.SPEED,
  ALERT_TYPES.VEHICLE_MAINTENANCE,
  ALERT_TYPES.DRIVER_AND_VEHICLE,
  ALERT_TYPES.SMARTJOBS,
  ALERT_TYPES.OOH,
  ALERT_TYPES.MASS_MANAGEMENT,
  ALERT_TYPES.METER,
  ALERT_TYPES.GPIO,
  ALERT_TYPES.DURESS
];

const typesForDriversAndBranches = [
  ALERT_TYPES.SENTINEL,
  ALERT_TYPES.DRIVER_MANAGEMENT,
  ALERT_TYPES.ELD,
  ALERT_TYPES.TACHO
];

export const usesFleetsAndVehicles = alertType => typesForFleetsAndVehicles.includes(alertType);
export const usesDriversAndBranches = alertType => typesForDriversAndBranches.includes(alertType);

export const Paths = {
  ALERTS_DEFAULT: '/settings/alerts',
  NEW_ALERT: '/settings/alerts/new/:type',
  EDIT_ALERT: '/settings/alerts/edit/:type/:id',
  CLONE_ALERT: '/settings/alerts/new/:type/:clone/:id',
  ALERT_VIEW: '/settings/alerts/view/:type'
};

export const eventSupportMultiTiered = ['IT'];
