import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { prepareDataForMultiselect } from 'utils/filters';
import useDebounce from 'utils/hooks/useDebounce';
import { toLower } from 'lodash';
import { sortStrings } from 'utils/strings';

import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { SortDirection } from 'react-virtualized';
import {
  useDevices,
  useFleets,
  useIsFetching,
  useVehiclesFromFleets
} from 'features/fleets/fleetsSlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import { useGetAssetConfigurationTemplatesQuery } from 'services/nextgen/ngAssetConfigurationApi';

//components
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { TabNavLink } from 'components/nav/NavLinks';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import { PATHS as ASSETS_PATHS } from './constants';
import { DevicesTable } from './DevicesTable';

export const Devices = ({ tab = 'all' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const types = useDeviceTypesList();
  const iFace = types.find(type => type.code.toLowerCase() === 'iface');
  const external = types.find(type => type.code.toLowerCase() === 'external');
  const currentCompany = useCurrentCompany();
  const allDevices = useDevices();
  const fleets = useFleets();
  const isFetchingFleets = useIsFetching();
  const { vehicles, isFetchingVehicles } = useVehiclesFromFleets();
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterVehicles, setFilterVehicles] = useState([]);
  const [filterVehicleTypes, setFilterVehicleTypes] = useState([]);
  const [filterModelOptions, setFilterModelOptions] = useState([]);
  const [filterTemplates, setFilterTemplates] = useState([]);
  const { data, isFetching } = useGetAssetConfigurationTemplatesQuery(
    { companyId: currentCompany?.id, embed: 'devices' },
    { skip: currentCompany?.id === undefined }
  );

  const templates = useMemo(() => {
    const configurationTemplates = (data || []).map(i => {
      const template = i.configurationTemplate;
      return { ...template, associations: { devices: i.associatedDevices } };
    });
    return configurationTemplates;
  }, [currentCompany?.id, data, isFetching]);

  const deviceTemplateMap = useMemo(() => {
    const map = {};
    templates.forEach(template => {
      template.associations.devices.forEach(device => {
        map[device.id] = template;
      });
    });
    return map;
  }, [templates]);

  const debouncedSearchText = useDebounce(filterText, 300);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const vehicleMap = vehicles.reduce((map, vehicle) => {
    map[vehicle.id] = vehicle;
    return map;
  }, {});
  const filteredDevices = useMemo(
    () =>
      allDevices
        .filter(
          device =>
            (device?.type?.id === iFace.id || device?.type?.id === external.id) &&
            device?.company?.id === currentCompany.id &&
            device?.services?.includes('ASSETSUSAGE')
        )
        .map(device => {
          const template = deviceTemplateMap[device.id];
          const vehicle = device.vehicle ? vehicleMap[device.vehicle.id] : null;
          return { ...device, vehicle: vehicle, template: template };
        }),
    [iFace, external, currentCompany, allDevices, deviceTemplateMap]
  );
  const [devices, setDevices] = useState(filteredDevices);

  const handleSort = ({ sortBy: vSortBy }) => {
    if (sortBy === vSortBy) {
      if (sortDirection === SortDirection.ASC) {
        setSortDirection(SortDirection.DESC);
      } else if (sortDirection === SortDirection.DESC) {
        setSortDirection(null);
      } else {
        setSortDirection(SortDirection.ASC);
      }
    } else {
      setSortBy(vSortBy);
      setSortDirection(SortDirection.ASC);
    }
  };

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.DeviceConfigurations.AssetsConfig.Title')));
    dispatch(setBackButton(false));

    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {
          breadcrumbName: t('CompanyConfig.DeviceConfigurations.Title'),
          path: PATHS.DEVICE_CONFIG_LINKS
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch, t]);

  useEffect(() => {
    const models = filteredDevices.filter(device => device.model).map(device => device.model);
    const uniqueModels = [...new Set(models.map(item => item.id))].map(id =>
      models.find(item => item.id === id)
    );
    setFilterModelOptions(
      prepareDataForMultiselect(
        uniqueModels.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        t('Common.AllModels'),
        null
      )
    );
  }, [filteredDevices]);

  useEffect(() => {
    const vehicles = filteredDevices.filter(device => device.vehicle).map(device => device.vehicle);
    const uniqueVehicles = [...new Set(vehicles.map(item => item.id))].map(id =>
      vehicles.find(item => item.id === id)
    );
    setFilterVehicles(
      prepareDataForMultiselect(
        uniqueVehicles.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        t('Common.AllVehicles'),
        null
      )
    );
  }, [filteredDevices, isFetchingVehicles, vehicles, t]);

  useEffect(() => {
    if (isFetchingVehicles) {
      return;
    }
    const uniqueVehicleTypeIds = new Set();
    const uniqueVehicleTypes = [];
    filterVehicles.forEach(v => {
      const vehicle = vehicleMap[v.id];
      if (vehicle?.type) {
        if (!uniqueVehicleTypeIds.has(vehicle.type.id)) {
          uniqueVehicleTypes.push(vehicle.type);
          uniqueVehicleTypeIds.add(vehicle.type.id);
        }
      }
    });
    setFilterVehicleTypes(
      prepareDataForMultiselect(
        uniqueVehicleTypes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        t('Common.AllVehicleTypes'),
        null
      )
    );
  }, [filterVehicles, isFetchingVehicles, vehicles, t]);

  useEffect(() => {
    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && currentCompany.id === fleet.company.id
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets')));
  }, [fleets, t]);

  useEffect(() => {
    let devicesToFilter = filteredDevices;
    setDevices(
      devicesToFilter
        .filter(device => {
          let validDevice = true;

          //Filter by device category
          if (tab === 'all') {
            validDevice = true;
          } else if (tab === 'assigned') {
            validDevice = !!device.template;
          }
          if (tab === 'unassigned') {
            validDevice = !device.template;
          }

          //Filter by search field

          if (debouncedSearchText) {
            validDevice =
              validDevice &&
              [
                device.name,
                device?.vehicle?.name,
                device?.imei,
                device?.vehicle?.registration,
                device?.serialNumber
              ].some(value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1);
          }

          //Filter by fleets
          let checkedFleetsIds = filterFleets
            .filter(fleet => fleet.checked)
            .map(fleet => parseInt(fleet.id, 10));
          if (!(checkedFleetsIds.indexOf(0) > -1)) {
            let isDeviceInFleet = false;
            (device.fleetInfo || []).forEach(fleet => {
              isDeviceInFleet =
                isDeviceInFleet || checkedFleetsIds.indexOf(parseInt(fleet.id, 10)) > -1;
            });
            if (!isDeviceInFleet && checkedFleetsIds.indexOf(-1) > -1) {
              isDeviceInFleet =
                !Array.isArray(device.fleetInfo) || !device.fleetInfo.find(fleet => true)?.id;
            }
            validDevice = validDevice && isDeviceInFleet;
          }

          // Filter by Model
          const checkedModelIds = filterModelOptions
            .filter(model => model.checked)
            .map(model => parseInt(model.id, 10));
          if (!(checkedModelIds.indexOf(0) > -1)) {
            validDevice =
              validDevice && checkedModelIds.indexOf(parseInt(device.model.id, 10)) > -1;
          }

          //Filter by vehicle
          const checkedVehicleIds = filterVehicles
            .filter(vehicle => vehicle.checked)
            .map(vehicle => parseInt(vehicle.id, 10));
          if (!(checkedVehicleIds.indexOf(0) > -1)) {
            validDevice =
              validDevice && device.vehicle && checkedVehicleIds.indexOf(device.vehicle.id) > -1;
          }

          //Filter by vehicle
          const checkedVehicleTypeIds = filterVehicleTypes
            .filter(vehicleType => vehicleType.checked)
            .map(vehicleType => parseInt(vehicleType.id, 10));
          if (!(checkedVehicleTypeIds.indexOf(0) > -1)) {
            validDevice =
              validDevice &&
              device.vehicle &&
              checkedVehicleTypeIds.indexOf(vehicleMap[device.vehicle.id]?.type?.id) > -1;
          }

          if (tab !== 'unassigned') {
            // Filter by Model
            const checkedTemplateIds = filterTemplates
              .filter(template => template.checked)
              .map(template => parseInt(template.id, 10));
            if (!(checkedTemplateIds.indexOf(0) > -1)) {
              validDevice =
                validDevice &&
                (checkedTemplateIds.indexOf(device.template?.id) > -1 ||
                  (checkedTemplateIds.indexOf(-1) > -1 && device?.template?.id === undefined));
            }
          }

          return validDevice;
        })
        .sort((a, b) => {
          let compare = 0;
          if (sortDirection) {
            if (sortBy === 'name') {
              compare = sortStrings(a?.name, b?.name);
            } else if (sortBy === 'vehicle') {
              compare = sortStrings(a?.vehicle?.name, b?.vehicle?.name);
            } else if (sortBy === 'model') {
              compare = sortStrings(a?.model?.name, b?.model?.name);
            } else if (sortBy === 'vehicleType') {
              compare = sortStrings(a?.vehicle?.type?.name, b?.vehicle?.type?.name);
            } else if (sortBy === 'template') {
              compare = sortStrings(a?.template?.name, b?.template?.name);
            }
            if (sortDirection === SortDirection.DESC) {
              compare = compare * -1;
            }
          }
          return compare;
        })
    );
  }, [
    filteredDevices,
    history.location.state,
    filterFleets,
    filterText,
    debouncedSearchText,
    filterModelOptions,
    filterVehicles,
    filterVehicleTypes,
    filterTemplates,
    sortBy,
    sortDirection,
    tab
  ]);

  useEffect(() => {
    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && currentCompany.id === fleet.company.id
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets')));
  }, [fleets, history, t]);

  useEffect(() => {
    const templateOptions = [
      ...templates,
      { id: -1, name: t('CompanyConfig.DeviceConfigurations.AssetsTemplates.None') }
    ];
    setFilterTemplates(
      prepareDataForMultiselect(
        templateOptions,
        t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate')
      )
    );
  }, [templates, history, t]);

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink exact to={ASSETS_PATHS.ASSETS_DEFAULT}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate')}
            </TabNavLink>
            <TabNavLink to={ASSETS_PATHS.ASSETS_CONFIGURED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Configured')}
            </TabNavLink>
            <TabNavLink exact to={ASSETS_PATHS.ASSETS_DELETED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Deleted')}
            </TabNavLink>
          </div>
        </HeaderPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink exact to={ASSETS_PATHS.ASSETS_CONFIGURED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.All')}
            </TabNavLink>
            <TabNavLink exact to={ASSETS_PATHS.ASSETS_CONFIGURED_ASSIGNED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Assigned')}
            </TabNavLink>
            <TabNavLink exact to={ASSETS_PATHS.ASSETS_CONFIGURED_UNASSIGNED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Unassigned')}
            </TabNavLink>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
            <AntMultiselect
              title={
                filterModelOptions?.some(value => !value.checked)
                  ? t('Common.Models')
                  : t('Common.AllModels')
              }
              data={filterModelOptions}
              onFilter={v => {
                setFilterModelOptions(v);
              }}
            />
            <AntMultiselect
              title={
                filterVehicles?.some(value => !value.checked)
                  ? t('Common.Vehicles')
                  : t('Common.AllVehicles')
              }
              data={filterVehicles}
              onFilter={v => {
                setFilterVehicles(v);
              }}
            />
            <AntMultiselect
              title={
                filterVehicleTypes?.some(value => !value.checked)
                  ? t('Common.VehicleTypes')
                  : t('Common.AllVehicleTypes')
              }
              data={filterVehicleTypes}
              onFilter={v => {
                setFilterVehicleTypes(v);
              }}
            />
            {tab !== 'unassigned' && (
              <AntMultiselect
                title={
                  filterTemplates?.some(value => !value.checked)
                    ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template')
                    : t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate')
                }
                data={filterTemplates}
                onFilter={t => {
                  setFilterTemplates(t);
                }}
              />
            )}
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {devices.length} {devices.length === 1 ? t('Devices.Device') : t('Devices.Devices')}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          <DevicesTable
            devices={devices}
            isLoading={isFetchingFleets}
            handleSort={handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          ></DevicesTable>
        </div>
      </ContainerPageWrapper>
    </>
  );
};
