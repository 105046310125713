import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import {
  useCompanies,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import InfoRow from 'components/form/info-row/InfoRow';
import { setBackButton, setPageTitle, addBackButtonLink } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { LoadingCentered } from 'components/loading/Loading';
import styles from './GPIO.module.scss';
import { Table } from 'antd';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import {
  useConfigMapping,
  enrichWithExtraServiceConfig,
  formatDiginData,
  formatDigoutData,
  toTableStructure
} from './helpers';
//constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useGpioRelatedConfigKeys } from 'services/nextgen/ngConfigKeyApi';
import { format } from 'utils/dates';
import {
  useGetGpioConfigurationTemplateByIdQuery,
  useRestoreGpioConfigurationTemplateByIdMutation,
  useDeleteGpioConfigurationTemplateByIdMutation,
  executeDeleteGpioConfigurationTemplate,
  executeRestoreGpioConfigurationTemplate,
  useCurrentCompanyServicesMap
} from 'services/nextgen/ngGpioConfigurationApi';
import { PATHS, gpioDetailColumns, BPM_VPM_KEYS, SIREN_KEYS, IDLE_KEYS } from './constants';
import { services } from 'features/permissions';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useUser } from 'features/user/userSlice';
import { sortBy } from 'lodash';

export const GPIOView = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );

  const history = useHistory();
  const { data: gpioTemplate, isFetching } = useGetGpioConfigurationTemplateByIdQuery(
    { templateId: id, embed: 'devices' },
    { skip: id === undefined }
  );

  const {
    gpioData,
    vpmData,
    bpmData,
    speedsirenData,
    isFetching: isFetchingConfig
  } = useGpioRelatedConfigKeys();
  const { pathname } = useLocation();
  const [restoreGpioConfigurationTemplateById] = useRestoreGpioConfigurationTemplateByIdMutation();
  const [deleteGpioConfigurationTemplateById] = useDeleteGpioConfigurationTemplateByIdMutation();
  const [inputTableData, setInputTableData] = useState([]);
  const [outputTableData, setOutputTableData] = useState([]);
  const serviceMap = useCurrentCompanyServicesMap();

  const {
    inBiasMap,
    inTriggerMap,
    inOccurrenceMap,
    outBiasMap,
    outTriggerMap,
    outOccurrenceMap,
    diginFormConfig,
    digoutFormConfig,
    channelTypes
  } = useConfigMapping(gpioData);
  const dispatch = useDispatch();
  const companies = useCompanies();

  useRedirectToMainFeaturePageOnCompanyChange('/settings/company_config/gpio');

  const handleFetchError = useCallback(() => {
    history.replace(PATHS.GPIO_DEFAULT);
  }, [history]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        gpioTemplate?.configurationTemplate?.name && `${gpioTemplate?.configurationTemplate?.name}`
      )
    );

    dispatch(setBackButton(true));
    addBackButtonLink({
      url: pathname,
      backLink: PATHS.GPIO_DEFAULT
    });
  }, [dispatch, gpioTemplate]);

  useEffect(() => {
    if (gpioTemplate && !isFetchingConfig) {
      const diginIODefinition = diginFormConfig.find(cfgField => cfgField.type === 'io');
      const digoutIODefinition = digoutFormConfig.find(cfgField => cfgField.type === 'io');

      if (!diginIODefinition || !digoutIODefinition) {
        return;
      }
      let requiredVpmData = vpmData?.filter(s => BPM_VPM_KEYS.includes(s.key));
      let requiredBpmData = bpmData?.filter(s => BPM_VPM_KEYS.includes(s.key));
      let requiredSirenData = speedsirenData?.filter(s => SIREN_KEYS.includes(s.key));
      let requiredIdleData =
        vpmData?.length > 0
          ? vpmData?.filter(s => IDLE_KEYS.includes(s.key))
          : bpmData?.filter(s => IDLE_KEYS.includes(s.key));

      let configurations = sortBy(
        enrichWithExtraServiceConfig(
          gpioTemplate?.configurationTemplate?.configurations || [],
          serviceMap,
          diginIODefinition,
          digoutIODefinition,
          channelTypes,
          localization
        ),
        'channel'
      );

      setInputTableData(
        configurations
          ?.filter(i => channelTypes.some(t => t.form === 'digin' && i.input.startsWith(t.key)))
          .map(c => {
            return formatDiginData(
              c,
              inBiasMap,
              inOccurrenceMap,
              inTriggerMap,
              diginIODefinition,
              true
            );
          })
      );

      setOutputTableData(
        configurations
          ?.filter(i => channelTypes.some(t => t.form === 'digout' && i.input.startsWith(t.key)))
          .map(c => {
            return formatDigoutData(
              c,
              outBiasMap,
              outOccurrenceMap,
              outTriggerMap,
              requiredVpmData,
              requiredBpmData,
              requiredIdleData,
              requiredSirenData,
              true
            );
          })
      );
    }
  }, [gpioTemplate, isFetchingConfig, gpioData]);

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        confirmationModal(
          `${t('Common.DeleteButton')} ${gpioTemplate?.configurationTemplate?.name}`,
          `${
            gpioTemplate?.configurationTemplate?.default
              ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeleteCompanyTemplateWarning')
              : t('Common.SureDelete') + ' ' + gpioTemplate?.configurationTemplate?.name + '?'
          }`,
          t('Common.DeleteButton'),
          t('Common.CancelButton'),
          () => {
            executeDeleteGpioConfigurationTemplate(
              { id: id, name: gpioTemplate?.configurationTemplate?.name },
              deleteGpioConfigurationTemplateById,
              dispatch
            );
          },
          'delete'
        );
        break;
      case 'restore':
        executeRestoreGpioConfigurationTemplate(
          { id: id, name: gpioTemplate?.configurationTemplate?.name },
          restoreGpioConfigurationTemplateById,
          dispatch
        );
        break;
      default:
    }
  };

  useEffect(() => {
    if (id <= 0 || isNaN(id)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [id, dispatch, handleFetchError]);

  const renderDevices = () => {
    if (gpioTemplate?.associatedDevices?.length !== 0) {
      return gpioTemplate?.associatedDevices.map(device => {
        return (
          <div key={`device-${device.id}`}>
            <Link to={`/settings/devices/id/${device.id}`}>{device.imei}</Link>
          </div>
        );
      });
    } else {
      return '-';
    }
  };

  if (isFetching) {
    return <LoadingCentered />;
  }

  const inGPIOData = toTableStructure(channelTypes, inputTableData);
  const outGPIOData = toTableStructure(channelTypes, outputTableData);

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{
          entityName: AUDIT_ENTITY.CONFIGURATION_TEMPLATE,
          ...gpioTemplate?.configurationTemplate
        }}
        editPath={`/settings/company_config/gpio/edit/id/${id}`}
        auditPath={`/settings/company_config/gpio/audit/id/${id}`}
        handleButtonAction={handleButtonAction}
        skipDeleteConfirmation={true}
        disableDelete={!isSiteAdmin}
        disableRestore={!isSiteAdmin}
        canService={[services.GPIO]}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
        className={styles.gpioView}
      >
        <DeletedEntityAlert
          entity={DeletableEntityType.ConfigurationTemplate}
          entityStatus={gpioTemplate?.status}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Company')}
          value={
            companies.find(comp => comp.id === gpioTemplate?.configurationTemplate?.company?.id)
              ?.name
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Template')}
          value={gpioTemplate?.configurationTemplate?.name}
          sxValue={{ width: '75%' }}
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Description')}
          value={gpioTemplate?.configurationTemplate?.description}
          sxValue={{ width: '75%', whiteSpace: 'pre-wrap' }}
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.IsDefaultTemplate')}
          sxValue={{ width: '75%' }}
          value={gpioTemplate?.configurationTemplate?.default ? t('Common.Yes') : t('Common.No')}
          styles={styles}
        />
        {inGPIOData?.length > 0 &&
          inGPIOData.map(digin => {
            return (
              digin.data?.length > 0 && (
                <InfoRow
                  label={t('CompanyConfig.DeviceConfigurations.View.' + digin.value, digin.value)}
                  value={
                    digin.data.length > 0 ? (
                      <Table
                        dataSource={digin.data}
                        columns={gpioDetailColumns(t)}
                        styles={styles}
                        pagination={false}
                        customRowStyle={{ alignItems: 'center', background: '#fff' }}
                      />
                    ) : (
                      '-'
                    )
                  }
                  styles={styles}
                  sxValue={{
                    width: '75%',
                    minHeight: `${digin.data.length * 64 + 32}px`
                  }}
                />
              )
            );
          })}
        {outGPIOData?.length > 0 &&
          outGPIOData.map(digout => {
            return (
              digout.data?.length > 0 && (
                <InfoRow
                  label={t('CompanyConfig.DeviceConfigurations.View.' + digout.value, digout.value)}
                  value={
                    digout.data.length > 0 ? (
                      <Table
                        dataSource={digout.data}
                        columns={gpioDetailColumns(t)}
                        styles={styles}
                        pagination={false}
                        customRowStyle={{ alignItems: 'center', background: '#fff' }}
                      />
                    ) : (
                      '-'
                    )
                  }
                  styles={styles}
                  sxValue={{
                    width: '75%',
                    minHeight: `${digout.data.length * 64 + 32}px`
                  }}
                />
              )
            );
          })}
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Seatbelt Speed Threshold')}
          sxValue={{ width: '75%' }}
          value={
            localization.convertDistance(
              gpioTemplate?.configurationTemplate?.configurations?.find(
                item => item.key === 'seatbelt.speed.threshold'
              )?.value || 8,
              0
            ) +
            ' ' +
            localization.formats.speed.unit_per_hour
          }
          styles={styles}
        />
        <InfoRow
          label={t('Devices.Devices')}
          value={renderDevices()}
          styles={styles}
          sxValue={{ width: '75%' }}
        />
        <InfoRow
          sxValue={{ width: '75%' }}
          label={t('CompanyConfig.DeviceConfigurations.View.CreatedAt')}
          value={
            gpioTemplate?.configurationTemplate?.createdAt
              ? format(
                  new Date(gpioTemplate?.configurationTemplate?.createdAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.UpdatedAt')}
          sxValue={{ width: '75%' }}
          value={
            gpioTemplate?.configurationTemplate?.updatedAt
              ? format(
                  new Date(gpioTemplate?.configurationTemplate?.updatedAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
      </div>
    </React.Fragment>
  );
};
