import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ConfigCard } from './Card';
import { ELDConfigModal } from './ELDConfigModal';

import { UserDefinedModal } from './Modals/UserDefinedModal/UserDefinedModal';
import { VehicleMaintenanceModal } from './Modals/VehicleMaintenanceModal/VehicleMaintenanceModal';

import { setPageTitle } from 'features/page/pageSlice';
import {
  FeatureFlag,
  services,
  entities,
  companyFeatures,
  GlobalRoles,
  Can,
  useCanFeatureFlag
} from 'features/permissions';
import { PATHS, UNIQUE_IDS } from './utils/constants';

import styles from './CompanyConfig.module.scss';
import { VehicleLoginLimitModal } from './Modals/VehicleLoginLimit/VehicleLoginLimitModal';
import { useHistory } from 'react-router';

export const CompanyConfig = () => {
  const speedAssistFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.deviceConfig_speedAssist.flag
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const getForceShowCondition = c => {
    return c.id === UNIQUE_IDS.ELD && history?.location?.state?.forceShow;
  };

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.Title')));
  }, [dispatch, t]);

  const configList = [
    {
      title: 'Common.ELDFatigue',
      shortDescription: 'Edit ELD Carrier Configuration',
      permissions: {
        everyRole: [GlobalRoles.Admin],
        everyService: [services.ELD]
      },
      modal: ELDConfigModal,
      navigateTo: PATHS.ELD_CARRIER,
      id: UNIQUE_IDS.ELD
    },
    {
      title: 'CompanyConfig.UserDefinedFields.DriverManagementTitle',
      shortDescription: 'CompanyConfig.UserDefinedFields.Description',
      permissions: {
        everyRole: [GlobalRoles.Admin]
      },
      modal: UserDefinedModal,
      modalDetails: 'driverManagement',
      navigateTo: null,
      id: UNIQUE_IDS.DRIVER_MANAGEMENT
    },
    {
      title: 'CompanyConfig.UserDefinedFields.VehicleMaintenanceTitle',
      shortDescription: 'CompanyConfig.UserDefinedFields.Description',
      permissions: {
        everyRole: [GlobalRoles.Admin],
        featureHide: companyFeatures.HTF
      },
      modal: VehicleMaintenanceModal,
      modalDetails: 'vehicleMaintenance',
      navigateTo: null,
      id: UNIQUE_IDS.VEHICLE_MAINTENANCE
    },
    {
      title: 'CompanyConfig.VehicleLoginLimit.Title',
      shortDescription: 'CompanyConfig.VehicleLoginLimit.Description',
      permissions: {
        everyRole: [GlobalRoles.Admin]
      },
      modal: VehicleLoginLimitModal,
      navigateTo: null,
      id: UNIQUE_IDS.VEHICLE_LOGIN_LKIMIT
    },
    {
      title: 'CompanyConfig.BulkManagement.Title',
      shortDescription: 'CompanyConfig.BulkManagement.Description',
      permissions: {
        everyEntity: [entities.BULKIMPORT],
        oneOfEntities: [
          entities.USER_CREATE,
          entities.VEHICLE_CREATE,
          entities.GEOFENCE_CREATE,
          entities.LOCATION_CREATE
        ]
      },
      navigateTo: PATHS.BULK_MANAGEMENT,
      id: UNIQUE_IDS.BULK_MANAGEMENT
    },
    {
      title: 'CompanyConfig.SmartjobsIntegration.Title',
      shortDescription: 'CompanyConfig.SmartjobsIntegration.Description',
      permissions: {
        everyRole: [GlobalRoles.SiteAdmin],
        featureFlag: FeatureFlag.npi.flag
      },
      modal: UserDefinedModal,
      modalDetails: 'smartjobsIntegration',
      navigateTo: null,
      id: UNIQUE_IDS.SMARTJOBS_INTEGRATIONS
    },
    {
      title: 'CompanyConfig.HideNonBusiness.Title',
      shortDescription: 'CompanyConfig.HideNonBusiness.Description',
      permissions: {
        everyRole: [GlobalRoles.Admin],
        oneOfServices: [services.FBTMANAGER, services.HIDENONBUSINESS]
      },
      modal: UserDefinedModal,
      modalDetails: 'hideNonBusiness',
      navigateTo: null,
      id: UNIQUE_IDS.HIDE_NON_BUSINESS_TRIPS
    },
    {
      title: 'CompanyConfig.IQCamera.Title',
      shortDescription: 'CompanyConfig.IQCamera.Description',
      permissions: {
        oneOfRoles: [GlobalRoles.Admin, GlobalRoles.IQCameraAdministrator],
        everyCompanyService: [services.IQ]
      },
      navigateTo: PATHS.IQ_CAMERA,
      id: UNIQUE_IDS.IQ_CAMERA
    },
    {
      title: 'CompanyConfig.SmartDashcam.Title',
      shortDescription: 'CompanyConfig.SmartDashcam.Description',
      permissions: {
        everyRole: [GlobalRoles.Admin],
        everyCompanyService: [services.CAMERA, services.NETRADYNE]
      },
      modal: UserDefinedModal,
      modalDetails: 'smartDashcam',
      navigateTo: null,
      id: UNIQUE_IDS.SMART_DASHCAM
    },
    {
      title: 'CompanyConfig.SharedLinks.Title',
      shortDescription: 'CompanyConfig.SharedLinks.Description',
      navigateTo: PATHS.SHARED_LINKS,
      id: UNIQUE_IDS.SHARED_LINKS
    },
    {
      title: 'CompanyConfig.CompanyAgreement.Title',
      shortDescription: 'CompanyConfig.CompanyAgreement.Description',
      permissions: {
        oneOfRoles: [GlobalRoles.SiteAdmin, GlobalRoles.Reseller, GlobalRoles.SubCompanyAdmin]
      },
      navigateTo: PATHS.AGREEMENT_LINKS,
      id: UNIQUE_IDS.AGREEMENT_LINKS
    },
    {
      title: 'CompanyConfig.DeviceConfigurations.Title',
      shortDescription: 'CompanyConfig.DeviceConfigurations.Description',
      permissions: {
        oneOfCompanyServices: [services.DRIVERPIN, services.SPEEDASSIST, services.GPIO]
      },
      navigateTo: PATHS.DEVICE_CONFIG_LINKS,
      id: UNIQUE_IDS.DEVICE_CONFIGURATIONS
    }
  ];

  return (
    <div className={styles.companyConfig}>
      {configList.map((c, idx) => {
        return (
          <Can key={idx} {...c.permissions}>
            <ConfigCard
              title={t(c.title)}
              shortDescription={t(c.shortDescription)}
              modal={c.modal}
              modalDetails={c.modalDetails}
              navigateTo={c.navigateTo}
              id={c.id}
              forceShow={getForceShowCondition(c)}
            />
          </Can>
        );
      })}
    </div>
  );
};
