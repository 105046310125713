export const PATHS = {
  BULK_MANAGEMENT: '/settings/company_config/bulkManagement',
  COMPANY_CONFIG: '/settings/company_config',
  ELD_CARRIER: '/settings/company_config/eldCarrier',
  IQ_CAMERA: '/settings/company_config/iq_camera',
  IQ_CAMERA_CONFIG_AUDIT: '/settings/company_config/iq_camera/config_audit',
  IQ_CAMERA_ASSOCIATION_AUDIT: '/settings/company_config/iq_camera/association_audit',
  SHARED_LINKS: '/settings/company_config/sharedLinks',
  AGREEMENT_LINKS: '/settings/company_config/agreement',
  DEVICE_CONFIG_LINKS: '/settings/company_config/device_config',
  GPIO_LINKS: '/settings/company_config/gpio',
  ASSETS_LINKS: '/settings/company_config/assetsusage',
  SPEEDASSIST_LINKS: '/settings/company_config/speedassist',
  LOG_ON_EVENTS: '/settings/company_config/log_on,_events',
  DRIVER_ID: '/settings/company_config/driverId'
};

// Unique identifiers for WalkMe intergration
export const UNIQUE_IDS = {
  ELD: 'CompanyConfig-ELD',
  DRIVER_MANAGEMENT: 'CompanyConfig-DriverManagement',
  VEHICLE_MAINTENANCE: 'CompanyConfig-VehicleMaintenance',
  VEHICLE_LOGIN_LKIMIT: 'CompanyConfig-VehicleLoginLimit',
  BULK_MANAGEMENT: 'CompanyConfig-BulkManagement',
  HIDE_NON_BUSINESS_TRIPS: 'CompanyConfig-HideNonBusinessTrips',
  SMARTJOBS_INTEGRATIONS: 'CompanyConfig-SmartjobsIntegration',
  IQ_CAMERA: 'CompanyConfig-IQCamera',
  SMART_DASHCAM: 'CompanyConfig-SmartDashcam',
  SHARED_LINKS: 'CompanyConfig-SharedLinks',
  DEVICE_CONFIGURATIONS: 'CompanyConfig-DeviceConfigurations',
  GPIO_CONFIGURATIONS: 'CompanyConfig-GPIOConfigurations',
  ASSETS_CONFIGURATIONS: 'CompanyConfig-AssetsConfigurations',
  SPEEDASSIST_CONFIGURATIONS: 'CompanyConfig-SpeedAssistConfigurations',
  AGREEMENT_LINKS: 'CompanyConfig-AgreementLinks',
  LOG_ON_EVENTS_CONFIGURATIONS: 'CompanyConfig-LogOnEventsConfigurations',
  DRIVER_ID: 'CompanyConfig-DriverId'
};
