import React, { useState, useEffect } from 'react';
import { Form, Card, Space, Alert, Switch, Button, Select, InputNumber } from 'antd';
import { BUTTON_IDS } from 'utils/globalConstants';
import { inputValidator } from '../helpers';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import { useDispatch } from 'react-redux';
import styles from '../Devices.module.scss';
import { useCurrentCompany } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { UNITS } from 'features/localization/localization';
import { services } from 'features/permissions';
import { useUpdateConfigsMutation, executeUpdateConfig } from 'services/nextgen/ngConfigKeyApi';
import {
  useSelectedConfigurationTemplateMutation,
  useSettingValueForOneDeviceMutation,
  useSaveTheSettingValuesMutation,
  useGetSpeedAssistConfigurationTemplatesQuery
} from 'services/nextgen/ngSpeedAssistConfigurationApi';
import { useTranslation } from 'react-i18next';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';

export const SpeedAssist = ({ data, onClose, initialValues, selectedId }) => {
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const types = useDeviceTypesList();
  const [selectedTemplateName, setSelectedTemplateName] = useState(selectedId?.name);
  const [selectedConfigurationTemplate] = useSelectedConfigurationTemplateMutation();
  const [settingValues] = useSettingValueForOneDeviceMutation();
  const [saveTheSettingValues] = useSaveTheSettingValuesMutation();
  const [isTemplateSelected, setIsTemplateSelected] = useState(!!selectedId?.id);
  const [isOverrideSettingsEnabled, setIsOverrideSettingsEnabled] = useState(false);
  const [previousTemplateId, setPreviousTemplateId] = useState(selectedId?.id);
  const [isDropdownEnabled, setIsDropdownEnabled] = useState(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const supportedServices = [
    {
      code: services.SPEEDASSIST,
      getServiceCodes: () => [services.SPEEDASSIST],
      condition: () =>
        (data.services?.includes(services.SPEEDASSIST) &&
          types.find(type => type.id === data?.type?.id)?.name?.toUpperCase() === 'EXTERNAL') ||
        'IFACE'
    }
  ];
  const [updateConfigs] = useUpdateConfigsMutation();

  const { data: speedassistConfigurationTemplates } = useGetSpeedAssistConfigurationTemplatesQuery({
    companyId: currentCompany?.id
  });
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setSelectedTemplateName(initialValues.speedassistTemplateId);
      setPreviousTemplateId(initialValues.speedassistTemplateId);
      if (initialValues.speedassistTemplateId) {
        setIsOverrideSettingsEnabled(false);
      }
      if (initialValues.speedassistTemplateId === '') {
        setIsOverrideSettingsEnabled(true);
      }
    }
  }, [initialValues]);

  const onSpeedAssistConfigurationTemplateChange = (templateId, setFieldValue) => {
    const selectedTemplate = speedassistConfigurationTemplates.find(
      template => template.configurationTemplate.id === templateId
    );

    setSelectedTemplateName(selectedTemplate?.configurationTemplate?.name || '');
    setPreviousTemplateId(templateId);
    setFieldValue('speedassistTemplateId', templateId);
    setIsTemplateSelected(!!templateId);
    setIsSaveEnabled(templateId !== previousTemplateId);

    if (templateId) {
      form.setFieldsValue({
        overrideSettings: false,
        maxThreshold: '',
        maxOffset: '',
        maxDuration: ''
      });
      setIsOverrideSettingsEnabled(false);
      setIsDropdownEnabled(true);
    }
    selectedConfigurationTemplate({ companyId: currentCompany?.id, templateId, id: data.id })
      .then(response => {
        console.log('Template Selection Response:', response);
      })
      .catch(error => {
        console.error('Template Selection Error:', error);
      });
  };

  const handleSaveForm = async () => {
    try {
      // Get field values and round them immediately
      let maxThreshold = Math.round(Number(form.getFieldValue('maxThreshold')));
      let maxOffset = Math.round(Number(form.getFieldValue('maxOffset')));

      // Update form fields with rounded values to reflect changes
      form.setFieldsValue({ maxThreshold, maxOffset });

      // Validate fields after setting rounded values
      await form.validateFields();
      const configs = [
        {
          device: { id: data.id },
          service: 'SPEEDASSIST',
          key: 'company.max.threshold',
          value: localization.convertSpeedWithUnit(
            maxThreshold,
            UNITS.KM,
            localization?.formats?.speed?.unit || UNITS.KM,
            0
          )
        },
        {
          device: { id: data.id },
          service: 'SPEEDASSIST',
          key: 'company.max.offset',
          value: localization.convertSpeedWithUnit(
            maxOffset,
            UNITS.KM,
            localization?.formats?.speed?.unit || UNITS.KM,
            0
          )
        },
        {
          device: { id: data.id },
          service: 'SPEEDASSIST',
          key: 'company.max.duration',
          value: form.getFieldValue('maxDuration')
        },
        {
          device: { id: data.id },
          service: 'SPEEDASSIST',
          key: 'signposted.disable',
          value: form.getFieldValue('signpostedDisable')
        },
        {
          device: { id: data.id },
          service: 'SPEEDASSIST',
          key: 'company.managed',
          value: form.getFieldValue('companyManaged')
        }
      ];
      if (isOverrideSettingsEnabled) {
        await settingValues({ companyId: currentCompany?.id, id: data.id }).unwrap();
      }
      await saveTheSettingValues({ body: configs, companyId: currentCompany?.id }).unwrap();

      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
        })
      );
    } catch (error) {
      const errorMessage =
        typeof error === 'string' ? error : error.message || JSON.stringify(error);

      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(errorMessage)}`
        })
      );
    }
  };

  const handlePush = () => {
    const speedAssistService = data.services?.find(service => service === services.SPEEDASSIST);
    if (speedAssistService) {
      const payload = {
        deviceId: data.id,
        service:
          supportedServices.find(i => i.code === speedAssistService)?.getServiceCodes() ||
          speedAssistService
      };

      executeUpdateConfig(payload, updateConfigs, dispatch);
    }
  };

  const handleOverrideSettingsChange = checked => {
    setIsOverrideSettingsEnabled(checked);
    form.setFieldsValue({ overrideSettings: checked });

    if (checked) {
      form.setFieldsValue({ speedassistTemplateId: '' });
      setSelectedTemplateName('');
      setIsDropdownEnabled(false);
    } else {
      // When override settings is disabled, restore the previous template
      setIsDropdownEnabled(true);

      setTimeout(() => {
        form.setFieldsValue({ speedassistTemplateId: previousTemplateId });
        const previousTemplate = speedassistConfigurationTemplates.find(
          template => template.configurationTemplate.id === previousTemplateId
        );
        setSelectedTemplateName(previousTemplate?.configurationTemplate?.name || '');
        setIsSaveEnabled(previousTemplateId !== selectedId?.id);
      }, 0);
    }
  };

  useEffect(() => {
    if (!isOverrideSettingsEnabled) {
      form.setFieldsValue({ speedassistTemplateId: previousTemplateId });
      const previousTemplate = speedassistConfigurationTemplates?.find(
        template => template.configurationTemplate.id === previousTemplateId
      );
      setSelectedTemplateName(previousTemplate?.configurationTemplate?.name || '');
    }
  }, [isOverrideSettingsEnabled, previousTemplateId, speedassistConfigurationTemplates, form]);

  useEffect(() => {
    form.setFieldsValue({ overrideSettings: isOverrideSettingsEnabled });
  }, [isOverrideSettingsEnabled, form]);

  const handleCancel = () => {
    form.setFieldsValue(initialValues);
    onClose();
  };

  return (
    <Form form={form} initialValues={initialValues} layout="vertical">
      {({ setFieldValue, values, errors }) => (
        <Space direction="vertical" size={16}>
          <Card bodyStyle={{ borderRadius: '8px' }}>
            <h4 className={styles.templateHeader}>
              {t('CompanyConfig.DeviceConfigurations.View.Template')}
            </h4>
            <Alert message={t('Devices.DeviceConfigurations.TemplateAlert')} type="info" showIcon />
            <Form.Item
              label={t('Devices.DeviceConfigurations.SelectedTemplate')}
              style={{ marginTop: '12px' }}
            >
              <Select
                name="speedassistTemplateId"
                placeholder={t('Devices.DeviceConfigurations.SelectOption')}
                value={
                  isDropdownEnabled && !isOverrideSettingsEnabled ? selectedTemplateName : undefined
                }
                options={speedassistConfigurationTemplates
                  ?.filter(template => template?.configurationTemplate?.status === 'ENABLED')
                  .sort((a, b) =>
                    a?.configurationTemplate?.name?.localeCompare(b?.configurationTemplate?.name)
                  )
                  .map(template => ({
                    label: template?.configurationTemplate?.name,
                    value: template?.configurationTemplate?.id
                  }))}
                onChange={value =>
                  onSpeedAssistConfigurationTemplateChange(value, form.setFieldValue)
                }
                disabled={!isDropdownEnabled || isOverrideSettingsEnabled}
              />
            </Form.Item>
          </Card>
          <Card bodyStyle={{ padding: '24px', borderRadius: '8px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxHeight: '3em'
              }}
            >
              <div>
                <div className={styles.templateHeader}>{t('Common.Settings')}</div>
              </div>
              <div>
                <Form.Item
                  label={t('Devices.DeviceConfigurations.OverrideSettings')}
                  name="overrideSettings"
                  valuePropName="checked"
                  style={{ top: '6px', position: 'relative' }}
                >
                  <Switch
                    checked={isOverrideSettingsEnabled}
                    onChange={handleOverrideSettingsChange}
                    style={{ position: 'relative', right: '50px', bottom: '33px' }}
                  />
                </Form.Item>
              </div>
            </div>
            <Alert
              message={t('Devices.DeviceConfigurations.OverrideSettingsAlert')}
              type="info"
              showIcon
            />
            <div className={styles.suffix} style={{ marginTop: '12px' }}>
              {t('Devices.DeviceConfigurations.AssetsUsage')}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Form.Item
                className={styles.formItemMargin}
                tooltip={t(
                  'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxThresholdTooltip'
                )}
                label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxThreshold')}
                rules={inputValidator().number}
                name="maxThreshold"
                initialValue={0}
              >
                <InputNumber precision={0} placeholder={0} disabled={!isOverrideSettingsEnabled} />
              </Form.Item>
              <Form.Item
                className={styles.formItemMargin}
                tooltip={t(
                  'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxOffsetTooltip'
                )}
                label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxOffset')}
                rules={inputValidator().number}
                name="maxOffset"
                initialValue={0}
              >
                <InputNumber precision={0} placeholder={0} disabled={!isOverrideSettingsEnabled} />
              </Form.Item>
              <Form.Item
                className={styles.formItemMargin}
                tooltip={t(
                  'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxDurationTooltip'
                )}
                label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxDuration')}
                rules={inputValidator().number}
                name="maxDuration"
                initialValue={0}
              >
                <InputNumber precision={0} placeholder={0} disabled={!isOverrideSettingsEnabled} />
              </Form.Item>
            </div>
            <Card style={{ backgroundColor: '#f7f8f9', marginTop: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <Form.Item
                  className={styles.formItemMargin}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.SignpostedDisabledTooltip'
                  )}
                  label={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.SignpostedDisabled'
                  )}
                  name="signpostedDisable"
                  valuePropName="checked"
                >
                  <Switch
                    onChange={checked => form.setFieldValue('signpostedDisable', checked)}
                    disabled={!isOverrideSettingsEnabled}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.formItemMargin}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.CompanyManagedTooltip'
                  )}
                  label={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.CompanyManaged'
                  )}
                  name="companyManaged"
                  valuePropName="checked"
                >
                  <Switch
                    onChange={checked => form.setFieldValue('companyManaged', checked)}
                    disabled={!isOverrideSettingsEnabled}
                  />
                </Form.Item>
              </div>
            </Card>
          </Card>
          <Card
            bodyStyle={{
              padding: '8px',
              borderRadius: '8px',
              position: 'fixed',
              bottom: '50px',
              background: 'white'
            }}
          >
            <Space>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                id={BUTTON_IDS.configureFormSave}
                onClick={handleSaveForm}
                disabled={!isSaveEnabled && !isOverrideSettingsEnabled}
              >
                {t('Common.SaveButton')}
              </Button>
              <Button size="large" id={BUTTON_IDS.configureFormCancel} onClick={handleCancel}>
                {t('Common.CancelButton')}
              </Button>
            </Space>
            <Space>
              <Button
                size="large"
                id={BUTTON_IDS.configureFormPush}
                style={{ marginLeft: '315px', border: '1px solid' }}
                onClick={handlePush}
              >
                {t('Common.PushButton')}
              </Button>
            </Space>
          </Card>
        </Space>
      )}
    </Form>
  );
};
