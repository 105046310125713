import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PATHS as CompanyAgreementPaths } from './constants';
import { CompanyAgreement } from './CompanyAgreement';
import { CompanyAgreementAudits } from './CompanyAgreementAudits';
import CompanyAgreementView from './CompanyAgreementViews';
import { Can, FeatureFlag } from 'features/permissions';
import CompanyAgreementAssignment from './CompanyAgreementAssignment';
import { NoAccessBody } from 'components/pages/NoAccess';
import { useLocation } from 'react-router';
import GlobalRoles from 'features/permissions/GlobalRoles';

export const CompanyAgreementRouter = () => {
  const location = useLocation();

  return (
    <Switch>
      <Route path={CompanyAgreementPaths.AGREEMENT_VIEW}>
        <CompanyAgreementView />
      </Route>
      <Route path={CompanyAgreementPaths.AGREEMENT_AUDIT}>
        <CompanyAgreementAudits />
      </Route>
      <Route path={CompanyAgreementPaths.AGREEMENT_ASSIGN}>
        <Can
          featureFlag={FeatureFlag.subCompanyManagement.flag}
          oneOfRoles={[GlobalRoles.SubCompanyAdmin]}
          onFail={<NoAccessBody />}
        >
          <CompanyAgreementAssignment agreement={location.state?.agreement} />
        </Can>
      </Route>
      <Route path={CompanyAgreementPaths.AGREEMENT_DEFAULT}>
        <CompanyAgreement />
      </Route>
    </Switch>
  );
};
