import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { DOCUMENTS_UPLOAD_URL, DOCUMENTS_URL } from 'features/easydocs/documentsSlice';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { useCallback, useMemo } from 'react';
import { useVehiclesFromFleets } from 'features/fleets/fleetsSlice';
import { VehicleConfig } from 'containers/Administration/Vehicles/constants';
import { useVehicleMeters, useVehiclesMeters } from 'features/vehicles/vehiclesMetersSlice';
import { useVehicleConfig } from 'features/vehicles/vehiclesConfigsSlice';
import moment from 'moment';
import { useDevicesMeters } from 'features/devices/devicesMetersSlice';
import {
  EDRDevicesSortFun,
  getScheduleStatusByTab,
  getScheduleVehicleOdometer
} from 'containers/VehicleMaintenance/utils/helpers';
import { useVehiclesStats, useIsFetchingVehicleStats } from 'features/vehicles/vehiclesStatsSlice';
import {
  useDevicesStatsEmbedDeviceMeters,
  useIsFetchingDevicesStats
} from 'features/devices/devicesStatsSlice';
import {
  STATUS,
  SCHEDULE_BY_ID_URL,
  TABS,
  FILTER_BY,
  Paths
} from 'containers/VehicleMaintenance/utils/constants';
import i18next from 'i18next';

const TRIPS = '/trips';
const DELETE_SCHEDULE_URL = '/schedules/vehiclemaintenance';
const RESTORE_SCHEDULE_URL = '/schedules/vehiclemaintenance/restore';
const InitialFilteredSchedules = {
  [STATUS.overdue]: {
    data: [],
    meta: {
      isFetching: false,
      error: null,
      isNoData: false,
      companyKey: null
    }
  },
  [STATUS.dueNow]: {
    data: [],
    meta: {
      isFetching: false,
      error: null,
      isNoData: false,
      companyKey: null
    }
  },
  [STATUS.pending]: {
    data: [],
    meta: {
      isFetching: false,
      error: null,
      isNoData: false,
      companyKey: null
    }
  },
  [STATUS.completed]: {
    data: [],
    meta: {
      isFetching: false,
      error: null,
      isNoData: false,
      companyKey: null
    }
  },
  [STATUS.cancelled]: {
    data: [],
    meta: {
      isFetching: false,
      error: null,
      isNoData: false,
      companyKey: null
    }
  },
  [STATUS.invalid]: {
    data: [],
    meta: {
      isFetching: false,
      error: null,
      isNoData: false,
      companyKey: null
    }
  },
  [STATUS.deleted]: {
    data: [],
    meta: {
      isFetching: false,
      error: null,
      isNoData: false,
      companyKey: null
    }
  }
};
const schedules = {
  list: [],
  activeTab: TABS.active,
  filteredSchedules: InitialFilteredSchedules,
  calendarView: false,
  calendarDate: null,
  filters: {
    // null convention -> initially all filters are checked
    // [] convention -> none are selected
    companies: null,
    fleets: null,
    vehicles: null,
    types: null,
    filterBy: FILTER_BY.groupBy
  },
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false,
    companyKey: null
  },
  lastFetchedSchedule: {
    data: null,
    scheduleId: null,
    meta: {
      isFetching: false,
      error: null
    }
  },
  scheduleVehicles: {}
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: schedules,
  reducers: {
    initFilteredSchedules(state) {
      state.filteredSchedules = InitialFilteredSchedules;
    },
    fetchSchedulesStart(state, action) {
      if (action.payload.isFilterByStatus) {
        action.payload.filterByStatus.forEach(status => {
          state.filteredSchedules[status] = {
            data: [],
            meta: {
              isFetching: true,
              error: null,
              isNoData: false
            }
          };
        });
      } else {
        startLoading(state, action);
      }
    },
    fetchSchedulesSuccess(state, { payload }) {
      if (payload.isFilterByStatus) {
        payload.filterByStatus.forEach(status => {
          const statusData = payload.list.filter(item => item.status === status);
          state.filteredSchedules[status] = {
            data: statusData,
            meta: {
              isFetching: false,
              error: null,
              isNoData: statusData.length === 0,
              companyKey: payload.companyKey
            }
          };
        });
        state.meta.companyKey = payload.companyKey;
      } else {
        state.list = payload.list;
        state.meta.isFetching = false;
        state.meta.lastFetched = 'now';
        state.meta.error = null;
        state.meta.isListEmpty = payload.list.length === 0;
        state.meta.companyKey = payload.companyKey;
      }
    },
    fetchSchedulesFailure(state, action) {
      if (action.payload.isFilterByStatus) {
        action.payload.filterByStatus.forEach(status => {
          state.filteredSchedules[status] = {
            data: [],
            meta: {
              isFetching: false,
              error: action.payload.err,
              isNoData: false,
              companyKey: action.payload.companyKey
            }
          };
        });
        state.meta.companyKey = action.payload.companyKey;
      } else {
        loadingFailed(state, action);
      }
    },
    toggleCalendarView(state) {
      state.calendarView = !state.calendarView;
    },
    setCalendarDate(state, { payload }) {
      state.calendarDate = payload;
    },
    setFilters(state, { payload }) {
      state.filters[payload.type] = payload.filterIds;
    },
    resetFilters(state) {
      state.filters = {
        companies: null,
        fleets: null,
        vehicles: null,
        types: null,
        filterBy: FILTER_BY.groupBy
      };
    },
    fetchScheduleVehiclesStart(state, { payload }) {
      state.scheduleVehicles[payload.id] = {
        lastFetched: null,
        isFetching: true,
        error: null,
        vehicleDetails: null
      };
    },
    fetchScheduleVehiclesSuccess(state, { payload }) {
      state.scheduleVehicles[payload?.id] = {
        lastFetched: moment().format(),
        isFetching: false,
        error: null,
        vehicleDetails: payload.value
      };
      state.meta.companyKey = payload.companyKey;
    },
    fetchScheduleVehiclesFailure(state, { payload }) {
      state.scheduleVehicles[payload?.id] = {
        lastFetched: moment().format(),
        isFetching: false,
        error: payload?.err,
        vehicleDetails: { id: payload?.id }
      };
      state.meta.companyKey = payload.companyKey;
    },
    resetScheduleVehicles(state) {
      state.scheduleVehicles = {};
    },
    updateActiveTab(state, { payload }) {
      state.activeTab = payload.activeTab;
    },
    fetchScheduleStart(state, { payload }) {
      state.lastFetchedSchedule = {
        data: null,
        scheduleId: payload.scheduleId,
        meta: {
          isFetching: true,
          error: null
        }
      };
    },
    fetchScheduleSuccess(state, { payload }) {
      state.lastFetchedSchedule.data = payload.data || { id: payload.scheduleId };
      state.lastFetchedSchedule.scheduleId = payload.scheduleId;
      state.lastFetchedSchedule.meta.isFetching = false;
      state.lastFetchedSchedule.meta.error = null;
      state.meta.companyKey = payload.companyKey;
    },
    fetchScheduleFailure(state, { payload }) {
      state.lastFetchedSchedule.data = { id: payload.scheduleId };
      state.lastFetchedSchedule.scheduleId = payload.scheduleId;
      state.lastFetchedSchedule.meta.isFetching = false;
      state.lastFetchedSchedule.meta.error = payload.err;
      state.meta.companyKey = payload.companyKey;
    }
  }
});

const {
  fetchSchedulesStart,
  fetchSchedulesSuccess,
  fetchSchedulesFailure,
  toggleCalendarView,
  setCalendarDate,
  setFilters,
  resetFilters,
  initFilteredSchedules,
  fetchScheduleVehiclesStart,
  fetchScheduleVehiclesSuccess,
  fetchScheduleVehiclesFailure,
  resetScheduleVehicles,
  updateActiveTab,
  fetchScheduleStart,
  fetchScheduleSuccess,
  fetchScheduleFailure
} = schedulesSlice.actions;

export const changeCalendarView = () => dispatch => {
  dispatch(toggleCalendarView());
};

export const updateCalendarDate = date => dispatch => {
  dispatch(setCalendarDate(date));
};

export const updateFilters = (type, filterIds) => dispatch => {
  dispatch(setFilters({ type, filterIds }));
};

export const fetchSchedules = (isCompanyKeyDifferent, filterByStatus = []) => (
  dispatch,
  getState
) => {
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;
  const isFilterByStatus = !!filterByStatus?.length;
  try {
    if (!companyKey || !userKey || !companyId) {
      return;
    }
    if (isCompanyKeyDifferent) {
      dispatch(resetFilters());
    }
    dispatch(fetchSchedulesStart({ filterByStatus, isFilterByStatus }));
    const url = isFilterByStatus
      ? `${API_PATH}/schedules/vehiclemaintenance?direction=DOWN&pruning=ALL&embed=vehicle,company,user,managetype,previousMaintenance&company_id=${companyId}&status=${filterByStatus.join(
          ','
        )}`
      : `${API_PATH}/schedules/vehiclemaintenance?direction=DOWN&pruning=ALL&embed=vehicle,company,user,managetype,previousMaintenance&company_id=${companyId}`;
    request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(res => {
        dispatch(
          fetchSchedulesSuccess({
            list: res?.body || [],
            companyKey,
            filterByStatus,
            isFilterByStatus
          })
        );
      })
      .catch(err => {
        dispatch(
          fetchSchedulesFailure({
            err: err.toString(),
            companyKey,
            filterByStatus,
            isFilterByStatus
          })
        );
        console.warn('ERROR', err);
      });
  } catch (err) {
    dispatch(
      fetchSchedulesFailure({ err: err.toString(), companyKey, filterByStatus, isFilterByStatus })
    );
  }
};

// Attach files to a completed schedule
export const attachFiles = (files, scheduleId) => async (dispatch, getState) => {
  const {
    companies: { current },
    user,
    documents
  } = getState();
  const authKey = user.current.auth.key;

  // Upload the files and create an array of uploaded files ids
  for (let file of files) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploadParams', JSON.stringify({ overwrite: true, scheduleId }));

    try {
      const uploadResponse = await api.post(
        DOCUMENTS_UPLOAD_URL,
        {
          authKey,
          company_id: documents.uploadCompanyId || current.id
        },
        formData
      );

      const { body } = uploadResponse;

      if (body?.success === false) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message:
              body?.filesizeLimitReached === true
                ? i18next.t('VehicleMntSchedules.Notifications.FileExceededMaximumSize', {
                    name: file.name
                  })
                : `File ${file.name} was not saved.`
          })
        );
      }
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `File ${file.name} was not saved: ${err}`
        })
      );
    }
  }
};

export const removeFiles = (file, files, updateFilesCb) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;

  try {
    const response = await api.delete(`${DOCUMENTS_URL}/${file.id}`, { authKey });
    if (response?.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${file?.name} removed successfully!`
        })
      );

      updateFilesCb(files.filter(uploadedFile => uploadedFile.name !== file.name));
    }
  } catch (e) {
    console.error(e);
  }
};

export const refetchSchedules = (schedule, extraStatus = [], includeDeleted = false) => async (
  dispatch,
  getState
) => {
  const activeTab = getState().schedules.activeTab;
  const status = Array.from(
    new Set([
      ...getScheduleStatusByTab(activeTab),
      STATUS[Object.keys(STATUS).find(key => STATUS[key] === schedule.status)],
      ...(includeDeleted ? [STATUS.deleted] : []),
      ...extraStatus
    ])
  ).filter(status => !!status);
  dispatch(fetchSchedules(false, status));
};

export const setActiveTab = activeTab => async dispatch => {
  dispatch(updateActiveTab({ activeTab }));
};

export const useFilteredSchedules = (filterBy = []) => {
  const dispatch = useDispatch();
  const filteredSchedules = useSelector(state => state.schedules.filteredSchedules);
  const currentCompanyKey = useCurrentCompanyKey();
  const isCompanyKeyDiffOnStatus = useCallback(
    companyKey => {
      return companyKey !== currentCompanyKey;
    },
    [currentCompanyKey]
  );
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const shouldFilter = useMemo(() => {
    const isFetchingAll = filterBy.every(status => filteredSchedules[status].meta.isFetching);
    const someShouldFetch = filterBy.some(status => {
      const statusState = filteredSchedules[status];
      const statusIsFetching = statusState.meta.isFetching;
      const statusIsEmpty = statusState.meta.isNoData;
      const statusHasNoData = !statusState.data.length;
      const statusHasError = statusState.meta.error;
      return (
        !statusIsFetching &&
        ((statusHasNoData && !statusIsEmpty && !statusHasError) ||
          isCompanyKeyDiffOnStatus(statusState.meta.companyKey))
      );
    });
    return !isFetchingAll && someShouldFetch;
  }, [filterBy, filteredSchedules, isCompanyKeyDiffOnStatus]);

  if (shouldFilter) {
    dispatch(fetchSchedules(isCompanyKeyDifferent, filterBy));
  }

  const schedules = useMemo(() => {
    return filterBy
      .map(status => filteredSchedules[status] || [])
      .reduce((a, c) => [...a, ...c.data], []);
  }, [filterBy, filteredSchedules]);

  const isFetching = useMemo(() => {
    return filterBy.some(status => {
      return filteredSchedules[status]?.meta?.isFetching;
    });
  }, [filterBy, filteredSchedules]);

  return { schedules, isFetching };
};

export const useSchedules = () => {
  const dispatch = useDispatch();
  const schedules = useSelector(state => state.schedules.list);
  const isFetching = useSelector(state => state.schedules.meta.isFetching);
  const isListEmpty = useSelector(state => state.schedules.meta.isListEmpty);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  if (!isFetching && (isCompanyKeyDifferent || (schedules.length === 0 && !isListEmpty))) {
    dispatch(fetchSchedules(isCompanyKeyDifferent));
  }
  return schedules;
};

export const useSchedulesWithVehicleOdometer = (filterByScheduleStatus = []) => {
  //get schedules from scheduleAPI,each schedule has boolean useRucOdometerSource indicates a scheduleVehicle is rucVehicle or not
  //get vehicleMeters from vehicleStatsAPI, no-rucVehicle need read odomerter from vehicleMeters
  //get deviceMeters from devicesStatsPI, rucVehicle need read odometer from deviceMeter

  const { schedules: _schedules, isFetching: isFetchingSchedules } = useFilteredSchedules(
    filterByScheduleStatus
  );
  const schedules = useMemo(() => {
    return isFetchingSchedules ? [] : _schedules;
  }, [_schedules, isFetchingSchedules]);

  const {
    schedulesVehicles,
    isFetching: isFetchingSchedulesVehicles,
    fetchingDetails: schedulesVehiclesWithMetersFetchingDetails
  } = useSchedulesVehiclesWithMeters(schedules, isFetchingSchedules);

  const schedulesWithVehicleAndEDRDevicesMeters = useMemo(() => {
    return schedules?.map(schedule => {
      return {
        ...schedule,
        vehicle: {
          ...schedule.vehicle,
          ...(schedulesVehicles?.find(vehicle => vehicle.id === schedule.vehicle?.id) || {})
        }
      };
    });
  }, [schedules, schedulesVehicles]);

  const rucScheduleVehicleIds = useMemo(() => {
    return Array.from(
      new Set(
        schedules
          ?.filter(schedule => !!schedule?.useRucOdometerSource)
          .map(schedule => schedule?.vehicle?.id)
      )
    );
  }, [schedules]);

  const isVehicleUsingRUCOdometerForMnt = useCallback(
    scheduleVehicle => {
      return rucScheduleVehicleIds?.some(rucVehicleId => rucVehicleId === scheduleVehicle.id);
    },
    [rucScheduleVehicleIds]
  );

  const schedulesWithVehiceOdometer = useMemo(() => {
    return (schedulesWithVehicleAndEDRDevicesMeters || []).map(schedule => {
      const scheduleVehicle = schedule?.vehicle || {};
      const updatedScheduleVehicle = {
        ...scheduleVehicle,
        config: isVehicleUsingRUCOdometerForMnt(scheduleVehicle)
          ? { isUsingRUCOdometerForMnt: true }
          : {}
      };
      return {
        ...schedule,
        vehicle: {
          ...updatedScheduleVehicle,
          vehicleOdometer: getScheduleVehicleOdometer(updatedScheduleVehicle)
        }
      };
    });
  }, [schedulesWithVehicleAndEDRDevicesMeters, isVehicleUsingRUCOdometerForMnt]);

  const isFetching = useMemo(() => {
    return isFetchingSchedules || isFetchingSchedulesVehicles;
  }, [isFetchingSchedules, isFetchingSchedulesVehicles]);

  return {
    schedulesWithVehiceOdometer,
    isFetching,
    fetchingDetails: {
      ...schedulesVehiclesWithMetersFetchingDetails,
      isFetchingSchedules
    }
  };
};

const fetcScheduleVehicles = (ids, force) => async (dispatch, getState) => {
  try {
    const companyKey = getState().companies.current.api_key;
    const userKey = getState().user.current.auth.key;
    if (!companyKey || !userKey) {
      return;
    }
    ids = force
      ? ids
      : ids.filter(
          id =>
            !getState().schedules.scheduleVehicles[id] &&
            !getState().schedules.scheduleVehicles[id]?.isFetching
        );
    if (force) {
      dispatch(resetScheduleVehicles());
    }
    ids.map(id => dispatch(fetchScheduleVehiclesStart({ id })));
    await Promise.all(
      ids.map(id =>
        Promise.all([
          api
            .get(`/vehicles/${id}`, {
              authKey: userKey,
              query: {
                embed: 'maintenance_info,vehicle_assets'
              }
            })
            .then(res => (res.status === 200 ? res.body || { id } : { id })),
          api
            .get(`/vehicles/${id}/devices`, { authKey: userKey })
            .then(res => (res.status === 200 ? res.body || [] : []))
        ])
          .then(([vehicleDetails, vehicleDevices]) => {
            dispatch(
              fetchScheduleVehiclesSuccess({
                value: {
                  ...vehicleDetails,
                  devices: vehicleDevices
                },
                id,
                companyKey
              })
            );
          })
          .catch(err => {
            dispatch(fetchScheduleVehiclesFailure({ err: err.toString(), id, companyKey }));
          })
      )
    );
  } catch (err) {}
};

const useSchedulesVehiclesWithMeters = (schedules, isFetchingSchedules) => {
  //make sure every schedule.vehicle has vehicle.devices, vehicle.meters vehicle.devices.meters
  const dispatch = useDispatch();
  //useVehicles has vehicle.devices
  const { vehicles, isFetchingVehicles } = useVehiclesFromFleets();
  //useVehiclesStats has vehicle.meters
  const vehiclesStats = useVehiclesStats();
  const isFetchingVehicesStats = useIsFetchingVehicleStats();

  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const extraVehicles = useSelector(state => state.schedules.scheduleVehicles); //vehicles found in schedules but can't find in useVehicles

  const vehiclesIdsFromSchedules = useMemo(() => {
    return isFetchingSchedules
      ? []
      : Array.from(
          new Set((schedules || []).map(schedule => schedule?.vehicle?.id).filter(id => !!id))
        );
  }, [isFetchingSchedules, schedules]);

  const vehicleNeedDetails = useCallback(
    vehicleId => {
      return (
        !isFetchingVehicles &&
        vehicles.length &&
        vehicles.every(vehicle => vehicle.id !== vehicleId) &&
        !extraVehicles[vehicleId]
      );
    },
    [vehicles, isFetchingVehicles, extraVehicles]
  );

  const vehicleIdsNeedDetails = useMemo(() => {
    return vehiclesIdsFromSchedules.filter(vehicleNeedDetails);
  }, [vehicleNeedDetails, vehiclesIdsFromSchedules]);

  if (vehicleIdsNeedDetails?.length) {
    dispatch(fetcScheduleVehicles(vehicleIdsNeedDetails, isCompanyKeyDifferent));
  }

  const isFetchingScheduleVehicles = useMemo(() => {
    return (
      vehicleIdsNeedDetails &&
      !!vehicleIdsNeedDetails.length &&
      vehicleIdsNeedDetails.some(
        vehicleId => extraVehicles[vehicleId] && extraVehicles[vehicleId].isFetching
      )
    );
  }, [vehicleIdsNeedDetails, extraVehicles]);

  const vehiclesIdsNeedMeters = useMemo(() => {
    return isFetchingVehicesStats
      ? []
      : vehiclesIdsFromSchedules?.filter(vehicleId =>
          vehiclesStats.every(vehicle => vehicle?.vehicleId !== vehicleId)
        );
  }, [vehiclesIdsFromSchedules, isFetchingVehicesStats, vehiclesStats]);

  const {
    vehiclesMeters: extracVehiclesMeters,
    isFetching: isFetchingVehiclesMeters
  } = useVehiclesMeters(vehiclesIdsNeedMeters);

  //get devicesMeters from devicesStats,otherwise call deviceMetersAPI
  const devicesStats = useDevicesStatsEmbedDeviceMeters('EDR');
  const isFetchingDevicesStats = useIsFetchingDevicesStats();

  const getSortedEDRDevices = useCallback(
    schedulesVehicle => {
      const edrDevices =
        schedulesVehicle?.devices?.filter(device => device?.type?.code === 'EDR') || [];
      const edrDevicesWithMeters = edrDevices
        .map(edrDevice => {
          const edrDeviceMeters =
            devicesStats?.find(deviceStat => deviceStat.deviceId === edrDevice?.id)?.meters || [];
          return {
            ...edrDevice,
            meters: edrDeviceMeters
          };
        })
        .sort(EDRDevicesSortFun);

      return edrDevicesWithMeters;
    },
    [devicesStats]
  );

  const schedulesVehicles = useMemo(() => {
    return (vehiclesIdsFromSchedules || []).map(vId => {
      const scheduleVehicle = schedules.find(schedule => schedule?.vehicle?.id === vId)?.vehicle;
      const vehicleDetails =
        vehicles?.find(v => v.id === vId) || extraVehicles[vId]?.vehicleDetails || {};
      const vehicleMeters =
        vehiclesStats?.find(v => v.vehicleId === vId)?.meters ||
        (extracVehiclesMeters && extracVehiclesMeters[vId]) ||
        [];
      const ret = {
        ...scheduleVehicle,
        ...vehicleDetails,
        meters: vehicleMeters
      };
      const EDRDevices = getSortedEDRDevices(ret);
      return {
        ...ret,
        EDRDevices,
        rucDevice: EDRDevices && EDRDevices[0],
        hasEDRDevice: EDRDevices && EDRDevices.length > 0
      };
    });
  }, [
    vehiclesIdsFromSchedules,
    schedules,
    vehicles,
    extraVehicles,
    vehiclesStats,
    extracVehiclesMeters,
    getSortedEDRDevices
  ]);

  //Only EDR devices need to get deviceMeter for calculating RUC Odometer
  const edrDeviceIds = useMemo(() => {
    const deviceIds = [];
    (schedulesVehicles || []).forEach(schedulesVehicle => {
      (schedulesVehicle?.EDRDevices || []).forEach(EDRDevice => {
        if (EDRDevice?.id) {
          deviceIds.push(EDRDevice.id);
        }
      });
    });
    return Array.from(new Set(deviceIds));
  }, [schedulesVehicles]);

  const edrDeviceIdsNeedMeters = useMemo(() => {
    return isFetchingDevicesStats
      ? []
      : edrDeviceIds.filter(edrDeviceId =>
          devicesStats.every(device => device.deviceId !== edrDeviceId)
        );
  }, [devicesStats, edrDeviceIds]);

  const {
    devicesMeters: extraEDRDevicesMeters,
    isFetching: isFetchingEDRDevicesMeters
  } = useDevicesMeters(edrDeviceIdsNeedMeters);

  const schedulesVehiclesWithDeviceMeters = useMemo(() => {
    return schedulesVehicles.map(schedulesVehicle => {
      const EDRDevices = schedulesVehicle.EDRDevices.map(device => {
        const edrDeviceMeters =
          (extraEDRDevicesMeters && extraEDRDevicesMeters[device?.id]) || device?.meters || [];
        return {
          ...device,
          meters: edrDeviceMeters
        };
      });
      return {
        ...schedulesVehicle,
        EDRDevices
      };
    });
  }, [schedulesVehicles, devicesStats, extraEDRDevicesMeters]);

  const isFetching = useMemo(() => {
    return (
      isFetchingVehicles ||
      isFetchingVehiclesMeters ||
      isFetchingScheduleVehicles ||
      isFetchingDevicesStats ||
      isFetchingEDRDevicesMeters
    );
  }, [
    isFetchingVehicles,
    isFetchingVehiclesMeters,
    isFetchingScheduleVehicles,
    isFetchingDevicesStats,
    isFetchingEDRDevicesMeters
  ]);

  return {
    schedulesVehicles: schedulesVehiclesWithDeviceMeters,
    isFetching,
    fetchingDetails: {
      isFetchingVehicles,
      isFetchingVehiclesMeters,
      isFetchingScheduleVehicles,
      isFetchingVehicesStats,
      isFetchingEDRDevicesMeters,
      isFetchingDevicesStats
    }
  };
};

export const useIsFetching = () => useSelector(state => state.schedules.meta.isFetching);
export const useCompanyKey = () => useSelector(state => state.schedules.meta.companyKey);
export const useIsCalendarView = () => useSelector(state => state.schedules.calendarView);
export const useCaledarDate = () => useSelector(state => state.schedules.calendarDate);
export const useFilters = () => {
  return useSelector(state => state.schedules.filters);
};
const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

export const fetchTrips = (fromDate, toDate, vehicleId) => async (dispatch, getState) => {
  const {
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  try {
    const response = await api.get(TRIPS, {
      authKey,
      query: {
        from: fromDate,
        to: toDate,
        embed: 'events',
        event_types: 'IOR',
        vehicleId: vehicleId
      }
    });

    if (!response || response.status !== 200) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `Something went wrong while fetching ignition events!`
        })
      );
    }

    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(err)
      })
    );
  }
};

export const handleScheduleDeleteAction = ({ schedule, t, history }) => async (
  dispatch,
  getState
) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  Mixpanel.sendTrackEvent(MPTrackingEvents.VehicleMaintenance.Schedule.delete, {
    action: 'delete',
    typeName: schedule?.manageType?.name,
    serviceName: schedule.name
  });

  if (!authKey || !currentCompany) {
    return;
  }

  try {
    const response = await api.delete(DELETE_SCHEDULE_URL.concat(`/${schedule.id}`), { authKey });
    if (response && (response.ok || response.noContent)) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('VehicleMntSchedules.Notifications.ScheduleDeleted', {
            name: schedule.name
          })
        })
      );
      dispatch(refetchSchedules(response.body, [], true));
      if (history) {
        history.push(`${Paths.VEHICLEMAINTENANCE_SCHEDULES}/${TABS.active}`);
      }
    }
  } catch (e) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t('VehicleMntSchedules.Notifications.ScheduleDeleteError', {
          name: schedule.name
        })
      })
    );
  }
};

export const handleScheduleRestoreAction = ({ schedule, t }) => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey || !currentCompany || !schedule.id) {
    return;
  }

  try {
    const response = await api.put(RESTORE_SCHEDULE_URL.concat(`/${schedule.id}`), { authKey });
    if (response?.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('VehicleMntSchedules.Notifications.ScheduleRestored', {
            name: schedule.name
          })
        })
      );
      dispatch(refetchSchedules(response.body, [], true));
    }
  } catch (e) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${t('VehicleMntSchedules.Notifications.ScheduleRestoreError', {
          name: schedule.name
        })} (${parseErrorMessage(e)})`
      })
    );
  }
};

const fetchScheduleById = scheduleId => async (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const authKey = getState().user.current.auth.key;
  try {
    if (!companyKey || !authKey || !companyId) {
      return;
    }
    dispatch(fetchScheduleStart({ scheduleId }));
    await api
      .get(`${SCHEDULE_BY_ID_URL}/${scheduleId}`, {
        authKey,
        query: { embed: 'vehicle,user,events,company,managetype', pruning: 'ALL' }
      })
      .then(res => {
        dispatch(
          fetchScheduleSuccess({ scheduleId, companyKey, data: res.body || { id: scheduleId } })
        );
      })
      .catch(err => {
        dispatch(
          fetchScheduleFailure({
            err: err.toString(),
            companyKey
          })
        );
      });
  } catch (err) {
    console.error(err);
    dispatch(fetchScheduleFailure({ scheduleId, companyKey, err: err || 'unknow error' }));
  }
};

const useSchedule = scheduleId => {
  const dispatch = useDispatch();
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const lastFetchedSchedule = useSelector(state => state.schedules.lastFetchedSchedule);
  const isFetchingSchedule = useSelector(
    state => state.schedules.lastFetchedSchedule.meta.isFetching
  );
  const filteredSchedules = useSelector(state => state.schedules.filteredSchedules);

  const validScheduleId = useMemo(() => {
    return (
      scheduleId &&
      scheduleId !== 'add' &&
      !isNaN(Number(scheduleId)) &&
      isFinite(Number(scheduleId))
    );
  }, [scheduleId]);

  const existingSchedule = useMemo(() => {
    const statusKeys = Object.keys(filteredSchedules || {});
    const total = statusKeys
      .map(key => filteredSchedules[key].data || [])
      .reduce((a, c) => [...a, ...c], []);
    return validScheduleId && total.find(item => Number(item.id) === Number(scheduleId));
  }, [filteredSchedules, validScheduleId, scheduleId]);

  const shouldFetch = useMemo(() => {
    return (
      validScheduleId &&
      !existingSchedule &&
      (lastFetchedSchedule.scheduleId !== scheduleId ||
        (!isFetchingSchedule &&
          ((!lastFetchedSchedule?.data && !lastFetchedSchedule.meta.error) ||
            isCompanyKeyDifferent)))
    );
  }, [
    validScheduleId,
    lastFetchedSchedule,
    isFetchingSchedule,
    isCompanyKeyDifferent,
    existingSchedule
  ]);

  if (shouldFetch) {
    dispatch(fetchScheduleById(scheduleId));
  }
  const schedule = useMemo(() => {
    return validScheduleId && (existingSchedule || lastFetchedSchedule.data);
  }, [lastFetchedSchedule, existingSchedule, validScheduleId]);

  return schedule;
};

export const useScheduleWithVehicleOdometer = scheduleId => {
  const schedule = useSchedule(scheduleId);
  const scheduleVehicleMeters = useVehicleMeters(schedule?.vehicle?.id);
  const scheduleVehicleConfig = useVehicleConfig(
    schedule?.vehicle?.id,
    VehicleConfig.OdometerForMnt.value
  );

  const scheduleWithVehicleOdometer = useMemo(() => {
    if (!schedule) {
      return;
    }
    const scheduleVehicle = schedule?.vehicle || {};

    const updatedScheduleVehicle = {
      ...scheduleVehicle,
      config: scheduleVehicleConfig || {},
      meters: scheduleVehicleMeters,
      rucDevice: scheduleVehicleConfig?.rucDevice,
      EDRDevices: scheduleVehicleConfig?.EDRDevices || [],
      hasEDRDevice: !!scheduleVehicleConfig?.hasEDRDevice
    };

    return {
      ...schedule,
      vehicle: {
        ...updatedScheduleVehicle,
        vehicleOdometer: getScheduleVehicleOdometer(updatedScheduleVehicle)
      }
    };
  }, [schedule, scheduleVehicleMeters, scheduleVehicleConfig]);

  return scheduleWithVehicleOdometer;
};

export const useVehicleSchedules = (vehicleId, scheduleStatus) => {
  const { schedules, isFetching } = useFilteredSchedules(scheduleStatus);

  const vehicleSchedules = useMemo(() => {
    return isFetching
      ? []
      : vehicleId &&
          schedules.filter(
            schedule =>
              [STATUS.completed, STATUS.cancelled, STATUS.invalid].every(
                status => schedule.status !== status
              ) && Number(schedule.vehicle.id) === Number(vehicleId)
          );
  }, [vehicleId, schedules, isFetching]);

  return {
    vehicleSchedules,
    isFetching
  };
};
export const updateVehicleSchedules = () => dispatch => {
  //init filtered schedules to fetch it when needed
  dispatch(initFilteredSchedules());
};

export default schedulesSlice.reducer;
